import {
  Box,
  BoxProps,
  Center,
  FlexProps,
  Grid,
  HStack,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import { MaterializeLogo } from "~/components/MaterializeLogo";
import { MaterializeTheme } from "~/theme";

export const AuthLayout = ({
  children,
  ...props
}: React.PropsWithChildren<FlexProps>) => {
  const { colors } = useTheme<MaterializeTheme>();

  return (
    <Center background={colors.background.primary} height="100vh" {...props}>
      <Grid
        gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
        gridTemplateRows={{ base: "1fr 3fr", lg: "1fr" }}
        alignItems={{ base: "start", lg: "center" }}
        height="100%"
        width="100%"
      >
        <HStack my={{ base: "8", lg: "0" }} justifyContent="center">
          <MaterializeLogo height="12" />
        </HStack>
        <Center justifyContent={{ base: "center", lg: "flex-start" }}>
          {children}
        </Center>
      </Grid>
    </Center>
  );
};

export const AuthContentContainer = ({
  title,
  children,
  subheading,
  ...containerProps
}: React.PropsWithChildren<
  { title: string; subheading?: React.ReactNode } & BoxProps
>) => {
  return (
    <Box width="420px" {...containerProps}>
      <VStack alignItems="flex-start" mb="40px" gap="4">
        <Text as="h1" textStyle="heading-md">
          {title}
        </Text>
        {subheading}
      </VStack>
      {children}
    </Box>
  );
};
