import { Alert, Text, useTheme } from "@chakra-ui/react";
import * as React from "react";

import { useCurrentOrganization } from "~/api/auth";
import config from "~/config";
import { MaterializeTheme } from "~/theme";

const ImpersonationAlert = () => {
  const { colors } = useTheme<MaterializeTheme>();
  const { organization } = useCurrentOrganization();

  if (!config.impersonation) return null;

  return (
    <Alert
      backgroundColor={colors.background.info}
      borderTopWidth="1px"
      borderTopColor={colors.border.info}
      borderBottomWidth="1px"
      borderBottomColor={colors.border.info}
      py={2}
      fontSize="sm"
      lineHeight="20px"
      color={colors.foreground.primary}
      status="info"
      justifyContent="center"
      data-testid="account-status-alert"
      flexShrink="0"
    >
      <Text>
        You are currently teleported into{" "}
        <Text as="span" textStyle="text-ui-med">
          {organization?.name}
        </Text>{" "}
        ({config.impersonation.organizationId})
      </Text>
    </Alert>
  );
};

export default ImpersonationAlert;
