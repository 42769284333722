import { PostgreSQL, sql, SQLDialect } from "@codemirror/lang-sql";

import lazyLoadLexer from "~/lexer/lexer";

async function lazyLoadGetLanguageExtension() {
  const lexer = await lazyLoadLexer();

  return () => {
    const mzKeywords = lexer.getKeywords();
    const dialect = SQLDialect.define({
      ...PostgreSQL.spec,
      keywords: mzKeywords.join(" ").toLowerCase(),
    });

    return sql({ dialect });
  };
}

export default lazyLoadGetLanguageExtension;
