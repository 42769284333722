import {
  Box,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useTheme,
} from "@chakra-ui/react";
import React from "react";

import { createNamespace } from "~/api/materialize";
import { Table as TableType } from "~/api/materialize/tables/tablesList";
import { AppErrorBoundary } from "~/components/AppErrorBoundary";
import { CodeBlock } from "~/components/copyableComponents";
import DeleteObjectMenuItem from "~/components/DeleteObjectMenuItem";
import { LoadingContainer } from "~/components/LoadingContainer";
import OverflowMenu, { OVERFLOW_BUTTON_WIDTH } from "~/components/OverflowMenu";
import DatabaseFilter from "~/components/SchemaObjectFilter/DatabaseFilter";
import SchemaFilter from "~/components/SchemaObjectFilter/SchemaFilter";
import {
  DatabaseFilterState,
  NameFilterState,
  SchemaFilterState,
  useSchemaObjectFilters,
} from "~/components/SchemaObjectFilter/useSchemaObjectFilters";
import SearchInput from "~/components/SearchInput";
import {
  MainContentContainer,
  PageHeader,
  PageHeading,
} from "~/layouts/BaseLayout";
import {
  EmptyListHeader,
  EmptyListHeaderContents,
  EmptyListWrapper,
  SampleCodeBoxWrapper,
} from "~/layouts/listPageComponents";
import docUrls from "~/mz-doc-urls.json";
import TableIcon from "~/svg/TableIcon";
import { MaterializeTheme } from "~/theme";
import { truncateMaxWidth } from "~/theme/components/Table";

import { TABLES_FETCH_ERROR_MESSAGE } from "./constants";
import { useTablesList } from "./queries";

const NAME_FILTER_QUERY_STRING_KEY = "tableName";

export const EmptyState = () => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <EmptyListWrapper>
      <EmptyListHeader>
        <Box mt="-1px">
          <TableIcon w={10} h={10} color={colors.foreground.tertiary} />
        </Box>
        <>
          <EmptyListHeaderContents
            title="No available tables"
            helpText="Tables in your database will show up here."
          />
          <SampleCodeBoxWrapper docsUrl={docUrls["/docs/sql/create-table/"]}>
            <CodeBlock
              title="Create a table"
              contents={`CREATE TABLE <table_name> (
    <column_name> <data_type>,
    -- add additional columns...
);`}
              lineNumbers
            />
          </SampleCodeBoxWrapper>
        </>
      </EmptyListHeader>
    </EmptyListWrapper>
  );
};

export const TablesList = () => {
  const { databaseFilter, schemaFilter, nameFilter } = useSchemaObjectFilters(
    NAME_FILTER_QUERY_STRING_KEY,
  );

  return (
    <MainContentContainer>
      <PageHeader>
        <PageHeading>Tables</PageHeading>
        <HStack spacing="4">
          <HStack>
            <DatabaseFilter {...databaseFilter} />
            <SchemaFilter {...schemaFilter} />
          </HStack>
          <SearchInput
            name="table"
            value={nameFilter.name}
            onChange={(e) => {
              nameFilter.setName(e.target.value);
            }}
          />
        </HStack>
      </PageHeader>
      <AppErrorBoundary message={TABLES_FETCH_ERROR_MESSAGE}>
        <React.Suspense fallback={<LoadingContainer />}>
          <TablesListContent
            databaseFilter={databaseFilter}
            schemaFilter={schemaFilter}
            nameFilter={nameFilter}
          />
        </React.Suspense>
      </AppErrorBoundary>
    </MainContentContainer>
  );
};

interface TableListContentProps {
  databaseFilter: DatabaseFilterState;
  nameFilter: NameFilterState;
  schemaFilter: SchemaFilterState;
}

const TablesListContent = ({
  databaseFilter,
  schemaFilter,
  nameFilter,
}: TableListContentProps) => {
  const { data: tables, refetch } = useTablesList({
    databaseId: databaseFilter.selected?.id,
    schemaId: schemaFilter.selected?.id,
    nameFilter: nameFilter.name,
  });

  if (tables.rows.length === 0) {
    return <EmptyState />;
  }
  return <TablesTable tables={tables.rows} refetchTables={refetch} />;
};

interface TablesTableProps {
  tables: TableType[];
  refetchTables: () => void;
}

export const TablesTable = (props: TablesTableProps) => {
  const { colors } = useTheme<MaterializeTheme>();

  return (
    <Table variant="linkable" borderRadius="xl">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th width={OVERFLOW_BUTTON_WIDTH}></Th>
        </Tr>
      </Thead>
      <Tbody>
        {props.tables.map((t) => (
          <Tr key={t.id}>
            <Td {...truncateMaxWidth} py="2">
              <Text
                textStyle="text-small"
                fontWeight="500"
                noOfLines={1}
                color={colors.foreground.secondary}
              >
                {createNamespace(t.databaseName, t.schemaName)}
              </Text>
              <Text textStyle="text-ui-med" noOfLines={1}>
                {t.name}
              </Text>
            </Td>
            <Td>
              <OverflowMenu
                items={[
                  {
                    visible: t.isOwner,
                    render: () => (
                      <DeleteObjectMenuItem
                        key="delete-object"
                        selectedObject={t}
                        onSuccessAction={props.refetchTables}
                        objectType="TABLE"
                      />
                    ),
                  },
                ]}
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
