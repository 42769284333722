import { useSuspenseQuery } from "@tanstack/react-query";

import {
  buildQueryKeyPart,
  buildRegionQueryKey,
} from "~/api/buildQueryKeySchema";
import {
  fetchTableList,
  ListFilters,
} from "~/api/materialize/tables/tablesList";

export const tableQueryKeys = {
  all: () => buildRegionQueryKey("table"),
  list: (filters?: ListFilters) =>
    [...tableQueryKeys.all(), buildQueryKeyPart("list", filters)] as const,
};

export function useTablesList(filters: ListFilters = {}) {
  return useSuspenseQuery({
    refetchInterval: 5000,
    queryKey: tableQueryKeys.list(filters),
    queryFn: ({ queryKey, signal }) => {
      const [, filtersKeyPart] = queryKey;
      return fetchTableList({
        queryKey,
        filters: filtersKeyPart,
        requestOptions: { signal },
      });
    },
  });
}

export type TablesListResponse = ReturnType<typeof useTablesList>;
