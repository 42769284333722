import { Tag } from "@chakra-ui/react";
import * as React from "react";

import { CopyButton } from "~/components/copyableComponents";
import { useCurrentUser } from "~/queries/frontegg";
import { isMzInternalEmail } from "~/util";

const Org = () => {
  const { data: user } = useCurrentUser();
  if (!isMzInternalEmail(user.email) || !user.tenantId) return null;

  const truncatedOrgId = user.tenantId.slice(0, 9) + "...";

  return (
    <Tag
      size="sm"
      variant="outline"
      opacity={0.8}
      fontSize="80%"
      css={{ overflowWrap: "anywhere" }}
    >
      Org ID {truncatedOrgId}
      <CopyButton
        contents={user.tenantId}
        size="xs"
        ml={1}
        title="Copy org ID"
        height="auto"
      />
    </Tag>
  );
};

export default Org;
