import React from "react";

import { useSqlTyped } from "~/api/materialize";

import { DEFAULT_DATABASE_NAME } from "./databaseList";
import { buildSchemaListQuery, Schema } from "./schemaList";

export const DEFAULT_SCHEMA_NAME = "public";

/**
 * Fetches all schemas, optionally filtered by database
 */
function useSchemas(
  options: { databaseId?: string; filterByCreatePrivilege?: boolean } = {},
) {
  const query = React.useMemo(() => {
    const qb = buildSchemaListQuery({
      databaseId: options.databaseId,
      filterByCreatePrivilege: options.filterByCreatePrivilege,
    });
    return qb.compile();
  }, [options.databaseId, options.filterByCreatePrivilege]);

  return useSqlTyped(query);
}

export function isDefaultSchema(schema: Schema) {
  return (
    schema.name === DEFAULT_SCHEMA_NAME &&
    schema.databaseName === DEFAULT_DATABASE_NAME
  );
}

// A function to get the schema name from the search path the same way `SHOW SCHEMA` works;
export function getSchemaNameFromSearchPath(
  searchPath?: string,
  extantSchemaNames?: {
    name: Schema["name"];
  }[],
) {
  if (!searchPath || !extantSchemaNames) {
    return undefined;
  }
  const searchParts = (searchPath ?? "").split(",").map((str) => str.trim());

  for (const potentialSchemaName of searchParts) {
    if (extantSchemaNames.some(({ name }) => name === potentialSchemaName)) {
      return potentialSchemaName;
    }
  }

  return undefined;
}

export type UseSchemaResponse = ReturnType<typeof useSchemas>;

export const useSchemasColumns = ["id", "name", "databaseId", "databaseName"];

export default useSchemas;
