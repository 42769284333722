import { Flex } from "@chakra-ui/react";
import React from "react";
import { Navigate, Route, useParams } from "react-router-dom";

import { Sink } from "~/api/materialize/sink/sinkList";
import { LoadingContainer } from "~/components/LoadingContainer";
import ShowCreateModal from "~/components/ShowCreateModal";
import { ConnectorStatusPill } from "~/components/StatusPill";
import WorkflowGraph from "~/components/WorkflowGraph/WorkflowGraph";
import {
  Breadcrumb,
  PageBreadcrumbs,
  PageHeader,
  PageTabStrip,
} from "~/layouts/BaseLayout";
import {
  objectOrRedirect,
  relativeObjectPath,
  SchemaObjectRouteParams,
} from "~/platform/schemaObjectRouteHelpers";
import { SentryRoutes } from "~/sentry";
import { assert } from "~/util";

import { useSinkList } from "./queries";
import SinkErrors from "./SinkErrors";
import SinkOverview from "./SinkOverview";

const SinkOrRedirect: React.FC = () => {
  const params = useParams<SchemaObjectRouteParams>();
  const { data } = useSinkList();
  const result = objectOrRedirect({
    params,
    objects: data.rows,
    loading: false,
    relativePathFn: relativeObjectPath,
  });
  if (result.type === "loading") {
    return null;
  }
  if (result.type === "redirect") {
    return result.redirect;
  }
  return <SinkDetailRoutes sink={result.object} />;
};

export const SinkDetailRoutes = (props: { sink: Sink }) => {
  const params = useParams<SchemaObjectRouteParams>();

  return (
    <SentryRoutes>
      <Route
        path="/"
        element={
          <React.Suspense fallback={<LoadingContainer />}>
            <SinkOverview sink={props.sink} />
          </React.Suspense>
        }
      />
      <Route
        path="errors"
        element={
          <React.Suspense fallback={<LoadingContainer />}>
            <SinkErrors sink={props.sink} />
          </React.Suspense>
        }
      />
      <Route
        path="workflow"
        element={<WorkflowGraph focusedObjectId={params.id} />}
      />
      <Route path="*" element={<Navigate to="../" replace />} />
    </SentryRoutes>
  );
};

const SinkDetailBreadcrumbs = (props: { crumbs: Breadcrumb[] }) => {
  const params = useParams<SchemaObjectRouteParams>();

  // The route requires an ID
  assert(params.id);
  const { data } = useSinkList();
  const sink = data.rows.find((r) => r.id === params.id);
  if (!sink) {
    return null;
  }

  return (
    <PageBreadcrumbs
      crumbs={props.crumbs}
      contextMenuChildren={
        sink && <ShowCreateModal type="SINK" schemaObject={sink} />
      }
    >
      {sink?.status && (
        <Flex height="100%" alignItems="center">
          <ConnectorStatusPill ml={2} connector={sink} />
        </Flex>
      )}
    </PageBreadcrumbs>
  );
};

const SinkDetail = () => {
  const { objectName } = useParams<SchemaObjectRouteParams>();

  const breadcrumbs: Breadcrumb[] = React.useMemo(
    () => [{ title: "Sinks", href: ".." }, { title: objectName ?? "" }],
    [objectName],
  );

  return (
    <>
      <PageHeader variant="compact" sticky>
        <React.Suspense fallback={<PageBreadcrumbs crumbs={breadcrumbs} />}>
          <SinkDetailBreadcrumbs crumbs={breadcrumbs} />
        </React.Suspense>
        <PageTabStrip
          tabData={[
            { label: "Overview", href: "./.", end: true },
            { label: "Errors", href: "errors" },
            { href: `workflow`, label: "Workflow" },
          ]}
        />
      </PageHeader>

      <React.Suspense fallback={<LoadingContainer />}>
        <SinkOrRedirect />
      </React.Suspense>
    </>
  );
};

export default SinkDetail;
