import { atom, useAtom } from "jotai";

import { Breadcrumb, Tab } from "~/layouts/BaseLayout";

export const clusterBreadcrumbs = atom<Breadcrumb[]>([]);

export const clusterDetailsSubnav: Tab[] = [
  { label: "Overview", href: ".", end: true },
  { label: "Replicas", href: "replicas" },
  { label: "Materialized Views", href: "materialized-views" },
  { label: "Indexes", href: "indexes" },
  { label: "Sources", href: "sources" },
  { label: "Sinks", href: "sinks" },
];

export const clusterSubnavItems = atom<Tab[]>(clusterDetailsSubnav);

export function useClusterBreadcrumbs() {
  const [value] = useAtom(clusterBreadcrumbs);
  return value;
}

export function useClusterSubnavItems() {
  const [value] = useAtom(clusterSubnavItems);
  return value;
}

export function useSetClusterBreadcrumbs() {
  const [, set] = useAtom(clusterBreadcrumbs);
  return set;
}

export function useSetClusterSubnavItems() {
  const [, set] = useAtom(clusterSubnavItems);
  return set;
}
