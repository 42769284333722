import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

const UpstashLogoIcon = (props: IconProps) => {
  return (
    <Icon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <rect width="32" height="32" fill="#050505" />
      <path
        d="M7.02148 24.9988C10.9929 28.9702 17.4319 28.9702 21.4033 24.9988C25.3747 21.0274 25.3747 14.5884 21.4033 10.6169L19.6056 12.4147C22.5842 15.3932 22.5842 20.2225 19.6056 23.201C16.627 26.1796 11.7978 26.1796 8.8192 23.201L7.02148 24.9988Z"
        fill="#00E9A3"
      />
      <path
        d="M10.6172 21.4033C12.6029 23.389 15.8224 23.389 17.8081 21.4033C19.7938 19.4176 19.7938 16.1981 17.8081 14.2124L16.0104 16.0101C17.0032 17.003 17.0032 18.6127 16.0104 19.6056C15.0175 20.5984 13.4078 20.5984 12.4149 19.6056L10.6172 21.4033Z"
        fill="#00E9A3"
      />
      <path
        d="M24.9991 7.02141C21.0276 3.04999 14.5887 3.04999 10.6173 7.02141C6.64581 10.9928 6.64581 17.4318 10.6173 21.4033L12.4149 19.6055C9.43642 16.627 9.43642 11.7977 12.4149 8.81917C15.3935 5.84058 20.2228 5.84058 23.2013 8.81917L24.9991 7.02141Z"
        fill="#00E9A3"
      />
      <path
        d="M21.4029 10.6168C19.4172 8.63114 16.1977 8.63114 14.212 10.6168C12.2262 12.6026 12.2262 15.822 14.212 17.8077L16.0097 16.01C15.0168 15.0172 15.0168 13.4074 16.0097 12.4145C17.0025 11.4217 18.6123 11.4217 19.6052 12.4145L21.4029 10.6168Z"
        fill="#00E9A3"
      />
      <path
        d="M24.9991 7.02141C21.0276 3.04999 14.5887 3.04999 10.6173 7.02141C6.64581 10.9928 6.64581 17.4318 10.6173 21.4033L12.4149 19.6055C9.43642 16.627 9.43642 11.7977 12.4149 8.81917C15.3935 5.84058 20.2228 5.84058 23.2013 8.81917L24.9991 7.02141Z"
        fill="white"
        fillOpacity="0.8"
      />
      <path
        d="M21.4029 10.6168C19.4172 8.63114 16.1977 8.63114 14.212 10.6168C12.2262 12.6026 12.2262 15.822 14.212 17.8077L16.0097 16.01C15.0168 15.0172 15.0168 13.4074 16.0097 12.4145C17.0025 11.4217 18.6123 11.4217 19.6052 12.4145L21.4029 10.6168Z"
        fill="white"
        fillOpacity="0.8"
      />
    </Icon>
  );
};

export default UpstashLogoIcon;
