import {
  Box,
  Collapse,
  Divider,
  Heading,
  HStack,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import React, { PropsWithChildren, useMemo } from "react";

import { useCurrentOrganization } from "~/api/auth";
import { DailyCosts } from "~/api/cloudGlobalApi";
import TextLink from "~/components/TextLink";
import config from "~/config";
import * as chilipiper from "~/marketing/chilipiper";
import { useCurrentUser } from "~/queries/frontegg";
import { cloudRegionsSelector } from "~/store/cloudRegions";
import LowerLeftCornerIcon from "~/svg/LowerLeftCornerIcon";
import { MaterializeTheme } from "~/theme";
import { formatCurrency } from "~/utils/format";

import { useCreditBalance } from "./queries";
import { summarizePlanCosts } from "./utils";

type PlanDetailsProps = {
  region: "all" | string;
  dailyCosts: DailyCosts | null;
  timeSpan: number;
};

const planTypeDisplayNames = {
  partner: "Partner",
  capacity: "Capacity",
  internal: "Internal",
  "on-demand": "On Demand",
  evaluation: "Evaluation",
  uncategorized: "Unknown",
} as const;

const PlanSectionHeader = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <HStack justifyContent="space-between" px="4">
      <Text textStyle="text-ui-reg" color={colors.foreground.secondary}>
        {title}
      </Text>
      <Text textStyle="text-ui-med">{value}</Text>
    </HStack>
  );
};

const PlanSectionItem = ({
  title,
  value,
}: {
  title: string;
  value: string;
}) => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <HStack justifyContent="space-between" px="4" mt="2">
      <Text textStyle="text-ui-reg" color={colors.foreground.secondary}>
        <LowerLeftCornerIcon color={colors.border.secondary} />
        {title}
      </Text>
      <Text textStyle="text-ui-reg" color={colors.foreground.secondary}>
        {value}
      </Text>
    </HStack>
  );
};

const PlanSection = ({
  children,
  showDivider,
}: PropsWithChildren<{ showDivider?: boolean }>) => {
  return (
    <>
      {children}
      {(showDivider ?? true) && <Divider my="3" />}
    </>
  );
};

const PlanDetails = ({ region, dailyCosts, timeSpan }: PlanDetailsProps) => {
  const { data: user } = useCurrentUser();
  const { organization } = useCurrentOrganization();
  const { colors } = useTheme<MaterializeTheme>();
  const { data: creditBalance } = useCreditBalance();
  const [cloudRegions] = useAtom(cloudRegionsSelector);
  const { spanSummary, last30Summary } = useMemo(
    () =>
      summarizePlanCosts(
        dailyCosts ? dailyCosts.daily : null,
        timeSpan,
        cloudRegions,
      ),
    [dailyCosts, timeSpan, cloudRegions],
  );
  const isRegionFiltered = region !== "all";
  const isImpersonating = !!config.impersonation;
  return (
    <Box
      data-testid="plan-details"
      borderRadius="lg"
      border="1px solid"
      borderColor={colors.border.primary}
      backgroundColor={colors.background.primary}
      overflow="hidden"
    >
      <Heading as="h3" fontSize="sm" fontWeight="500" px="4" py="3">
        Plan details
      </Heading>
      <VStack alignItems="stretch" mt="3" gap={0}>
        <PlanSection>
          <PlanSectionHeader
            title="Plan type"
            value={
              organization?.subscription
                ? planTypeDisplayNames[organization.subscription.type]
                : "-"
            }
          />
        </PlanSection>
        <Collapse in={organization?.subscription?.type === "capacity"}>
          <PlanSection>
            <PlanSectionHeader
              title="Total balance"
              value={formatCurrency(creditBalance ?? 0)}
            />
          </PlanSection>
        </Collapse>
        <PlanSection>
          <PlanSectionHeader
            title="Last 30 days"
            value={formatCurrency(
              isRegionFiltered
                ? (last30Summary.regions.get(region) ?? 0)
                : last30Summary.total,
            )}
          />
          <Collapse in={!isRegionFiltered}>
            {Array.from(last30Summary.regions.entries()).map(
              ([regionId, regionTotal]) => (
                <PlanSectionItem
                  key={regionId}
                  title={regionId}
                  value={formatCurrency(regionTotal)}
                />
              ),
            )}
          </Collapse>
        </PlanSection>
        <PlanSection showDivider={!isImpersonating}>
          <PlanSectionHeader
            title="Daily average"
            value={formatCurrency(
              (isRegionFiltered
                ? (spanSummary.regions.get(region) ?? 0)
                : spanSummary.total) / timeSpan,
            )}
          />
          <Collapse in={!isRegionFiltered}>
            {Array.from(spanSummary.regions.entries()).map(
              ([regionId, regionTotal]) => (
                <PlanSectionItem
                  key={regionId}
                  title={regionId}
                  value={formatCurrency(regionTotal / timeSpan)}
                />
              ),
            )}
          </Collapse>
        </PlanSection>
      </VStack>
      {!isImpersonating ? (
        <Box backgroundColor={colors.background.secondary} padding="4" mt="-3">
          <Text textStyle="text-small" color={colors.foreground.secondary}>
            Getting towards the end of your balance?
          </Text>
          <TextLink
            textStyle="text-small"
            fontWeight="500"
            onClick={() => {
              if (user) {
                chilipiper.submit({
                  name: user.name,
                  email: user.email,
                  organizationId: user.tenantId,
                });
              }
            }}
          >
            Contact our sales team &#x027F6;
          </TextLink>
        </Box>
      ) : (
        <Box paddingBottom="4" />
      )}
    </Box>
  );
};

export default PlanDetails;
