import { atom } from "jotai";

import {
  fetchCurrentOrganization,
  queryKeys as authQueryKeys,
} from "~/api/auth";
import { Organization } from "~/api/cloudGlobalApi";
import { User } from "~/api/frontegg/types";
import config from "~/config";
import { fronteggQueryKeys, queryUser } from "~/queries/frontegg";
import { queryClient } from "~/queryClient";

import { currentEnvironmentState } from "./environments";

export const isCurrentOrganizationBlockedAtom = atom(async (get) => {
  // environmentdOverride is set during impersonation and local environmentd mode
  if (config.environmentdOverride) return false;
  const currentUser = await queryClient.ensureQueryData<User>({
    queryKey: fronteggQueryKeys.user(),
    queryFn: queryUser,
  });
  const currentOrganization = await queryClient.ensureQueryData<Organization>({
    queryKey: authQueryKeys.currentOrganization(currentUser.tenantId),
    queryFn: fetchCurrentOrganization,
  });
  if (currentOrganization.blocked) return true;
  const currentEnvironment = await get(currentEnvironmentState);
  return (
    currentEnvironment?.state === "enabled" &&
    currentEnvironment.status.health === "blocked"
  );
});
