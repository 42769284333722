import { createStore } from "jotai";

let store = createStore();

export function getStore() {
  return store;
}

/**
 * Creates a new Jotai store in module scope, resetting all global state.
 */
export function resetStore() {
  store = createStore();
}
