import { formatRFC3339 } from "date-fns";
import createClient from "openapi-fetch";

import { getFronteggToken } from "~/api/fronteggToken";
import { OpenApiRequestOptions } from "~/api/types";
import config from "~/config";
import { consoleVersionHeaders } from "~/version/api";

import { tokenRefreshMiddleware, withMiddleware } from "./fetch";
import { impersonationCloudApiMiddleware } from "./impersonationCloudApiMiddleware";
import {
  handleOpenApiResponse,
  handleOpenApiResponseWithBody,
} from "./openApiUtils";
import { components, paths } from "./schemas/global-api";

export type Organization = components["schemas"]["OrganizationResponse"];
export type PlanType = components["schemas"]["PlanType"];
export type Invoice = components["schemas"]["Invoice"];
export type Region = components["schemas"]["Region"];
export type Regions = components["schemas"]["Regions"];
export type CreditBlock = components["schemas"]["CreditBlock"];
export type DailyCosts = components["schemas"]["DailyCostResponse"];
export type AllCosts = components["schemas"]["AllCosts"];
export type DailyCostKey = keyof components["schemas"]["AllCosts"];
export type Prices =
  | components["schemas"]["ComputePrice"]
  | components["schemas"]["StoragePrice"]
  | components["schemas"]["NetworkPrice"];
export type Marketplace = components["schemas"]["Marketplace"];

const fetchWithMiddleware = withMiddleware(
  tokenRefreshMiddleware,
  // The impersonation middleware should always be last in the chain since it
  // is terminal.
  impersonationCloudApiMiddleware,
);

const client = createClient<paths>({
  baseUrl: config.cloudGlobalApiUrl,
  fetch: fetchWithMiddleware,
});

function buildHeaders(headers?: HeadersInit) {
  return {
    authorization: `Bearer ${getFronteggToken()}`,
    ...consoleVersionHeaders(),
    ...headers,
  };
}

export async function getCurrentOrganization(
  requestOptions: OpenApiRequestOptions = {},
) {
  const { headers, ...options } = requestOptions;
  const { data, response } = await client.GET("/api/organization", {
    signal: requestOptions?.signal,
    headers: buildHeaders(headers),
    ...options,
  });
  return handleOpenApiResponseWithBody(data, response);
}

export async function onboardCurrentOrganization(
  requestOptions: OpenApiRequestOptions = {},
) {
  const { headers, ...options } = requestOptions;
  const { data, response } = await client.POST("/api/organization/onboarded", {
    signal: requestOptions?.signal,
    headers: buildHeaders(headers),
    ...options,
  });
  return handleOpenApiResponse(data, response);
}

export async function recentInvoices(
  params: {
    draft?: boolean;
    issued?: boolean;
    paid?: boolean;
    void?: boolean;
    synced?: boolean;
  } = {},
  requestOptions: OpenApiRequestOptions = {},
) {
  const { headers, ...options } = requestOptions;
  const { data, response } = await client.GET("/api/invoices", {
    params: { query: params },
    signal: requestOptions?.signal,
    headers: buildHeaders(headers),
    ...options,
  });
  return handleOpenApiResponseWithBody(data, response);
}

export async function getCloudRegions(
  requestOptions: OpenApiRequestOptions = {},
) {
  const { headers, ...options } = requestOptions;
  const { data, response } = await client.GET("/api/cloud-regions", {
    signal: requestOptions?.signal,
    headers: buildHeaders(headers),
    ...options,
  });
  return handleOpenApiResponseWithBody(data, response);
}

export async function getCredits(requestOptions: OpenApiRequestOptions = {}) {
  const { headers, ...options } = requestOptions;
  const { data, response } = await client.GET("/api/credits", {
    signal: requestOptions?.signal,
    headers: buildHeaders(headers),
    ...options,
  });
  return handleOpenApiResponseWithBody(data, response);
}

export async function getDailyCosts(
  startDate: Date,
  endDate: Date,
  requestOptions: OpenApiRequestOptions = {},
) {
  const { headers, ...options } = requestOptions;
  const { data, response } = await client.GET("/api/costs/daily", {
    params: {
      query: {
        startDate: formatRFC3339(startDate),
        endDate: formatRFC3339(endDate),
      },
    },
    signal: requestOptions?.signal,
    headers: buildHeaders(headers),
    ...options,
  });
  return handleOpenApiResponseWithBody(data, response);
}
