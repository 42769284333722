import { Flex } from "@chakra-ui/react";
import dagre from "@dagrejs/dagre";
import * as Sentry from "@sentry/react";
import React from "react";

import useWorkflowGraph from "~/api/materialize/useWorkflowGraph";
import {
  useWorkflowGraphNodes,
  WorkflowGraphNode,
} from "~/api/materialize/useWorkflowGraphNodes";
import ErrorBox from "~/components/ErrorBox";
import { MainContentContainer } from "~/layouts/BaseLayout";
import { useMaterializationLag } from "~/platform/clusters/queries";
import { clamp, notNullOrUndefined } from "~/util";

import { AppErrorBoundary } from "../AppErrorBoundary";
import { LoadingContainer } from "../LoadingContainer";
import { Canvas } from "./Canvas";
import { getDownstreamNodes, getUpstreamNodes } from "./graph";
import { GraphEdge, STROKE_WIDTH } from "./GraphEdge";
import { GraphEdgeContainer } from "./GraphEdgeContainer";
import {
  BASE_NODE_HEIGHT,
  GraphNode,
  NODE_HEIGHT_WITH_STATUS,
  NODE_WIDTH,
} from "./GraphNode";
import WorkflowGraphSidebar from "./Sidebar";

const MIN_EDGE_MARGIN = STROKE_WIDTH / 2;

const WorkflowGraph = (props: {
  /** Object that we are generating a DAG for */
  focusedObjectId?: string;
}) => {
  return (
    <MainContentContainer justifyContent="center" m="0" p="0">
      <AppErrorBoundary message="An error occurred fetching workflow data.">
        <React.Suspense fallback={<LoadingContainer />}>
          <WorkflowGraphInner {...props} />
        </React.Suspense>
      </AppErrorBoundary>
    </MainContentContainer>
  );
};

const WorkflowGraphInner = ({
  focusedObjectId,
}: {
  focusedObjectId?: string;
}) => {
  const [selectedNodeId, setSelectedNodeId] = React.useState<
    string | undefined
  >(undefined);

  const {
    isInitiallyLoading: areEdgesLoading,
    results: graphEdges,
    failedToLoad: edgesFailedToLoad,
  } = useWorkflowGraph({
    objectId: focusedObjectId,
  });

  const edgeIds = React.useMemo(() => {
    if (!graphEdges || graphEdges.length < 1)
      return focusedObjectId ? [focusedObjectId] : [];

    const ids = new Set<string>();
    for (const edge of graphEdges) {
      if (edge.parentId) ids.add(edge.parentId);
      if (edge.childId) ids.add(edge.childId);
    }
    return Array.from(ids.values());
  }, [focusedObjectId, graphEdges]);

  const {
    isInitiallyLoading: areNodesLoading,
    results: graphNodes,
    failedToLoad: nodesFailedToLoad,
  } = useWorkflowGraphNodes({
    objectIds: edgeIds,
  });

  const objectsWithLag = React.useMemo(() => {
    return graphNodes?.map((node) => node.id) ?? [];
  }, [graphNodes]);

  const { data, isLoading: isLagmapLoading } = useMaterializationLag({
    objectIds: objectsWithLag,
  });

  const { lagMap } = data ?? {};
  const graph = React.useMemo(() => {
    if (areNodesLoading || areEdgesLoading) return null;

    // Create a new directed graph
    const g = new dagre.graphlib.Graph();

    // Initialize the graph with the distance between ranks (levels of nodes)
    // as a multiple of the height of a node. The multiplier '2' works well for
    // graphs with a lot of clumped, overlapping edges between levels
    g.setGraph({ ranksep: NODE_HEIGHT_WITH_STATUS * 2 });
    // Default to assigning a new object as a label for each new edge
    g.setDefaultEdgeLabel(() => ({}));

    for (const o of graphNodes ?? []) {
      // Create a node for each object
      // this will be called once per edge, setting the node multiple times
      // but doesn't seem to be an issue
      g.setNode(o.id, {
        label: o.name,
        width: NODE_WIDTH,
        height: NODE_HEIGHT_WITH_STATUS,
      });
    }
    for (const o of graphEdges ?? []) {
      if (o.parentId && o.childId) {
        g.setEdge(o.parentId, o.childId);
      }
    }
    // This guard shouldn't be required: https://github.com/dagrejs/dagre/issues/428
    if (g.nodes().length > 0 || g.edges().length > 0) {
      // Mutate the graph object to include points for nodes and edges
      dagre.layout(g);
    }
    return g;
  }, [areEdgesLoading, areNodesLoading, graphEdges, graphNodes]);

  const nodeMap = React.useMemo(() => {
    return new Map<string, WorkflowGraphNode>(
      graphNodes?.map((r) => [r.id, r]) ?? [],
    );
  }, [graphNodes]);

  const upstreamNodes = React.useMemo(() => {
    if (!selectedNodeId || !graph) return [];
    return getUpstreamNodes(selectedNodeId, graph)
      .map((id) => nodeMap.get(id))
      .filter(notNullOrUndefined);
  }, [graph, nodeMap, selectedNodeId]);

  const downstreamNodes = React.useMemo(() => {
    if (!selectedNodeId || !graph) return [];
    return getDownstreamNodes(selectedNodeId, graph)
      .map((id) => nodeMap.get(id))
      .filter(notNullOrUndefined);
  }, [graph, nodeMap, selectedNodeId]);

  React.useEffect(() => {
    if (!selectedNodeId && focusedObjectId) {
      setSelectedNodeId(focusedObjectId);
    }
  }, [focusedObjectId, selectedNodeId]);

  const { height, width } = React.useMemo(() => {
    if (!graph) return { height: null, width: null };

    return graph.graph();
  }, [graph]);

  const nodePositionMap = React.useMemo(() => {
    const map = new Map<
      string,
      { top: number; right: number; left: number; bottom: number }
    >();
    if (!graph || !graphNodes) return map;

    for (const { id } of graphNodes) {
      const node = graph.node(id);
      if (!node) continue;

      const left = node.x - node.width / 2;
      const right = left + node.width;
      const top = node.y - node.height / 2;
      const hasStatus =
        Boolean(lagMap?.get(id)) || Boolean(nodeMap.get(id)?.sourceStatus);
      const bottom = hasStatus ? top + node.height : top + BASE_NODE_HEIGHT;
      map.set(id, { top, right, left, bottom });
    }
    return map;
  }, [graph, graphNodes, lagMap, nodeMap]);

  const selectedNode = React.useMemo(
    () => (graph && selectedNodeId ? graph.node(selectedNodeId) : null),
    [selectedNodeId, graph],
  );
  // We order edges by placing edges adjacent to the selected node last to ensure they are rendered on top
  const orderedGraphEdges = React.useMemo(() => {
    const edges = graph?.edges() ?? [];

    const edgesAdjacentToSelectedNode = edges.filter(
      (edge) => edge.v === selectedNodeId || edge.w === selectedNodeId,
    );

    const otherEdges = edges.filter(
      (edge) => !(edge.v === selectedNodeId || edge.w === selectedNodeId),
    );

    const orderedEdges = [...otherEdges, ...edgesAdjacentToSelectedNode];

    return orderedEdges;
  }, [selectedNodeId, graph]);

  if (graphNodes && graphNodes.length === 0) {
    // This should not happen in practice, even if an object is has no other dependencies
    // to show, the focused object should still show up. This generally happens if you
    // try to render the workflow graph for an object that is not valid object in the
    // workflow graph, e.g. a progress source.
    return <ErrorBox message="Unable to render workflow graph." />;
  }

  if (edgesFailedToLoad || nodesFailedToLoad) {
    return <ErrorBox message="An error occurred fetching workflow data." />;
  }

  if (
    !focusedObjectId ||
    !selectedNodeId ||
    !graph ||
    // this guards against the moment where the edges have not yet loaded,
    // areNodesLoading is false, since there is no query to run yet. I think we should
    // be able to remove it when we migrate to react query.
    graphNodes === null ||
    areNodesLoading ||
    areEdgesLoading ||
    isLagmapLoading
  ) {
    return <LoadingContainer />;
  }

  // This should never happen in practice
  if (!width || !height) {
    Sentry.captureException(
      new Error("WorkflowGraph missing height and width"),
    );
    return <ErrorBox message="An error occurred rendering the workflow." />;
  }

  return (
    <Flex position="relative" height="100%" flex="1">
      <Canvas width={width} height={height} selectedNode={selectedNode}>
        {graph.nodes().map((id: string) => {
          const node = graph.node(id);
          const nodePosition = nodePositionMap.get(id);
          if (!node || !nodePosition) return null;

          return (
            <GraphNode
              key={id}
              graph={graph}
              nodeLagInfo={lagMap?.get(id)}
              node={nodeMap.get(id)}
              isSelected={id === selectedNodeId}
              left={nodePosition.left}
              top={nodePosition.top}
              width={node.width}
              onClick={() => {
                setSelectedNodeId(id);
              }}
            />
          );
        })}
        <GraphEdgeContainer width={width} height={height}>
          {orderedGraphEdges.map((e) => {
            if (!nodePositionMap) return;

            const edge = graph.edge(e);
            const parentNodePosition = nodePositionMap.get(e.v);
            if (!parentNodePosition) return;

            // Dagre has a bug where point values can go outside the graph bounds
            // https://github.com/dagrejs/dagre/issues/291
            const [firstPoint, ...points] = edge.points.map((p) => ({
              x: clamp(p.x, MIN_EDGE_MARGIN, width - MIN_EDGE_MARGIN),
              y: clamp(p.y, MIN_EDGE_MARGIN, height - MIN_EDGE_MARGIN),
            }));

            return (
              <GraphEdge
                key={e.v + e.w}
                from={graph.node(e.v)}
                to={graph.node(e.w)}
                points={[
                  // Because our node heights vary, we have to look up the value of the
                  // parent node
                  { x: firstPoint.x, y: parentNodePosition.bottom },
                  ...points,
                ]}
                isAdjacentToSelectedNode={
                  e.v === selectedNodeId || e.w === selectedNodeId
                }
              />
            );
          })}
        </GraphEdgeContainer>
      </Canvas>
      <WorkflowGraphSidebar
        selectedNode={nodeMap.get(selectedNodeId)}
        upstreamNodes={upstreamNodes}
        downstreamNodes={downstreamNodes}
        onNodeClick={setSelectedNodeId}
        nodeMap={nodeMap}
        lagInfo={selectedNodeId ? lagMap?.get(selectedNodeId) : undefined}
      />
    </Flex>
  );
};

export default WorkflowGraph;
