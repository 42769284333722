/* Globals injected by Vite define.*/

import { BuildConstants, getBuildConstants } from "./buildConstants";
import { CloudRegion } from "./store/cloudRegions";
import { assert, base32UuidDecode } from "./util";
import storageAvailable from "./utils/storageAvailable";

const currentStackKey = "mz-current-stack";

/**
 * The current stack is a string value that represents which cloud resources the console
 * is currently pointing at. This can be changed at runtime, though only with a full
 * page refresh.
 *
 * Possible values:
 * production - Cloud production
 * staging - Cloud staging
 * $USER - Personal stack
 * local - Local development stack running in KinD
 */
export const getCurrentStack = (
  hostname: string,
  defaultStack: string = __DEFAULT_STACK__,
) => {
  if (__FORCE_OVERRIDE_STACK__) {
    return __FORCE_OVERRIDE_STACK__;
  }
  if (storageAvailable("localStorage")) {
    const stack = window.localStorage.getItem(currentStackKey);
    if (stack) {
      return stack;
    }
  }
  if (
    hostname.startsWith("staging.") ||
    hostname.startsWith("oidc-test.") ||
    hostname.startsWith("local.console") ||
    hostname.match(/^.*\.preview/)
  ) {
    // matches staging.console.materialize.com
    // or oid-test.console.materialize.com
    // or local.console.materialize.com
    // or *.preview.console.materialize.com
    return "staging";
  }
  if (hostname.startsWith("loadtest.")) {
    return "loadtest";
  }
  if (hostname.startsWith("local.dev.")) {
    return defaultStack;
  }
  const personalStackMatch = hostname.match(/^\w*\.(staging|dev)/);
  if (personalStackMatch) {
    // personal stack, return $USER.$ENV
    return personalStackMatch[0];
  }
  return defaultStack;
};

/**
 * Returns a string that represents the environment of the console build, which is
 * separate from the current stack. For example, you might be on staging console, and
 * use the stack switcher to point at a personal stack.
 */
export const getConsoleEnvironment = ({
  hostname,
  isImpersonation,
}: {
  hostname: string;
  isImpersonation: boolean;
}) => {
  if (isImpersonation) {
    return "production";
  }
  if (hostname === "console.materialize.com") {
    return "production";
  }
  if (hostname === "staging.console.materialize.com") {
    return "staging";
  }
  if (hostname.match(/^.*\.preview/)) {
    // *.preview.console.materialize.com
    return "preview";
  }
  return "development";
};

export const getLaunchDarklyKey = (consoleEnv: string) => {
  if (consoleEnv === "production") {
    return "63604cf8f9860a0c1f3c7099";
  }
  if (consoleEnv === "staging" || consoleEnv === "preview") {
    // Staging key
    return "6388e8a24ac9d112339757f3";
  }

  // Fall back to development key
  return "6388e8b9750ee71144183456";
};

export const getSegmentApiKey = (consoleEnv: string) => {
  if (consoleEnv === "production") {
    // Production key
    return "NCe6YQCHM9g04X9yEBUFtoWOuqZU8J1m";
  }

  // Since segment staging isn't hooked up to anything, use the development key for
  // everything that's not production
  return "dGeQYRjmGVsqDI0KIARrAhTvk1BdJJhk";
};

export const setCurrentStack = (stackName: string) => {
  if (storageAvailable("localStorage")) {
    window.localStorage.setItem(currentStackKey, stackName);
  }
};

export const getFronteggUrl = (stack: string) => {
  if (stack === "production") {
    return `https://admin.cloud.materialize.com`;
  }
  if (stack === "local") {
    // local development against cloud services uses staging frontegg
    return `https://admin.staging.cloud.materialize.com`;
  }
  return `https://admin.${stack}.cloud.materialize.com`;
};

export const getCloudGlobalApiUrl = ({
  stack,
  isImpersonation,
}: {
  stack: string;
  isImpersonation: boolean;
}) => {
  if (stack === "local") {
    return "http://localhost:8003";
  }
  if (isImpersonation) {
    if (window.location.hash === "#staging") {
      console.warn("Detected request for staging APIs");
      stack = "staging";
    }
    return `https://global-api-aws-us-east-1-${stack.replaceAll(
      ".",
      "-",
    )}.materialize.teleport.sh`;
  }
  if (stack === "production") {
    return "https://api.cloud.materialize.com";
  }
  return `https://api.${stack}.cloud.materialize.com`;
};

// We can't use localhost here because of an issue with playwright Safari:
// https://github.com/microsoft/playwright/issues/17368
// local.dev.materialize.com is a real DNS entry pointing to the loopback address
export const getE2eTestStack = (hostname: string) => {
  const isLocalStack =
    hostname === "local.dev.materialize.com" || hostname === "localhost";
  const cloudHost = process.env.CLOUD_HOST || "staging.cloud.materialize.com";

  if (isLocalStack) return "local";
  if (cloudHost === "cloud.materialize.com") return "production";
  return "staging";
};

/**
 * Are we in a browser? If not, we can assume this is a test environment.
 *
 * Note that this is *true* in jsdom.
 */
const isBrowser =
  typeof window !== "undefined" && typeof window.document !== "undefined";

const consoleLocationOrUrl = isBrowser
  ? location
  : new URL(
      process.env.CONSOLE_ADDR || "http://local.dev.materialize.com:3000",
    );

const getConsoleAddress = () =>
  `${consoleLocationOrUrl.protocol}//${consoleLocationOrUrl.hostname}${
    consoleLocationOrUrl.port ? ":" + consoleLocationOrUrl.port : ""
  }`;

const consoleAddress = getConsoleAddress();
const hostname = consoleLocationOrUrl.hostname;

const currentStack = isBrowser
  ? getCurrentStack(hostname)
  : getE2eTestStack(hostname);

const getOrganizationIdFromBase32 = (encoded: string) => {
  try {
    return base32UuidDecode(encoded.toUpperCase());
  } catch (e) {
    console.error(e);
    throw new Error("Invalid impersonation subdomain");
  }
};

const buildConstants: BuildConstants = isBrowser
  ? getBuildConstants()
  : {
      basename: "",
      defaultStack: "local",
      envdHost: undefined,
      forceOverrideStack: undefined,
      impersonationHostname: "",
      sentryEnabled: false,
      sentryRelease: "sentry-release",
    };

export const getImpersonatedEnvironment = (consoleHost: string) => {
  const host = buildConstants.impersonationHostname ?? consoleHost;
  const matches = host.match(/(^.*)\.materialize.teleport.sh/);
  if (matches && matches.length > 1) {
    const [, enviromentSubdomain] = matches;
    const [cloudProvider, ...parts] = enviromentSubdomain.split("-");
    const ordinal = parts.pop();
    const encodedOrganizationId = parts.pop();
    assert(encodedOrganizationId);
    const organizationId = getOrganizationIdFromBase32(
      encodedOrganizationId.toUpperCase(),
    );
    const cloudRegion = parts.join("-");
    return {
      provider: cloudProvider,
      region: cloudRegion,
      regionId: `${cloudProvider}/${cloudRegion}`,
      organizationId,
      environmentId: `environment-${organizationId}-${ordinal}`,
      // Since environmentd is reverse proxying console, it's the same hostname
      environmentdHttpAddress: consoleHost,
    };
  }
  return null;
};

export type ImpersonationConfig = ReturnType<typeof getImpersonatedEnvironment>;

export const getCloudRegions = (
  envdHost: string | undefined,
  impersonation: ImpersonationConfig,
): CloudRegion[] | null => {
  if (impersonation) {
    return [
      {
        provider: impersonation.provider,
        region: impersonation.region,
        regionApiUrl: "",
      },
    ];
  }

  if (envdHost) {
    return [
      {
        provider: "local",
        region: "local",
        regionApiUrl: "",
      },
    ];
  }

  return null;
};

export const getEnvironmentdConfig = (
  consoleHost: string,
  envdHost: string | undefined,
  cloudRegion: CloudRegion | undefined,
  impersonation: ImpersonationConfig,
) => {
  if (impersonation) {
    return {
      environmentdHttpAddress: impersonation.environmentdHttpAddress,
      regionId: impersonation.regionId,
    };
  }
  if (envdHost && cloudRegion) {
    return {
      environmentdHttpAddress: consoleHost,
      regionId: `${cloudRegion.provider}/${cloudRegion.region}`,
    };
  }

  return null;
};

export const getTempoDatasourceIds = (
  stack: string,
): Record<string, string> => {
  if (stack === "production") {
    return {
      "aws-us-east-1": "N0Q_O-14z",
      "aws-us-west-2": "f1fa3361-9db3-49a0-b9b6-f5e10bc4375c",
      "aws-eu-west-1": "2EllOa14z",
    };
  }
  if (stack === "staging") {
    return {
      "aws-us-east-1": "e47e25f5-623b-4790-b2eb-a11c3137d7b4",
      "aws-eu-west-1": "e493dba3-b407-4300-8211-7ab8d29671a2",
    };
  }
  return {};
};

export const getEnvironmentdScheme = () => {
  // local impersonation uses http to proxy through teleport
  if (buildConstants.envdHost) return "http";
  // SQL testing with mzcompose uses http
  if (process.env.NODE_ENV === "test") return "http";
  // local development and production use https
  return "https";
};

const isLocalEnvironmentd = Boolean(buildConstants.envdHost);

const impersonation = getImpersonatedEnvironment(consoleLocationOrUrl.host);
const isImpersonation = Boolean(impersonation);
const consoleEnvironment = getConsoleEnvironment({
  hostname,
  isImpersonation,
});
const cloudRegionsOverride = getCloudRegions(
  buildConstants.envdHost,
  impersonation,
);

const config = {
  /**
   * An optional prefix path used by impersonation and local environmentd development,
   * this should only be set to "" or "/internal-console".
   * */
  basename: buildConstants.basename,
  cloudGlobalApiUrl: getCloudGlobalApiUrl({
    stack: currentStack,
    isImpersonation,
  }),
  cloudRegionsOverride,
  /** The base url of console e.g. https://console.materialize.com */
  consoleAddress,
  /** The current cloud stack e.g. "local" or "production".
   * For Vitest, we stub config as 'test' via a Vitest file mock.
   * Prefer a dedicated config value instead of using this directly
   * */
  currentStack,
  /** Allows Console to point at a local environmentd */
  environmentdOverride: getEnvironmentdConfig(
    consoleLocationOrUrl.host,
    buildConstants.envdHost,
    cloudRegionsOverride?.[0],
    impersonation,
  ),
  /** The scheme that should be used. Local dev now requires SSL (unless one is bypassing the load balancer) */
  environmentdScheme: getEnvironmentdScheme(),
  environmentdWebsocketScheme: buildConstants.envdHost ? "ws" : "wss",
  externalRegistration: consoleEnvironment === "production" ? true : false,
  fronteggAuthDisabled: isImpersonation || isLocalEnvironmentd,
  fronteggUrl: getFronteggUrl(currentStack),
  /**
   * Region and environment details used during user organization impersonation.
   * Value is null during normal console usage
   */
  impersonation: impersonation,
  isImpersonating: !!impersonation,
  launchDarklyKey: getLaunchDarklyKey(consoleEnvironment),
  queryRetriesEnabled: buildConstants.defaultStack !== "test",
  segmentApiKey: isImpersonation ? null : getSegmentApiKey(consoleEnvironment),
  sentryDsn:
    "https://13c8b3a8d1e547c9b9493de997b04337@o561021.ingest.sentry.io/5699757",
  sentryEnabled: buildConstants.sentryEnabled,
  sentryEnvironment: currentStack,
  sentryRelease: buildConstants.sentryRelease,
  stackSwitcherEnabled: !isImpersonation && consoleEnvironment !== "production",
  tempoDatasourceIds: getTempoDatasourceIds(currentStack),
};

export type GlobalConfig = typeof config;

export default config;
