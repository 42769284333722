import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { Navigate, Route, useParams } from "react-router-dom";

import {
  MaterializedViewsResponse,
  useMaterializedViews,
} from "~/api/materialize/cluster/useMaterializedViews";
import { useSchemaObjectFilters } from "~/components/SchemaObjectFilter/useSchemaObjectFilters";
import {
  legacyRelativeDataflowVisualizerPath,
  legacyRelativeMaterializedViewPath,
} from "~/platform/routeHelpers";
import { objectOrRedirect } from "~/platform/schemaObjectRouteHelpers";
import { SentryRoutes } from "~/sentry";
import {
  clusterDetailsSubnav,
  useSetClusterBreadcrumbs,
  useSetClusterSubnavItems,
} from "~/store/clusters";

import { ClusterParams } from "./ClusterRoutes";
import MaterializedViewsList from "./MaterializedViewsList";

const DataflowVisualizer = React.lazy(() => import("./DataflowVisualizer"));

const WorkflowGraph = React.lazy(
  () => import("~/components/WorkflowGraph/WorkflowGraph"),
);

const MaterializedViewOrRedirect: React.FC<{
  materializedViewsResponse: MaterializedViewsResponse;
}> = ({ materializedViewsResponse }) => {
  const { results: materializedViews, isInitiallyLoading } =
    materializedViewsResponse;
  const params = useParams();
  const result = objectOrRedirect({
    params,
    objects: materializedViews,
    loading: isInitiallyLoading,
    relativePathFn: legacyRelativeMaterializedViewPath,
  });
  const setClusterBreadcrumbs = useSetClusterBreadcrumbs();
  const setClusterSubnav = useSetClusterSubnavItems();
  const resultObject = result.type === "object" ? result.object : undefined;
  const flags = useFlags();
  const dataflowVisualizerEnabled = flags["visualization-features"];

  React.useEffect(() => {
    if (resultObject) {
      setClusterBreadcrumbs([
        // Because these NavLinks are rendered in the cluster page, outside this nested
        // route, it has to include the materialized-view path
        { title: "Materialized Views", href: "materialized-views" },
        { title: resultObject.name },
      ]);
    }
    return () => {
      setClusterBreadcrumbs([]);
    };
  }, [resultObject, setClusterBreadcrumbs]);
  React.useEffect(() => {
    if (resultObject) {
      const subNavItems = [];
      subNavItems.push({
        label: "Workflow",
        // Because these NavLinks are rendered in the cluster page, outside this nested
        // route, it has to include the materialized-view path
        href: `materialized-views/${legacyRelativeMaterializedViewPath(
          resultObject,
        )}`,
      });
      if (dataflowVisualizerEnabled) {
        subNavItems.push({
          label: "Dataflow Visualizer",
          // Because these NavLinks are rendered in the cluster page, outside this nested
          // route, it has to include the materialized-view path
          href: `materialized-views/${legacyRelativeDataflowVisualizerPath(
            resultObject,
          )}`,
        });
      }
      setClusterSubnav(subNavItems);
    }
    return () => {
      setClusterSubnav(clusterDetailsSubnav);
    };
  }, [resultObject, result.type, setClusterSubnav, dataflowVisualizerEnabled]);

  if (result.type === "redirect") {
    return result.redirect;
  } else {
    return (
      <SentryRoutes>
        <Route path="/" element={<Navigate to="workflow" replace />} />
        <Route
          path="workflow"
          element={
            <WorkflowGraph
              focusedObjectId={
                result.type === "object" ? result.object.id : undefined
              }
            />
          }
        />
        <Route path="dataflow-visualizer" element={<DataflowVisualizer />} />
      </SentryRoutes>
    );
  }
};

const MaterializedViewRoutes = () => {
  const { clusterId } = useParams<ClusterParams>();

  const { databaseFilter, schemaFilter, nameFilter } = useSchemaObjectFilters(
    "materializeViewName",
  );

  const materializedViewsResponse = useMaterializedViews({
    databaseId: databaseFilter.selected?.id,
    schemaId: schemaFilter.selected?.id,
    nameFilter: nameFilter.name,
    clusterId,
  });
  return (
    <SentryRoutes>
      <Route
        path="/*"
        element={
          <MaterializedViewsList
            materializedViewsResponse={materializedViewsResponse}
            databaseFilter={databaseFilter}
            schemaFilter={schemaFilter}
            nameFilter={nameFilter}
          />
        }
      />
      <Route
        path=":id/:databaseName/:schemaName/:objectName/*"
        element={
          <MaterializedViewOrRedirect
            materializedViewsResponse={materializedViewsResponse}
          />
        }
      />
    </SentryRoutes>
  );
};

export default MaterializedViewRoutes;
