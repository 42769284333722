import { QueryKey } from "@tanstack/react-query";
import { InferResult, sql } from "kysely";

import { queryBuilder } from "~/api/materialize/db";
import { executeSqlV2 } from "~/api/materialize/executeSqlV2";
import { STATEMENT_LIFECYCLE_TABLE } from "~/api/materialize/query-history/queryHistoryDetail";
import {
  QUERY_HISTORY_LIST_TABLE,
  QUERY_HISTORY_LIST_TABLE_REDACTED,
} from "~/api/materialize/query-history/queryHistoryList";
import { DB } from "~/types/materialize";

export type Privilege = "SELECT" | "INSERT" | "UPDATE" | "DELETE";

export type PrivilegeObject = {
  relation: keyof DB;
  privilege: Privilege;
};

const PRIVILEGE_OBJECTS: PrivilegeObject[] = [
  {
    relation: QUERY_HISTORY_LIST_TABLE,
    privilege: "SELECT",
  },
  {
    relation: QUERY_HISTORY_LIST_TABLE_REDACTED,
    privilege: "SELECT",
  },
  {
    relation: STATEMENT_LIFECYCLE_TABLE,
    privilege: "SELECT",
  },
];

/**
 *
 * Queries a privilege table of the following structure:
 *
 * relation              | privilege | hasPrivilege
 * ------------------------------------------------
 * mz_secrets | select    | true
 *
 * Each relation must exist in mz_relations.
 *
 * In the future, we can expand this function to objects other than relations.
 */
export function buildHasTablePrivilegeQuery({
  relation,
  privilege,
}: PrivilegeObject) {
  const relationText = sql.lit(relation);
  const privilegeText = sql.lit(privilege);

  return queryBuilder.selectNoFrom((eb) => [
    relationText.as("relation"),
    privilegeText.as("privilege"),
    eb
      .fn<boolean>("has_table_privilege", [relationText, privilegeText])
      .as("hasTablePrivilege"),
  ]);
}

export function buildIsSuperuserQuery() {
  return queryBuilder.selectNoFrom((eb) => [
    eb.fn<boolean>("mz_is_superuser").as("isSuperUser"),
  ]);
}

export function buildPrivilegeTableQuery() {
  const [firstPrivilegeObject, ...privilegeObjectsRest] = PRIVILEGE_OBJECTS;

  let qb = buildHasTablePrivilegeQuery(firstPrivilegeObject);

  privilegeObjectsRest.forEach((privilegeObject) => {
    qb = qb.union(buildHasTablePrivilegeQuery(privilegeObject));
  });

  return qb;
}

/**
 * Fetches the privilege table and super user status.
 */
export async function fetchPrivilegeTable({
  queryKey,
  requestOptions,
}: {
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  const isSuperUserQuery = buildIsSuperuserQuery().compile();
  const privilegeTableQuery = buildPrivilegeTableQuery().compile();

  return executeSqlV2({
    queries: [isSuperUserQuery, privilegeTableQuery] as const,
    queryKey: queryKey,
    requestOptions,
  });
}

export type PrivilegeTable = InferResult<
  ReturnType<typeof buildPrivilegeTableQuery>
>;

export type PrivilegeRow = PrivilegeTable[0];
