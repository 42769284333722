import React from "react";

import { isSystemCluster, queryBuilder, useSqlTyped } from "~/api/materialize";
import {
  buildClusterReplicaUtilizationTable,
  getOwners,
} from "~/api/materialize/expressionBuilders";

export function useClusterReplicasWithUtilization(clusterId?: string) {
  const query = React.useMemo(() => {
    if (!clusterId) return null;

    return queryBuilder
      .selectFrom("mz_cluster_replicas as cr")
      .innerJoin(getOwners().as("owners"), "owners.id", "cr.owner_id")
      .innerJoin("mz_clusters as c", "c.id", "cr.cluster_id")
      .innerJoin(
        buildClusterReplicaUtilizationTable().as("cru"),
        "cr.id",
        "cru.replica_id",
      )
      .select([
        "cr.id",
        "cr.name",
        "c.name as clusterName",
        "cr.size",
        "cru.cpu_percent as cpuPercent",
        "cru.memory_percent as memoryPercent",
        "c.managed",
        "owners.isOwner",
      ])
      .where("c.id", "=", clusterId)
      .orderBy("cr.id")
      .compile();
  }, [clusterId]);

  return useSqlTyped(query, {
    transformRow: (row) => ({
      ...row,
      isOwner: Boolean(
        clusterId && !isSystemCluster(clusterId) && !row.managed && row.isOwner,
      ),
    }),
  });
}

export type ClusterReplicasWithUtilizationResponse = ReturnType<
  typeof useClusterReplicasWithUtilization
>;

export type ClusterReplicaWithUtilizaton = NonNullable<
  ClusterReplicasWithUtilizationResponse["results"]
>[0];
