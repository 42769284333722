import { Box, Flex } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { redirect, Route, useParams } from "react-router-dom";

import { Source } from "~/api/materialize/source/sourceList";
import DeleteObjectMenuItem from "~/components/DeleteObjectMenuItem";
import { LoadingContainer } from "~/components/LoadingContainer";
import ShowCreateModal from "~/components/ShowCreateModal";
import { ConnectorStatusPill } from "~/components/StatusPill";
import WorkflowGraph from "~/components/WorkflowGraph/WorkflowGraph";
import {
  Breadcrumb,
  PageBreadcrumbs,
  PageHeader,
  PageTabStrip,
} from "~/layouts/BaseLayout";
import {
  objectOrRedirect,
  relativeObjectPath,
  SchemaObjectRouteParams,
} from "~/platform/schemaObjectRouteHelpers";
import { SentryRoutes } from "~/sentry";
import { usePageHeadingInView } from "~/store/stickyHeader";
import { assert } from "~/util";

import { useSourcesList } from "./queries";
import SourceErrors from "./SourceErrors";
import { SourceOverview } from "./SourceOverview";
import Subsources from "./Subsources";

const SourceOrRedirect = () => {
  const params = useParams<SchemaObjectRouteParams>();

  const { data } = useSourcesList();
  const result = objectOrRedirect({
    params,
    objects: data.rows,
    loading: false,
    relativePathFn: relativeObjectPath,
  });

  if (result.type === "loading") {
    return null;
  }
  if (result.type === "redirect") {
    return result.redirect;
  }
  return <SourceDetailRoutes source={result.object} />;
};

export const SourceDetailRoutes = (props: { source: Source }) => {
  const params = useParams<SchemaObjectRouteParams>();

  return (
    <SentryRoutes>
      <Route path="/" element={<SourceOverview source={props.source} />} />
      <Route
        path="errors"
        element={
          <React.Suspense fallback={<LoadingContainer />}>
            <SourceErrors source={props.source} />
          </React.Suspense>
        }
      />
      <Route path="subsources" element={<Subsources sourceId={params.id} />} />
      <Route
        path="workflow"
        element={<WorkflowGraph focusedObjectId={params.id} />}
      />
    </SentryRoutes>
  );
};

const SourceDetailBreadcrumbs = (props: { crumbs: Breadcrumb[] }) => {
  const params = useParams<SchemaObjectRouteParams>();
  // The route requires an ID
  assert(params.id);
  const { data } = useSourcesList();
  const showStatus = !usePageHeadingInView();
  const source = data.rows.find((r) => r.id === params.id);

  if (!source) {
    return null;
  }

  return (
    <PageBreadcrumbs
      crumbs={props.crumbs}
      contextMenuChildren={
        <>
          <ShowCreateModal type="SOURCE" schemaObject={source} />
          <DeleteObjectMenuItem
            selectedObject={source}
            key="delete-object"
            onSuccessAction={() => redirect("..")}
            objectType="SOURCE"
          />
        </>
      }
    >
      <Flex height="100%" alignItems="center">
        {showStatus && (
          <AnimatePresence>
            <Box
              as={motion.div}
              initial={{ opacity: 0, y: 8 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 8 }}
            >
              <ConnectorStatusPill ml={2} connector={source} />
            </Box>
          </AnimatePresence>
        )}
      </Flex>
    </PageBreadcrumbs>
  );
};

const SourceDetail = () => {
  const params = useParams<SchemaObjectRouteParams>();

  const breadcrumbs: Breadcrumb[] = React.useMemo(
    () => [
      { title: "Sources", href: ".." },
      { title: params.objectName ?? "" },
    ],
    [params.objectName],
  );

  return (
    <>
      <PageHeader variant="compact" sticky>
        <React.Suspense fallback={<PageBreadcrumbs crumbs={breadcrumbs} />}>
          <SourceDetailBreadcrumbs crumbs={breadcrumbs} />
        </React.Suspense>
        <PageTabStrip
          tabData={[
            { href: "", label: "Overview", end: true },
            { href: `errors`, label: "Errors" },
            { href: `subsources`, label: "Subsources" },
            { href: `workflow`, label: "Workflow" },
          ]}
        />
      </PageHeader>
      <React.Suspense fallback={<LoadingContainer />}>
        <SourceOrRedirect />
      </React.Suspense>
    </>
  );
};

export default SourceDetail;
