import { MenuItem, VStack } from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { Link, Route, useLocation, useParams } from "react-router-dom";

import { isSystemCluster } from "~/api/materialize";
import { ClusterWithOwnership } from "~/api/materialize/cluster/clusterList";
import DeleteObjectMenuItem from "~/components/DeleteObjectMenuItem";
import OverflowMenu from "~/components/OverflowMenu";
import {
  Breadcrumb,
  PageBreadcrumbs,
  PageHeader,
  PageTabStrip,
} from "~/layouts/BaseLayout";
import {
  ClusterDetailParams,
  ClusterParams,
} from "~/platform/clusters/ClusterRoutes";
import { SentryRoutes } from "~/sentry";
import { useClusterBreadcrumbs, useClusterSubnavItems } from "~/store/clusters";
import { assert } from "~/util";

import { replaceClusterIdAndName } from "../routeHelpers";
import AlterClusterMenuItem from "./AlterClusterMenuItem";
import ClusterOverview from "./ClusterOverview";
import ClusterReplicas from "./ClusterReplicas";
import IndexRoutes from "./IndexRoutes";
import MaterializedViewRoutes from "./MaterializedViewRoutes";
import { useClusters } from "./queries";
import Sinks from "./Sinks";
import Sources from "./Sources";
import { useShowSystemObjects } from "./useShowSystemObjects";

const ClusterDetailBreadcrumbs = (props: { crumbs: Breadcrumb[] }) => {
  const flags = useFlags();
  const [showSystemObjects] = useShowSystemObjects();
  const { clusterId, clusterName } = useParams<ClusterParams>();
  const { data: clusters, getClusterById } = useClusters();
  const { pathname, search } = useLocation();
  assert(clusterId);
  assert(clusterName);
  const cluster = getClusterById(clusterId);
  const objectExplorerEnabled = flags["object-explorer-1820"];

  const clustersToShow = showSystemObjects
    ? clusters
    : clusters.filter((c) => c.id.startsWith("u"));

  let menu = null;
  if (objectExplorerEnabled) {
    menu = (
      <>
        {clustersToShow.map((c) => (
          <MenuItem
            as={Link}
            disabled={c.name === clusterName}
            to={
              replaceClusterIdAndName({
                pathname,
                currentClusterId: clusterId,
                currentClusterName: clusterName,
                targetCluster: c,
              }) + search
            }
            key={c.id}
          >
            {c.name}
          </MenuItem>
        ))}
      </>
    );
  } else if (cluster && !isSystemCluster(cluster.id) && cluster.isOwner) {
    menu = (
      <>
        {cluster.managed && <AlterClusterMenuItem cluster={cluster} />}
        <DeleteObjectMenuItem
          key="delete-object"
          selectedObject={cluster}
          // subscribe will update our list and the cluster routes will redirect
          onSuccessAction={() => undefined}
          objectType="CLUSTER"
        />
      </>
    );
  }

  return (
    <PageBreadcrumbs
      crumbs={props.crumbs}
      contextMenuChildren={menu}
      rightSideChildren={
        objectExplorerEnabled &&
        cluster && <OverflowMenuContainer cluster={cluster} />
      }
    />
  );
};

const OverflowMenuContainer = ({
  cluster,
}: {
  cluster: ClusterWithOwnership;
}) => {
  return (
    <OverflowMenu
      items={[
        {
          visible: !isSystemCluster(cluster.id) && cluster.managed,
          render: () => <AlterClusterMenuItem cluster={cluster} />,
        },
        {
          visible: !isSystemCluster(cluster.id) && cluster?.isOwner,
          render: () =>
            cluster && (
              <DeleteObjectMenuItem
                key="delete-object"
                selectedObject={cluster}
                // subscribe will update our list and the cluster routes will redirect
                onSuccessAction={() => undefined}
                objectType="CLUSTER"
              />
            ),
        },
      ]}
    />
  );
};

const ClusterDetailPage = () => {
  const { clusterName } = useParams<ClusterDetailParams>();
  const additionalBreadcrumbs = useClusterBreadcrumbs();
  const subnavItems = useClusterSubnavItems();

  const breadcrumbs: Breadcrumb[] = React.useMemo(
    () => [
      { title: "Clusters", href: ".." },
      { title: clusterName ?? "", href: "." },
      ...additionalBreadcrumbs,
    ],
    [clusterName, additionalBreadcrumbs],
  );

  // Setting key on the route elements prevents any weird jank when you use the context
  // menu to switch between clusters.
  return (
    <>
      <PageHeader variant="compact" boxProps={{ mb: 0 }} sticky>
        <VStack spacing={0} alignItems="flex-start" width="100%">
          <ClusterDetailBreadcrumbs crumbs={breadcrumbs} />
          <PageTabStrip tabData={subnavItems} />
        </VStack>
      </PageHeader>
      <SentryRoutes>
        <Route path="/" element={<ClusterOverview key={clusterName} />} />
        <Route
          path="replicas"
          element={<ClusterReplicas key={clusterName} />}
        />
        <Route
          path="materialized-views/*"
          element={<MaterializedViewRoutes key={clusterName} />}
        />
        <Route path="indexes/*" element={<IndexRoutes key={clusterName} />} />
        <Route path="sources" element={<Sources key={clusterName} />} />
        <Route path="sinks" element={<Sinks key={clusterName} />} />
      </SentryRoutes>
    </>
  );
};

export default ClusterDetailPage;
