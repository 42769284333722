import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { Navigate, Route, useParams } from "react-router-dom";

import { useSchemaObjectFilters } from "~/components/SchemaObjectFilter/useSchemaObjectFilters";
import WorkflowGraph from "~/components/WorkflowGraph/WorkflowGraph";
import {
  legacyRelativeDataflowVisualizerPath,
  legacyRelativeIndexPath,
} from "~/platform/routeHelpers";
import {
  objectOrRedirect,
  SchemaObjectRouteParams,
} from "~/platform/schemaObjectRouteHelpers";
import { SentryRoutes } from "~/sentry";
import {
  clusterDetailsSubnav,
  useSetClusterBreadcrumbs,
  useSetClusterSubnavItems,
} from "~/store/clusters";

import { ClusterParams } from "./ClusterRoutes";
import IndexList from "./IndexList";
import { useIndexesList } from "./queries";

const DataflowVisualizer = React.lazy(() => import("./DataflowVisualizer"));

const IndexOrRedirect = () => {
  const params = useParams<ClusterParams & SchemaObjectRouteParams>();
  const { data: indexes } = useIndexesList({
    clusterId: params.clusterId,
    includeSystemObjects: true,
  });
  const result = objectOrRedirect({
    params,
    objects: indexes?.rows,
    loading: false,
    relativePathFn: legacyRelativeDataflowVisualizerPath,
  });
  const setClusterBreadcrumbs = useSetClusterBreadcrumbs();
  const setClusterSubnav = useSetClusterSubnavItems();
  const resultObject = result.type === "object" ? result.object : undefined;
  const flags = useFlags();
  const dataflowVisualizerEnabled = flags["visualization-features"];

  React.useEffect(() => {
    if (resultObject) {
      setClusterBreadcrumbs([
        // Because these NavLinks are rendered in the cluster page, which is outside this
        // nested route, it has to include the index path
        { title: "Indexes", href: "indexes" },
        { title: resultObject.name },
      ]);
    }
    return () => {
      setClusterBreadcrumbs([]);
    };
  }, [resultObject, setClusterBreadcrumbs]);

  React.useEffect(() => {
    if (resultObject) {
      const subNavItems = [];
      subNavItems.push({
        label: "Workflow",
        // Because these NavLinks are rendered in the cluster page, outside this nested
        // route, it has to include the materialized-view path
        href: `indexes/${legacyRelativeIndexPath(resultObject)}`,
      });
      if (dataflowVisualizerEnabled) {
        subNavItems.push({
          label: "Dataflow Visualizer",
          // Because these NavLinks are rendered in the cluster page, which is outside this
          // nested route, it has to include the index path
          href: `indexes/${legacyRelativeDataflowVisualizerPath(resultObject)}`,
        });
      }
      setClusterSubnav(subNavItems);
    }
    return () => {
      setClusterSubnav(clusterDetailsSubnav);
    };
  }, [resultObject, result.type, setClusterSubnav, dataflowVisualizerEnabled]);

  if (result.type === "redirect") {
    return result.redirect;
  } else {
    return (
      <SentryRoutes>
        <Route path="/" element={<Navigate to="workflow" replace />} />
        <Route
          path="workflow"
          element={
            <WorkflowGraph
              focusedObjectId={
                result.type === "object" ? result.object.id : undefined
              }
            />
          }
        />
        <Route
          path="/"
          element={<Navigate to="dataflow-visualizer" replace />}
        />
        <Route path="dataflow-visualizer" element={<DataflowVisualizer />} />
      </SentryRoutes>
    );
  }
};

const IndexRoutes = () => {
  const { databaseFilter, schemaFilter, nameFilter } =
    useSchemaObjectFilters("indexName");

  return (
    <SentryRoutes>
      <Route
        path="/*"
        element={
          <IndexList
            databaseFilter={databaseFilter}
            schemaFilter={schemaFilter}
            nameFilter={nameFilter}
          />
        }
      />
      <Route
        path=":id/:databaseName/:schemaName/:objectName/*"
        element={<IndexOrRedirect />}
      />
    </SentryRoutes>
  );
};

export default IndexRoutes;
