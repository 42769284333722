import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { loginPath } from "~/platform/routeHelpers";
import { useOidcLogin } from "~/queries/frontegg";

export const OidcCallback = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const loginCalled = React.useRef(false);
  const { mutate: oidcLogin } = useOidcLogin();

  React.useEffect(() => {
    if (loginCalled.current) return;

    const code = params.get("code");
    const state = params.get("state");

    if (!code || !state) {
      navigate(loginPath);
      return;
    }
    oidcLogin(
      { code, state },
      {
        onError: () => {
          navigate(loginPath, { replace: true });
        },
        onSuccess: () => {
          navigate("/", { replace: true });
        },
      },
    );
    loginCalled.current = true;
  }, [navigate, oidcLogin, params]);

  return null;
};
