import { localPoint } from "@visx/event";
import { Point } from "framer-motion";
import { PointerEvent, useCallback, useContext, useState } from "react";

import { EventEmitterContext } from "~/components/EventEmitterContext";

// This file was copied from the visx XYchart:
// https://github.com/airbnb/visx/blob/dee0ac097cc3f53f4d81d1d99b736bf0e3c7ac1c/packages/visx-xychart/src/hooks/useEventEmitters.ts

interface RectReadOnly {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
  readonly top: number;
  readonly right: number;
  readonly bottom: number;
  readonly left: number;
}

type PointerEventEmitterParams = {
  /** Source of the events, e.g., the component name. */
  source: string;
  onBlur?: boolean;
  onFocus?: boolean;
  onPointerMove?: boolean;
  onPointerOut?: boolean;
  onPointerUp?: boolean;
  onPointerDown?: boolean;
};

/**
 * Calculates scroll offsets, accounting for scrolling containers, not just page scrolling.
 */
export const getOffset = (
  event: PointerEvent,
  svgPoint: Point,
  containerBounds: RectReadOnly,
) => {
  return {
    offsetY: event.clientY - containerBounds.top - svgPoint.y,
    offsetX: event.clientX - containerBounds.left - svgPoint.x,
  };
};

/**
 * A hook that simplifies creation of handlers for emitting
 * pointermove, pointerout, and pointerup events to EventEmitterContext.
 */
export function usePointerEventEmitters({
  source,
  onPointerOut = true,
  onPointerMove = true,
}: PointerEventEmitterParams) {
  const emitter = useContext(EventEmitterContext);

  const emitPointerMove = useCallback(
    (event: PointerEvent, containerBounds: RectReadOnly) => {
      const svgPoint = localPoint(event);
      if (!svgPoint) return;

      emitter?.emit("pointermove", {
        event,
        svgPoint,
        ...getOffset(event, svgPoint, containerBounds),
        source,
      });
    },
    [emitter, source],
  );
  const emitPointerOut = useCallback(
    (event: PointerEvent, containerBounds: RectReadOnly) => {
      const svgPoint = localPoint(event);
      if (!svgPoint) return;

      emitter?.emit("pointerout", {
        event,
        svgPoint,
        ...getOffset(event, svgPoint, containerBounds),
        source,
      });
    },
    [emitter, source],
  );

  return {
    onPointerMove: onPointerMove ? emitPointerMove : undefined,
    onPointerOut: onPointerOut ? emitPointerOut : undefined,
  };
}

export function useCursorState() {
  const [cursor, setCursor] = useState<Point | null>(null);

  const handleCursorMove = useCallback((event: PointerEvent) => {
    const svgPoint = localPoint(event);
    if (!svgPoint) return;

    setCursor({ x: svgPoint.x, y: svgPoint.y });
  }, []);

  const hideCursor = useCallback(() => {
    setCursor(null);
  }, []);

  return { handleCursorMove, hideCursor, cursor };
}
