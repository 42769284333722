import { sql } from "kysely";

import {
  buildFullyQualifiedObjectName,
  escapedIdentifier as id,
  SchemaObject,
} from "~/api/materialize";
import { Cluster } from "~/api/materialize/cluster/useConnectorClusters";
import { assert } from "~/util";

export interface CreateSourceParameters {
  name: string;
  connection: SchemaObject;
  databaseName: string;
  schemaName: string;
  cluster: Cluster;
  allTables: boolean;
  tables: {
    schemaName: string;
    name: string;
    alias: string;
  }[];
}

const createMySqlSourceStatement = (params: CreateSourceParameters) => {
  if (params.cluster.id === "0") {
    throw new Error("You must specify cluster");
  }

  assert(params.connection?.name);
  assert(params.cluster?.name);
  const query = sql`
CREATE SOURCE ${buildFullyQualifiedObjectName(params)}
IN CLUSTER ${id(params.cluster.name)}
FROM MYSQL CONNECTION ${buildFullyQualifiedObjectName(params.connection)}
${
  params.allTables
    ? sql`FOR ALL TABLES`
    : sql`FOR TABLES (${sql.join(
        params.tables.map(
          (t) =>
            sql`${id(t.schemaName)}.${id(t.name)}${t.alias ? sql` AS ${id(t.alias)}` : sql``}`,
        ),
      )})`
};`;

  return query;
};

export default createMySqlSourceStatement;
