import { sql } from "kysely";
import React from "react";

import { escapedIdentifier as id } from "~/api/materialize";
import {
  buildSubscribeQuery,
  useRawSubscribe,
} from "~/api/materialize/useSubscribe";

function useWebhookSourceEvents({
  databaseName,
  schemaName,
  sourceName,
  clusterName,
  headerColumns,
}: {
  databaseName: string;
  schemaName: string;
  sourceName: string;
  clusterName: string;
  headerColumns: string[];
}) {
  const subscribe = React.useMemo(() => {
    const selectCols = [id("body"), ...headerColumns.map((c) => id(c))];
    const selectClause = sql.join(selectCols, sql`, `);
    return buildSubscribeQuery(
      sql<{
        body: object;
        headers: object;
      }>`SELECT ${selectClause} FROM ${id(databaseName)}.${id(schemaName)}.${id(sourceName)}`,
      { upsertKey: "body" },
    );
  }, [databaseName, headerColumns, schemaName, sourceName]);
  const results = useRawSubscribe({ subscribe, clusterName });
  return results;
}

export default useWebhookSourceEvents;
