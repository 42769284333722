import { Button, Text, useTheme, VStack } from "@chakra-ui/react";
import React from "react";

import { useCurrentOrganization } from "~/api/auth";
import * as chilipiper from "~/marketing/chilipiper";
import { useCurrentUser } from "~/queries/frontegg";
import { MaterializeTheme } from "~/theme";

const BlockedState = () => {
  const { colors } = useTheme<MaterializeTheme>();
  const { data: user } = useCurrentUser();
  const { organization } = useCurrentOrganization();
  const isTrialCustomer = organization?.subscription?.type === "evaluation";
  return (
    <VStack flex={1} h="full" w="full" data-testid="account-status-alert">
      <VStack
        spacing={6}
        m={6}
        h="full"
        w="full"
        alignItems="center"
        justifyContent="center"
      >
        <VStack spacing={4} align="flex-start" maxWidth="500px">
          <Text as="h1" textStyle="heading-lg">
            {isTrialCustomer
              ? "Your trial has ended"
              : "Your Materialize plan has lapsed"}
          </Text>
          <Text
            as="h4"
            textStyle="text-base"
            fontSize="16px"
            color={colors.foreground.secondary}
          >
            {isTrialCustomer
              ? "But, we can help get you back to operating in real-time in no time!"
              : "Your organization's access is currently restricted. Please contact us to reactivate your account and restore access to your environment."}
          </Text>
          <Button
            size="lg"
            variant="primary"
            alignSelf="flex-end"
            onClick={() => {
              if (user) {
                const meetingType =
                  // Customers are routed to a different set of people based on plan type.
                  //   * Trial customers must go to the special `guided-trial` meeting group.
                  //   * Everyone else should go to the default meeting group.
                  isTrialCustomer ? "guided-trial" : undefined;
                chilipiper.submit({
                  name: user.name,
                  email: user.email,
                  meetingType,
                  organizationId: user.tenantId,
                });
              }
            }}
          >
            Talk with our team
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default BlockedState;
