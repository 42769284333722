import { useQuery } from "@tanstack/react-query";

import {
  buildQueryKeyPart,
  buildRegionQueryKey,
} from "~/api/buildQueryKeySchema";
import {
  ClusterReplicaDetails,
  fetchClusterReplicaDetails,
} from "~/api/materialize/environment-overview/clusterReplicaDetails";

const environmentOverviewQueryKeys = {
  all: () => buildRegionQueryKey("environmentOverview"),
  clusterReplicaDetails: () =>
    [
      ...environmentOverviewQueryKeys.all(),
      buildQueryKeyPart("clusterReplicaDetails"),
    ] as const,
};

export function clusterReplicaDetailsSelector(
  data: Awaited<ReturnType<typeof fetchClusterReplicaDetails>>,
) {
  return data.rows.reduce((accum, row) => {
    accum.set(row.id, row);
    return accum;
  }, new Map<string, ClusterReplicaDetails>());
}

export function useClusterReplicaDetails() {
  return useQuery({
    refetchInterval: 5000,
    queryKey: environmentOverviewQueryKeys.clusterReplicaDetails(),
    queryFn: ({ queryKey, signal }) => {
      return fetchClusterReplicaDetails({
        queryKey,
        requestOptions: { signal },
      });
    },
    select: clusterReplicaDetailsSelector,
  });
}
