import {
  Button,
  Circle,
  HStack,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import React from "react";

import { useSegment } from "~/analytics/segment";
import { createNamespace } from "~/api/materialize";
import { ListPageSecret } from "~/api/materialize/secret/fetchSecretsList";
import useCanCreateObjects from "~/api/materialize/useCanCreateObjects";
import DeleteObjectMenuItem from "~/components/DeleteObjectMenuItem";
import ErrorBox from "~/components/ErrorBox";
import OverflowMenu, { OVERFLOW_BUTTON_WIDTH } from "~/components/OverflowMenu";
import DatabaseFilter from "~/components/SchemaObjectFilter/DatabaseFilter";
import SchemaFilter from "~/components/SchemaObjectFilter/SchemaFilter";
import { useSchemaObjectFilters } from "~/components/SchemaObjectFilter/useSchemaObjectFilters";
import SearchInput from "~/components/SearchInput";
import TextLink from "~/components/TextLink";
import { LockIcon } from "~/icons";
import {
  MainContentContainer,
  PageHeader,
  PageHeading,
} from "~/layouts/BaseLayout";
import {
  EmptyListHeader,
  EmptyListHeaderContents,
  EmptyListWrapper,
} from "~/layouts/listPageComponents";
import { useSecretsListPage } from "~/platform/secrets/queries";
import { MaterializeTheme } from "~/theme";
import { truncateMaxWidth } from "~/theme/components/Table";
import { formatDate, FRIENDLY_DATE_FORMAT } from "~/utils/dateFormat";

import NewSecretModal from "./NewSecretModal";

const NAME_FILTER_QUERY_STRING_KEY = "secretName";

import docUrls from "~/mz-doc-urls.json";

const EmptyState = () => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <EmptyListWrapper>
      <EmptyListHeader>
        <Circle p={2} bg={colors.background.secondary}>
          <LockIcon />
        </Circle>
        <EmptyListHeaderContents
          title="No available secrets"
          helpText="Create a new secret to store sensitive information in Materialize."
        />
        <Text
          fontSize="xs"
          textAlign="center"
          color={colors.foreground.secondary}
        >
          Need help?{" "}
          <TextLink href={docUrls["/docs/sql/create-secret/"]} target="_blank">
            View the documentation.
          </TextLink>
        </Text>
      </EmptyListHeader>
    </EmptyListWrapper>
  );
};

export const SecretsList = () => {
  const { track } = useSegment();

  const { databaseFilter, schemaFilter, nameFilter } = useSchemaObjectFilters(
    NAME_FILTER_QUERY_STRING_KEY,
  );
  const { data, isLoading, refetch, isError } = useSecretsListPage({
    databaseId: databaseFilter.selected?.id,
    schemaId: schemaFilter.selected?.id,
    nameFilter: nameFilter.name,
  });
  const { results: canCreate } = useCanCreateObjects();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSecretCreation = () => {
    onClose();
  };

  const secrets = data?.rows;

  const isEmpty = secrets && secrets.length === 0;

  return (
    <MainContentContainer>
      <PageHeader>
        <PageHeading>Secrets</PageHeading>
        <HStack spacing="4">
          <DatabaseFilter {...databaseFilter} />
          <SchemaFilter {...schemaFilter} />
          <SearchInput
            name="secrets"
            value={nameFilter.name}
            onChange={(e) => {
              nameFilter.setName(e.target.value);
            }}
          />
          {canCreate && (
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                onOpen();
                track("New Secret Clicked");
              }}
            >
              New secret
            </Button>
          )}
        </HStack>
      </PageHeader>
      {isError ? (
        <ErrorBox message="An error occurred loading secrets" />
      ) : isLoading ? (
        <Spinner data-testid="loading-spinner" />
      ) : isEmpty ? (
        <EmptyState />
      ) : (
        <SecretsTable refetchSecrets={refetch} secrets={secrets ?? []} />
      )}
      <NewSecretModal
        isOpen={isOpen}
        onClose={onClose}
        onPrimaryButtonAction={handleSecretCreation}
      />
    </MainContentContainer>
  );
};

export interface SecretsTableProps {
  secrets: ListPageSecret[];
  refetchSecrets: () => void;
}

const SecretsTable = ({ secrets, refetchSecrets }: SecretsTableProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <Table variant="standalone">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Created</Th>
          <Th width={OVERFLOW_BUTTON_WIDTH}></Th>
        </Tr>
      </Thead>
      <Tbody>
        {secrets.map((secret) => {
          return (
            <Tr key={secret.id} textColor="default" aria-label={secret.name}>
              <Td {...truncateMaxWidth} py="2">
                <Text
                  textStyle="text-small"
                  fontWeight="500"
                  noOfLines={1}
                  color={colors.foreground.secondary}
                >
                  {createNamespace(secret.databaseName, secret.schemaName)}
                </Text>
                <Text textStyle="text-ui-med" noOfLines={1}>
                  {secret.name}
                </Text>
              </Td>
              <Td width="25%">
                <Text>
                  {formatDate(secret.createdAt, FRIENDLY_DATE_FORMAT)}
                </Text>
              </Td>
              <Td>
                <OverflowMenu
                  items={[
                    {
                      visible: secret.isOwner,
                      render: () => (
                        <DeleteObjectMenuItem
                          key="delete-object"
                          selectedObject={secret}
                          onSuccessAction={refetchSecrets}
                          objectType="SECRET"
                        />
                      ),
                    },
                  ]}
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default SecretsList;
