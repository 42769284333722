import {
  Alert as ChakraAlert,
  AlertDescription,
  BoxProps,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import DeleteAppPasswordModal from "~/access/DeleteAppPasswordModal";
import { useCanAccessTenantApiTokens } from "~/api/auth";
import { ApiToken, Role } from "~/api/frontegg/types";
import Alert from "~/components/Alert";
import ConnectModal from "~/components/ConnectModal";
import { SecretCopyableBox } from "~/components/copyableComponents";
import { LoadingContainer } from "~/components/LoadingContainer";
import { Modal } from "~/components/Modal";
import SearchableSelect from "~/components/SearchableSelect/SearchableSelect";
import {
  MainContentContainer,
  PageHeader,
  PageHeading,
} from "~/layouts/BaseLayout";
import {
  useCreateApiToken,
  useCurrentUser,
  useListApiTokens,
  useRoles,
} from "~/queries/frontegg";
import ConnectionIcon from "~/svg/ConnectionIcon";
import { MaterializeTheme } from "~/theme";
import {
  formatDate,
  FRIENDLY_DATETIME_FORMAT_NO_SECONDS,
} from "~/utils/dateFormat";

const AppPasswordsPage = () => {
  const flags = useFlags();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  React.useEffect(() => {
    if (location.state && "new" in location.state && location.state.new) {
      onOpen();
    }
  }, [location.pathname, location.state, onOpen]);

  return (
    <MainContentContainer>
      <PageHeader>
        <PageHeading>App Passwords</PageHeading>
        {!flags["object-explorer-1820"] && (
          <Button variant="primary" size="sm" onClick={onOpen}>
            New app password
          </Button>
        )}
      </PageHeader>
      <React.Suspense fallback={<LoadingContainer />}>
        <AppPasswordsInner isNewModalOpen={isOpen} closeNewModal={onClose} />
      </React.Suspense>
    </MainContentContainer>
  );
};
const AppPasswordsInner = (props: {
  isNewModalOpen: boolean;
  closeNewModal: () => void;
}) => {
  const { data: user } = useCurrentUser();
  const canAccessTenantApiTokens = useCanAccessTenantApiTokens();
  const { data: roles } = useRoles({ enabled: canAccessTenantApiTokens });

  const {
    mutate: createAppPassword,
    isPending: createInProgress,
    data: newPassword,
    error,
  } = useCreateApiToken();

  const { data: appPasswords } = useListApiTokens();

  const {
    register,
    handleSubmit,
    formState,
    reset,
    getValues,
    watch,
    control,
  } = useForm<{
    type: "personal" | "service";
    user: string;
    name: string;
    roles: { name: string; id: string }[];
  }>({
    mode: "onChange",
    defaultValues: {
      type: "personal",
      name: "",
      user: "",
      roles: [],
    },
  });

  const [newPasswordClosed, setNewPasswordClosed] = useState("");

  const watchType = watch("type");

  const isSecretBoxOpen =
    newPassword &&
    newPasswordClosed !== newPassword.clientId &&
    appPasswords.map((p) => p.clientId).includes(newPassword.clientId);

  return (
    <VStack alignItems="stretch">
      {error && <Alert variant="error" message={error.message} mb="10" />}
      {isSecretBoxOpen && (
        <SecretBox
          name={newPassword.description}
          password={newPassword.password}
          obfuscatedContent={newPassword.obfuscatedPassword}
          onClose={() => setNewPasswordClosed(newPassword.clientId)}
        />
      )}
      <Text fontSize="sm" mb="2">
        App passwords allow applications and services to connect to Materialize.
      </Text>
      <ApiTokensTableProps tokens={appPasswords} roles={roles} />
      <Modal
        isOpen={props.isNewModalOpen}
        onClose={props.closeNewModal}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <form
            onSubmit={handleSubmit((data) => {
              createAppPassword({
                type: data.type,
                description: data.name,
                user: data.user,
                roleIds: data.roles.map((r) => r.id),
              });
              reset();
              props.closeNewModal();
            })}
          >
            <ModalHeader>New app password</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack pb={6} spacing="4">
                <FormControl>
                  <FormLabel htmlFor="name" fontSize="sm">
                    Type
                  </FormLabel>
                  <RadioGroup defaultValue={getValues("type")}>
                    <Stack spacing="-1">
                      <Radio {...register("type")} value="personal" size="sm">
                        Personal
                      </Radio>
                      <Radio
                        {...register("type")}
                        isDisabled={!canAccessTenantApiTokens}
                        value="service"
                        size="sm"
                      >
                        <Tooltip
                          label="Only Organization Admins can create service app passwords."
                          isDisabled={canAccessTenantApiTokens}
                          hasArrow
                          placement="right"
                          lineHeight="1.15"
                        >
                          Service
                        </Tooltip>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <FormHelperText>
                    {watchType == "personal"
                      ? `Personal app passwords are associated with your user account (${user.email}).`
                      : "Service app passwords are associated with a user account of your choosing."}
                  </FormHelperText>
                </FormControl>
                <FormControl isInvalid={!!formState.errors.name}>
                  <FormLabel htmlFor="description" fontSize="sm">
                    Name
                  </FormLabel>
                  <Input
                    {...register("name", {
                      required: "Name is required",
                    })}
                    aria-label="Name"
                    placeholder="e.g. Personal laptop"
                    autoFocus={props.isNewModalOpen}
                    autoCorrect="off"
                    autoComplete="off"
                    size="sm"
                  />
                  <FormErrorMessage>
                    {formState.errors.name?.message}
                  </FormErrorMessage>
                  <FormHelperText>
                    Describe what you&apos;ll use the app password for, in case
                    you need to revoke it in the future.
                  </FormHelperText>
                </FormControl>
                {watchType == "service" && (
                  <>
                    <FormControl isInvalid={!!formState.errors.user}>
                      <FormLabel htmlFor="user" fontSize="sm">
                        User
                      </FormLabel>
                      <Input
                        {...register("user", {
                          validate: (value, formValues) => {
                            if (formValues.type === "service" && value === "") {
                              return "User is required.";
                            }
                            if (value.indexOf("@") !== -1) {
                              return "User cannot contain @ symbol.";
                            }
                            for (const prefix of ["mz_", "pg_", "external_"]) {
                              if (value.startsWith(prefix)) {
                                return `User cannot start with "${prefix}".`;
                              }
                            }
                          },
                        })}
                        autoCorrect="off"
                        autoComplete="off"
                        size="sm"
                      />
                      <FormHelperText>
                        If the specified user does not already exist, it will be
                        automatically created the first time the app password is
                        used.
                      </FormHelperText>
                      <FormErrorMessage>
                        {formState.errors.user?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!formState.errors.roles}>
                      <FormLabel htmlFor="roles" fontSize="sm">
                        Roles
                      </FormLabel>
                      <Controller
                        name="roles"
                        control={control}
                        rules={{
                          validate: (value, formValues) => {
                            if (
                              formValues.type === "service" &&
                              value.length === 0
                            ) {
                              return "At least one role is required.";
                            }
                          },
                        }}
                        render={({ field: { onChange, onBlur, ref } }) => (
                          <SearchableSelect
                            ariaLabel="Roles"
                            onBlur={onBlur}
                            onChange={onChange}
                            selectRef={ref}
                            options={roles?.map((r) => ({
                              name: r.name,
                              id: r.id,
                            }))}
                            isMulti={true}
                          />
                        )}
                      ></Controller>
                      <FormHelperText>
                        The roles to associate with the app password.
                      </FormHelperText>
                      <FormErrorMessage>
                        {formState.errors.roles?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}
              </VStack>
            </ModalBody>

            <ModalFooter>
              <HStack spacing="2">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={props.closeNewModal}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="sm"
                  isDisabled={!!createInProgress}
                >
                  Create Password
                </Button>
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

type ApiTokensTableProps = BoxProps & {
  tokens: ApiToken[];
  roles: Role[];
};

const ApiTokensTableProps = ({
  tokens,
  roles,
  ...props
}: ApiTokensTableProps) => {
  const { data: currentUser } = useCurrentUser();
  const { colors } = useTheme<MaterializeTheme>();

  const rolesByKey = new Map(roles.map((r) => [r.key, r.name]));
  const rolesById = new Map(roles.map((r) => [r.id, r.name]));

  return (
    <Table variant="standalone" {...props}>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Type</Th>
          <Th>User</Th>
          <Th>Roles</Th>
          <Th>Created at</Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {tokens.map((token) => {
          const user =
            token.type === "personal" ? currentUser.email : token.user;

          let tokenRoles;
          if (token.type === "personal") {
            tokenRoles = currentUser.roles.map(
              (r) => rolesByKey.get(r) || "Unknown",
            );
          } else {
            tokenRoles = token.roleIds.map(
              (r) => rolesById.get(r) || "Unknown",
            );
          }

          return (
            <Tr
              key={token.clientId}
              textColor="default"
              aria-label={token.description}
            >
              <Td
                borderBottomWidth="1px"
                borderBottomColor={colors.border.primary}
              >
                {token.description}
              </Td>
              <Td
                borderBottomWidth="1px"
                borderBottomColor={colors.border.primary}
              >
                {token.type === "personal" ? "Personal" : "Service"}
              </Td>
              <Td
                borderBottomWidth="1px"
                borderBottomColor={colors.border.primary}
              >
                {token.type === "personal" ? (
                  <Text color={colors.gray["500"]}>{user}</Text>
                ) : (
                  user
                )}
              </Td>
              <Td
                borderBottomWidth="1px"
                borderBottomColor={colors.border.primary}
              >
                {token.type === "personal" ? (
                  <Text color={colors.gray["500"]}>
                    {tokenRoles.join(", ")}
                  </Text>
                ) : (
                  tokenRoles.join(", ")
                )}
              </Td>
              <Td
                borderBottomWidth="1px"
                borderBottomColor={colors.border.primary}
              >
                {" "}
                {formatDate(
                  new Date(token.createdAt),
                  FRIENDLY_DATETIME_FORMAT_NO_SECONDS,
                )}
              </Td>
              <Td
                borderBottomWidth="1px"
                borderBottomColor={colors.border.primary}
              >
                <HStack>
                  <ConnectAppPasswordModal user={user} />
                  <DeleteAppPasswordModal token={token} />
                </HStack>
              </Td>
            </Tr>
          );
        })}
        {tokens.length === 0 && (
          <Tr>
            <Td colSpan={6}>No app passwords yet.</Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};

type SecretBoxProps = {
  name: string;
  password: string;
  obfuscatedContent: string;
  onClose: () => void;
};

const SecretBox = ({
  name,
  password,
  obfuscatedContent,
  onClose,
}: SecretBoxProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <ChakraAlert
      status="info"
      mb={2}
      size="sm"
      background={colors.background.info}
      borderRadius="md"
      borderWidth="1px"
      borderColor={colors.border.info}
    >
      <VStack alignItems="flex-start" width="100%">
        <AlertDescription width="100%" px={2}>
          <VStack alignItems="start">
            <Text fontSize="md" fontWeight="500">
              New password {`"${name}"`}:
            </Text>
            <SecretCopyableBox
              label="clientId"
              contents={password}
              obfuscatedContent={obfuscatedContent}
            />
          </VStack>
          <Text pt={1} textStyle="text-base" color={colors.foreground.primary}>
            Write this down; you will not be able to see your app password again
            after you reload!
          </Text>
        </AlertDescription>
      </VStack>
      <CloseButton
        position="absolute"
        right={1}
        top={1}
        size="sm"
        color={colors.foreground.secondary}
        onClick={onClose}
      />
    </ChakraAlert>
  );
};

const ConnectAppPasswordModal = ({ user }: { user: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        title="Connect to Materialize"
        size="sm"
        colorScheme="primary"
        variant="outline"
        leftIcon={<ConnectionIcon />}
      >
        Connect
      </Button>
      <ConnectModal
        onClose={onClose}
        isOpen={isOpen}
        forAppPassword={{ user }}
      />
    </>
  );
};

export default AppPasswordsPage;
