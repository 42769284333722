import config from "~/config";

import { getFronteggToken } from "../fronteggToken";
import { APPLICATION_NAME, buildSessionVariables } from ".";
import type { Column, Error, Notice } from "./types";
import { SqlRequest } from "./types";

export type AuthOptions = Record<string, string | undefined>;

export type Callback = () => void;
export type MessageCallback = (message: WebSocketResult) => void;
export type CloseCallback = (event: CloseEvent) => void;
export type OpenCallback = (event: Event) => void;

export interface MaterializeWebsocketState {
  readyForQuery: boolean;
  error: string | undefined;
}

export class MaterializeWebsocket {
  httpAddress: string;
  authOptions: AuthOptions;
  private socket: WebSocket | undefined;
  private onReadyForQuery: Callback | undefined;
  private listeners = new Set<() => void>();
  private currentState: MaterializeWebsocketState = {
    readyForQuery: false,
    error: undefined,
  };
  onMessage: MessageCallback | undefined;
  onClose: CloseCallback | undefined;
  onOpen: OpenCallback | undefined;

  constructor(options: {
    httpAddress: string;
    authOptions?: AuthOptions;
    onReadyForQuery?: Callback;
    onMessage?: MessageCallback;
    onClose?: CloseCallback;
    onOpen?: OpenCallback;
  }) {
    this.httpAddress = options.httpAddress;
    this.authOptions = buildSessionVariables({
      application_name: APPLICATION_NAME,
      ...options.authOptions,
    });
    this.onReadyForQuery = options.onReadyForQuery;
    this.onMessage = options.onMessage;
    this.onClose = options.onClose;
    this.onOpen = options.onOpen;
  }

  connect(httpAddress?: string, authOptions?: AuthOptions) {
    this.httpAddress = httpAddress ?? this.httpAddress;
    this.authOptions = authOptions
      ? buildSessionVariables({
          application_name: APPLICATION_NAME,
          ...authOptions,
        })
      : this.authOptions;
    this.disconnect();
    this.socket = new WebSocket(
      `${config.environmentdWebsocketScheme}://${this.httpAddress}/api/experimental/sql`,
    );
    this.socket.addEventListener("open", this.handleOpen);
    this.socket.addEventListener("message", this.handleMessage);
    this.socket.addEventListener("close", this.handleClose);
    this.socket.addEventListener("error", this.handleError);
  }

  disconnect() {
    this.setState({
      readyForQuery: false,
      error: undefined,
    });
    this.socket?.removeEventListener("open", this.handleOpen);
    this.socket?.removeEventListener("message", this.handleMessage);
    this.socket?.removeEventListener("close", this.handleClose);
    this.socket?.removeEventListener("error", this.handleError);
    this.socket?.close();
  }

  send(request: SqlRequest) {
    if (this.socket) {
      this.setState({
        readyForQuery: false,
      });
      this.socket.send(JSON.stringify(request));
    }
  }

  get isInitialized() {
    return Boolean(this.socket);
  }

  get isReadyForQuery() {
    return this.currentState.readyForQuery;
  }

  get error() {
    return this.currentState.error;
  }

  onChange = (callback: () => void) => {
    this.listeners.add(callback);
    return () => this.listeners.delete(callback);
  };

  getSnapshot = () => {
    return this.currentState;
  };

  private setState(update: Partial<MaterializeWebsocketState>) {
    this.currentState = {
      ...this.currentState,
      ...update,
    };
    for (const callback of this.listeners) {
      callback();
    }
  }

  private handleOpen = (event: Event) => {
    this.onOpen?.(event);
    if (this.socket) {
      this.socket.send(
        JSON.stringify(
          // During impersonation and local environmentd development, frontegg is not
          // used for authentication.
          {
            options: this.authOptions,
            ...(config.fronteggAuthDisabled
              ? null
              : { token: getFronteggToken() }),
          },
        ),
      );
    }
  };

  private handleMessage = (event: MessageEvent) => {
    const data = JSON.parse(event.data) as WebSocketResult;
    if (data.type === "ReadyForQuery") {
      this.setState({
        readyForQuery: true,
      });
      this.onReadyForQuery?.();
    }
    this.onMessage?.(data);
  };

  private handleError = () => {
    this.setState({
      error: "Socket error",
    });
    for (const callback of this.listeners) {
      callback();
    }
  };

  private handleClose = (event: CloseEvent) => {
    this.setState({
      readyForQuery: false,
      error: "Connection closed unexpectedly",
    });
    for (const callback of this.listeners) {
      callback();
    }
    this.onClose?.(event);
  };
}

export interface ParameterStatus {
  name: string;
  value: string;
}

export interface CommandStarting {
  has_rows: boolean;
  is_streaming: boolean;
}

export interface BackendKeyData {
  conn_id: number;
  secret_key: number;
}

export type WebsocketRow = { type: "Row"; payload: unknown[] };

export type WebSocketResult =
  | { type: "ReadyForQuery"; payload: string }
  | { type: "Notice"; payload: Notice }
  | { type: "CommandComplete"; payload: string }
  | { type: "Error"; payload: Error }
  | { type: "Rows"; payload: { columns: Column[] } }
  | { type: "Row"; payload: unknown[] }
  | { type: "ParameterStatus"; payload: ParameterStatus }
  | { type: "CommandStarting"; payload: CommandStarting }
  | { type: "BackendKeyData"; payload: BackendKeyData };
