import { history } from "@codemirror/commands";
import {
  extensionManager,
  updateListener,
} from "@codemirror-toolkit/extensions";
import React, { PropsWithChildren, useCallback } from "react";

import Editor, { EditorProps } from "./Editor";
import { EditorCommand, EditorEvent } from "./keymaps";
import lazyLoadGetLanguageExtension from "./mzDialect";
import { _Provider as Provider } from "./provider";

const getLangExt = await lazyLoadGetLanguageExtension();

export const CodeMirrorProvider = ({ children }: PropsWithChildren) => {
  const languageExt = useCallback(() => getLangExt(), []);
  return (
    <Provider
      config={{
        extensions: [
          updateListener(),
          history(),
          languageExt(),
          extensionManager(),
        ],
      }}
    >
      {children}
    </Provider>
  );
};

export default Editor;
export type { EditorCommand, EditorEvent, EditorProps };
