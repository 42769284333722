import { useEffect, useState } from "react";

import storageAvailable from "~/utils/storageAvailable";

function useLocalStorage<T>(key: string, defaultValue: T) {
  const [value, setValue] = useState(() => {
    let currentValue;

    if (storageAvailable("localStorage")) {
      currentValue = JSON.parse(
        localStorage.getItem(key) || String(defaultValue),
      );
    }

    return currentValue;
  });

  useEffect(() => {
    if (storageAvailable("localStorage")) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [value, key]);

  return [value, setValue];
}

export default useLocalStorage;
