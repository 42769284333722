import {
  FormLabel,
  HStack,
  MenuItem,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useTheme,
} from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useCurrentOrganization } from "~/api/auth";
import {
  createNamespace,
  isSystemCluster,
  isSystemId,
} from "~/api/materialize";
import { Replica } from "~/api/materialize/cluster/clusterList";
import { Index } from "~/api/materialize/cluster/indexesList";
import { AppErrorBoundary } from "~/components/AppErrorBoundary";
import IndexListEmptyState from "~/components/IndexListEmptyState";
import { LoadingContainer } from "~/components/LoadingContainer";
import OverflowMenu from "~/components/OverflowMenu";
import DatabaseFilter from "~/components/SchemaObjectFilter/DatabaseFilter";
import SchemaFilter from "~/components/SchemaObjectFilter/SchemaFilter";
import {
  DatabaseFilterState,
  NameFilterState,
  SchemaFilterState,
} from "~/components/SchemaObjectFilter/useSchemaObjectFilters";
import SearchInput from "~/components/SearchInput";
import useLocalStorage from "~/hooks/useLocalStorage";
import { InfoIcon } from "~/icons";
import { MainContentContainer } from "~/layouts/BaseLayout";
import { useBuildIndexPath } from "~/platform/routeHelpers";
import { useAllClusters } from "~/store/allClusters";
import { MaterializeTheme } from "~/theme";
import { truncateMaxWidth } from "~/theme/components/Table";
import { assert } from "~/util";
import { formatMemoryUsage } from "~/utils/format";

import { ClusterParams } from "./ClusterRoutes";
import { formatTableLagInfo } from "./format";
import {
  ArrangmentsMemoryUsageMap,
  LagMap,
  useArrangmentsMemory,
  useIndexesList,
  useMaterializationLag,
  useReplicasBySize,
} from "./queries";

interface IndexListProps {
  databaseFilter: DatabaseFilterState;
  nameFilter: NameFilterState;
  schemaFilter: SchemaFilterState;
}
const IndexList = (props: IndexListProps) => {
  const { clusterId } = useParams<ClusterParams>();
  assert(clusterId);
  const { organization } = useCurrentOrganization();
  const [showSystemObjects, setShowSystemObjects] = useLocalStorage<boolean>(
    `${organization?.id}-${clusterId}-show-system-objects`,
    isSystemCluster(clusterId),
  );

  return (
    <MainContentContainer>
      <HStack mb="6" alignItems="center" justifyContent="space-between">
        <Text textStyle="heading-sm">Indexes</Text>
        <HStack>
          <FormLabel
            htmlFor="show-system-objects"
            variant="inline"
            textStyle="text-base"
          >
            Show introspection objects
          </FormLabel>
          <Switch
            id="show-system-objects"
            isChecked={showSystemObjects}
            onChange={() => setShowSystemObjects((value: boolean) => !value)}
          />
          <DatabaseFilter {...props.databaseFilter} />
          <SchemaFilter {...props.schemaFilter} />
          <SearchInput
            name="source"
            value={props.nameFilter.name}
            onChange={(e) => {
              props.nameFilter.setName(e.target.value);
            }}
          />
        </HStack>
      </HStack>
      <AppErrorBoundary message="An error has occurred loading indexes">
        <React.Suspense fallback={<LoadingContainer />}>
          <IndexListInner {...props} showSystemObjects={showSystemObjects} />
        </React.Suspense>
      </AppErrorBoundary>
    </MainContentContainer>
  );
};

const IndexListInner = ({
  databaseFilter,
  schemaFilter,
  nameFilter,
  showSystemObjects,
}: IndexListProps & { showSystemObjects: boolean }) => {
  const { clusterId } = useParams<ClusterParams>();
  assert(clusterId);
  const { getClusterById } = useAllClusters();
  const { data: indexes } = useIndexesList({
    clusterId,
    includeSystemObjects: showSystemObjects,
    databaseId: databaseFilter.selected?.id,
    schemaId: schemaFilter.selected?.id,
    nameFilter: nameFilter.name,
  });

  const cluster = getClusterById(clusterId);

  const arrangmentIds = React.useMemo(
    () => indexes?.rows.map((i) => i.id),
    [indexes],
  );

  const { data: sortedReplicas } = useReplicasBySize({
    clusterId,
    orderBy: "memory_bytes",
  });
  const replicaSize = sortedReplicas?.rows[0]?.memoryBytes;
  const replicaName = sortedReplicas?.rows[0]?.name;

  const { data } = useArrangmentsMemory({
    arrangmentIds,
    // bigint is not serializable. This conversion is lossy, but shouldn't matter in
    // practice.
    replicaSize: replicaSize ? Number(replicaSize) : undefined,
    replicaName,
    clusterName: cluster?.name,
  });
  const memoryUsageById = data?.memoryUsageById;

  const { data: materializationLagData } = useMaterializationLag({
    objectIds: arrangmentIds,
  });

  const { lagMap } = materializationLagData ?? {};

  const isEmpty = indexes && indexes.rows.length === 0;

  if (isEmpty) {
    return <IndexListEmptyState title="This cluster has no indexes" />;
  }
  return (
    <IndexTable
      indexes={indexes?.rows ?? []}
      replicas={cluster?.replicas ?? []}
      memoryUsageMap={memoryUsageById}
      lagMap={lagMap}
    />
  );
};

interface IndexTableProps {
  indexes: Index[];
  replicas: Replica[];
  memoryUsageMap: ArrangmentsMemoryUsageMap | undefined;
  lagMap?: LagMap;
}

const IndexTable = (props: IndexTableProps) => {
  const navigate = useNavigate();
  const flags = useFlags();
  const indexPath = useBuildIndexPath();
  const dataflowVisualizerEnabled = flags["visualization-features"];
  const { colors } = useTheme<MaterializeTheme>();

  return (
    <>
      <Table variant="linkable" data-testid="index-table" borderRadius="xl">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Object Name</Th>
            <Th>Type</Th>
            <Th>Memory</Th>
            <Th>Lag behind source</Th>
            {dataflowVisualizerEnabled && <Th></Th>}
          </Tr>
        </Thead>
        <Tbody>
          {props.indexes.map((i) => {
            const memoryStats = props.memoryUsageMap?.get(i.id);

            const lagInfo = props.lagMap?.get(i.id);

            const formattedLag = formatTableLagInfo(lagInfo);

            const rowIsClickable = !isSystemId(i.id);
            return (
              <Tr
                key={i.id}
                onClick={(e) => {
                  if (rowIsClickable) {
                    e.preventDefault();
                    navigate(indexPath(i));
                  }
                }}
                cursor={rowIsClickable ? "pointer" : "auto"}
              >
                <Td {...truncateMaxWidth} py="2">
                  <Text
                    textStyle="text-small"
                    fontWeight="500"
                    noOfLines={1}
                    color={colors.foreground.secondary}
                  >
                    {createNamespace(i.databaseName, i.schemaName)}
                  </Text>
                  <Text textStyle="text-ui-med" noOfLines={1}>
                    {i.name}
                  </Text>
                </Td>
                <Td py="2" {...truncateMaxWidth}>
                  <Text
                    textStyle="text-small"
                    fontWeight="500"
                    noOfLines={1}
                    mb="2px"
                    color={colors.foreground.secondary}
                  >
                    {createNamespace(i.databaseName, i.schemaName)}
                  </Text>
                  <Text textStyle="text-ui-reg" noOfLines={1}>
                    {i.relationName} ({i.indexedColumns.join(", ")})
                  </Text>
                </Td>
                <Td>{i.relationType}</Td>
                <Td data-testid="memory-usage">
                  {formatMemoryUsage(memoryStats)}{" "}
                  {lagInfo && !lagInfo.hydrated && (
                    <Tooltip label="Memory usage will continue increase until hydration is complete.">
                      <InfoIcon />
                    </Tooltip>
                  )}
                </Td>
                <Td>{formattedLag}</Td>
                {dataflowVisualizerEnabled && (
                  <Td width="16">
                    <OverflowMenu
                      items={[
                        {
                          visible: dataflowVisualizerEnabled,
                          render: () => (
                            <MenuItem
                              key="dataflow-visualizer"
                              as={Link}
                              to={`${indexPath(i)}/dataflow-visualizer`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              textStyle="text-ui-med"
                            >
                              Visualize
                            </MenuItem>
                          ),
                        },
                      ]}
                    />
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

export default IndexList;
