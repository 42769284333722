import { HStack } from "@chakra-ui/layout";
import { Text, useTheme } from "@chakra-ui/react";
import React from "react";

import TextLink from "~/components/TextLink";
import Org from "~/layouts/Org";
import { MaterializeTheme } from "~/theme";

const PageFooter: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { colors } = useTheme<MaterializeTheme>();
  const currentYear = new Date().getFullYear();

  return (
    <HStack
      spacing="4"
      bg={colors.background.primary}
      color={colors.foreground.secondary}
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      py="2"
      fontWeight="400"
      fontSize="sm"
      boxShadow="footer"
      borderTopColor={colors.border.primary}
      borderTopWidth="1px"
    >
      <Text>© {currentYear} Materialize, Inc.</Text>
      <TextLink href="https://materialize.com/privacy-policy" target="_blank">
        Privacy Policy
      </TextLink>
      <TextLink
        href="https://materialize.com/terms-and-conditions"
        target="_blank"
      >
        Terms &amp; Conditions
      </TextLink>
      <TextLink href="https://status.materialize.com/" target="_blank">
        System Status
      </TextLink>
      <Org />
    </HStack>
  );
};

export default PageFooter;
