import {
  MenuItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

import { SchemaObject } from "~/api/materialize";
import useShowCreate from "~/api/materialize/useShowCreate";
import { Modal } from "~/components/Modal";
import TerminalIcon from "~/svg/Terminal";

import { TabbedCodeBlock } from "./copyableComponents";
import ErrorBox from "./ErrorBox";

export interface ShowCreateModalProps {
  schemaObject: SchemaObject | undefined;
  type: "SOURCE" | "SINK";
}

/**
 * A modal that displays the result of a SHOW CREATE statement
 */
const ShowCreateModal = (props: ShowCreateModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { results: ddl, failedToLoad } = useShowCreate(
    props.type,
    props.schemaObject,
  );

  return (
    <>
      <MenuItem onClick={onOpen}>View DDL statement</MenuItem>
      <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight="500">
            {props.schemaObject?.name} DDL
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="6" alignItems="stretch">
            {failedToLoad && (
              <ErrorBox message="There was an error loading your DDL." />
            )}
            {!failedToLoad && ddl && (
              <TabbedCodeBlock
                data-testid="connection-options"
                lineNumbers
                tabs={[
                  {
                    title: "Terminal",
                    contents: ddl,
                    icon: <TerminalIcon w="4" h="4" />,
                  },
                ]}
                minHeight="208px"
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShowCreateModal;
