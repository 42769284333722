import { VStack } from "@chakra-ui/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DevTools as JotaiDevtools } from "jotai-devtools";
import React, { Suspense } from "react";

import config from "~/config";
import { getStore } from "~/jotai";
import { DEVTOOL_BUTTONS_Z_INDEX } from "~/layouts/zIndex";
import { queryClient } from "~/queryClient";

const SwitchStackModal = React.lazy(() => import("./SwitchStackModal"));

export const DevtoolsContainer = () => {
  return (
    <VStack
      position="fixed"
      bottom="4"
      right="4"
      zIndex={DEVTOOL_BUTTONS_Z_INDEX}
    >
      <ReactQueryDevtools buttonPosition="relative" client={queryClient} />
      <JotaiDevtools position="bottom-right" store={getStore()} />
      <Suspense fallback={null}>
        {config.stackSwitcherEnabled && <SwitchStackModal />}
      </Suspense>
    </VStack>
  );
};
