import { QueryKey } from "@tanstack/react-query";
import { InferResult } from "kysely";

import { executeSqlV2, queryBuilder } from "~/api/materialize";
import { getOwners } from "~/api/materialize/expressionBuilders";

export type ListFilters = {
  databaseId?: string;
  nameFilter?: string;
  schemaId?: string;
};

/**
 * Builds a query to fetch all tables in the current environment
 */
export function buildTableListQuery({
  databaseId,
  schemaId,
  nameFilter,
}: ListFilters) {
  let qb = queryBuilder
    .selectFrom("mz_tables as t")
    .innerJoin("mz_schemas as sc", "sc.id", "t.schema_id")
    .innerJoin("mz_databases as d", "d.id", "sc.database_id")
    .innerJoin(getOwners().as("owners"), "owners.id", "t.owner_id")
    .select([
      "t.id",
      "t.name",
      "sc.name as schemaName",
      "d.name as databaseName",
      "owners.isOwner",
    ])
    .where("t.id", "like", "u%")
    .where("sc.name", "<>", "_mz_fivetran_scratch")
    .orderBy(["d.name", "sc.name", "name"]);
  if (databaseId) {
    qb = qb.where("d.id", "=", databaseId);
  }
  if (schemaId) {
    qb = qb.where("sc.id", "=", schemaId);
  }
  if (nameFilter) {
    qb = qb.where("t.name", "like", `%${nameFilter}%`);
  }
  return qb;
}

/**
 * Fetches all tables in the current environment
 */
export async function fetchTableList({
  filters,
  queryKey,
  requestOptions,
}: {
  filters: ListFilters;
  queryKey: QueryKey;
  requestOptions?: RequestInit;
}) {
  const compiledQuery = buildTableListQuery(filters).compile();
  return executeSqlV2({
    queries: compiledQuery,
    queryKey: queryKey,
    requestOptions,
  });
}

export type Table = InferResult<ReturnType<typeof buildTableListQuery>>[0];
