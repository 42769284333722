import { useLoginActions } from "@frontegg/react";
import { useAuth } from "@frontegg/react";
import * as Sentry from "@sentry/react";
import React from "react";

import config from "~/config";
import { AUTH_ROUTES } from "~/fronteggRoutes";
import { fronteggQueryKeys } from "~/queries/frontegg";
import { queryClient } from "~/queryClient";
import { fronteggLoginDisabled } from "~/utils/frontegg";

import { fetchRefreshAccessToken } from "./frontegg";
import { TokenRefreshResponse } from "./frontegg/types";

let currentToken: string | null = null;
let requestAuthorize: RequestAuthorizeAction = async () => {};

export type RequestAuthorizeAction = ReturnType<
  typeof useLoginActions
>["requestAuthorize"];

/**
 * Holds the current user.accessToken value in module state, which allows us to
 * automatically refresh the token if we get a 401. Frontegg doesn't expose a way to
 * await a token refresh, so we have to use this workaround.
 */
export function getFronteggToken() {
  return currentToken;
}

/**
 * Frontegg redux action to refresh its access token.
 */
export function refreshFronteggToken() {
  requestAuthorize();
}

/**
 * Clears the fronteggToken held in module state, used when logging a user out.
 */
export function clearFronteggToken() {
  queryClient.setQueryData(fronteggQueryKeys.isLoggedIn(), false);
  currentToken = null;
}

/**
 * Updates the fronteggToken held in module state.
 */
export function setFronteggToken(token: string | null) {
  if (!token) {
    Sentry.captureException(
      new Error("Frontegg token is empty, null or undefined"),
    );
  }
  queryClient.setQueryData(fronteggQueryKeys.isLoggedIn(), Boolean(token));
  currentToken = token;
}

export async function refreshToken() {
  // No frontegg login during impersonation
  if (config.environmentdOverride) return null;

  let response: TokenRefreshResponse;
  try {
    response = await fetchRefreshAccessToken();
  } catch (error) {
    const fullPath = location.pathname + location.search + location.hash;
    const redirectUrl = encodeURIComponent(fullPath);
    const loginPath = `${AUTH_ROUTES.loginPath}?redirectUrl=${redirectUrl}`;
    // If this call fails, we assume the user's refresh token is expired
    // and kick them out to the login page.
    if (fronteggLoginDisabled()) {
      // We can't easily trigger router navigation here, so reload the page.
      window.location.href = loginPath;
    } else {
      // Frontegg watches the url for changes, so we can avoid a full reload.
      history.pushState(undefined, "", loginPath);
    }
    Sentry.addBreadcrumb({
      level: "error",
      category: "auth",
      message: "Failed to refresh auth token",
      data: error as Error,
    });
    return null;
  }

  setFronteggToken(response.accessToken);

  // Tell Frontegg's redux store to refresh its token. This is a bit of a hack,
  // since there is no way to tell Frontegg to use the new token we just got, and
  // this action doesn't return a promise so we have no way of getting the new
  // token here. If we don't do this, subsequent requests the frontegg client
  // makes will fail, since that token is also likely expired.
  refreshFronteggToken();

  return response;
}

/**
 * Stores frontegg auth token and refresh action in module state.
 * The requestAuthorize action and user access token is updated any time it changes.
 */
export function useHoistFronteggAuth() {
  const { user } = useAuth();
  const { requestAuthorize: refresh } = useLoginActions();

  React.useEffect(() => {
    requestAuthorize = refresh;
  }, [refresh]);

  if (user?.accessToken) {
    setFronteggToken(user.accessToken);
  }
}
