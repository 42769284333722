import storageAvailable from "./storageAvailable";

export function fronteggLoginDisabled() {
  if (storageAvailable("localStorage")) {
    // TODO: wire this up to a feature flag, so that when the flag is enabled, we update
    // the localstorage value. We should also make sure we read this value once on app
    // load, since it could break things if we switch mid session.
    return localStorage.getItem("mz-frontegg-login") === "false";
  }
  return true;
}
