import { subDays } from "date-fns";
import { InferResult } from "kysely";

import { buildSubscribeQuery } from "~/api/materialize/buildSubscribeQuery";
import { queryBuilder } from "~/api/materialize/db";

// We do not have ideal handling for
// multiprocess clusters (i.e., 2xlarge+ clusters at the time of writing) in
// the Console. Specifically, we use the maximum CPU/memory
// percentage from any process in the replica as each replica's overall
// CPU/memory percentage.
// A better fix should be handled here (https://github.com/MaterializeInc/console/issues/1041)
export function buildClusterReplicaMetricsTable() {
  return queryBuilder
    .selectFrom("mz_cluster_replica_metrics as replicaMetrics")
    .groupBy("replica_id")
    .select(({ fn }) => [
      "replica_id",
      fn.max<bigint | null>("replicaMetrics.memory_bytes").as("memoryBytes"),
      fn.max<bigint | null>("replicaMetrics.disk_bytes").as("diskBytes"),
    ]);
}

export function buildClusterStorageUtilizationQuery() {
  return queryBuilder
    .selectFrom("mz_cluster_replicas as replicas")
    .innerJoin(
      buildClusterReplicaMetricsTable().as("replicaMetrics"),
      "replicaMetrics.replica_id",
      "replicas.id",
    )
    .innerJoin(
      "mz_cluster_replica_sizes as replicaSizes",
      "replicaSizes.size",
      "replicas.size",
    )
    .innerJoin("mz_cluster_replica_statuses as replicaStatuses", (join) =>
      join
        .onRef("replicaStatuses.replica_id", "=", "replicas.id")
        .on("replicaStatuses.process_id", "=", "0"),
    )
    .select([
      "replicas.cluster_id as clusterId",
      "replicaMetrics.replica_id as replicaId",
      "replicas.name as replicaName",
      "replicaMetrics.memoryBytes",
      "replicaMetrics.diskBytes",
      (eb) =>
        eb
          .ref("replicaSizes.memory_bytes")
          .$castTo<bigint | null>()
          .as("totalMemoryBytes"),
      (eb) =>
        eb
          .ref("replicaSizes.disk_bytes")
          .$castTo<bigint | null>()
          .as("totalDiskBytes"),
      "replicaStatuses.status",
      "replicaStatuses.updated_at as statusUpdatedAt",
    ]);
}

/**
 *
 * A subscribe query that gives storage information and status information of all
 * replicas across 15 days.
 */
export function buildClusterStorageUtilizationSubscribeQuery() {
  /**
   * We use 15 since a Materialize release is guaranteed to occur within 14 days.
   * This is because releases are every 7 days but can be skipped.
   */
  // TODO (#2908): Change back to 15 once latency decreases
  const minDate = subDays(new Date(), 7);
  return buildSubscribeQuery(buildClusterStorageUtilizationQuery(), {
    upsertKey: ["replicaId"],
    asOfAtLeast: minDate,
  });
}

export type ClusterStorageUtilization = InferResult<
  ReturnType<typeof buildClusterStorageUtilizationQuery>
>[0];
