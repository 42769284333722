import { sql } from "kysely";

import { queryBuilder } from "~/api/materialize/db";

import { buildFullyQualifiedObjectName } from ".";
import { DatabaseObject } from "./types";

export type DeletableObjectType =
  | "CLUSTER REPLICA"
  | "CLUSTER"
  | "CONNECTION"
  | "INDEX"
  | "MATERIALIZED VIEW"
  | "SECRET"
  | "SINK"
  | "SOURCE"
  | "TABLE"
  | "VIEW";

export function supportsDropCascade(objectType: DeletableObjectType) {
  return objectType !== "CLUSTER REPLICA";
}

export function buildDropObjectStatement({
  dbObject,
  objectType,
}: {
  dbObject: DatabaseObject;
  objectType: DeletableObjectType;
}) {
  const query = sql`DROP ${sql.raw(objectType)} ${buildFullyQualifiedObjectName(
    dbObject,
  )} ${supportsDropCascade(objectType) ? sql.raw("CASCADE") : sql.raw("")}`;
  return query.compile(queryBuilder).sql;
}
