import mitt from "mitt";
import React from "react";

import { EventEmitterContextType, EventEmitterEvents } from "~/types/charts";

// This file was copied from the visx XYchart:
// https://github.com/airbnb/visx/blob/dee0ac097cc3f53f4d81d1d99b736bf0e3c7ac1c/packages/visx-xychart/src/context/EventEmitterContext.tsx

export const EventEmitterContext =
  React.createContext<EventEmitterContextType | null>(null);

export const EventEmitterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const emitter = React.useMemo(() => mitt<EventEmitterEvents>(), []);
  return (
    <EventEmitterContext.Provider value={emitter}>
      {children}
    </EventEmitterContext.Provider>
  );
};
