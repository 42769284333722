import React from "react";
import { Route } from "react-router-dom";

import SourcesList from "~/platform/sources/SourcesList";
import { SentryRoutes } from "~/sentry";

import NewSource from "./create/NewSource";
import SourceDetail from "./SourceDetail";
import SubsourceRoutes from "./SubsourceRoutes";

const SourceRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="/new/*" element={<NewSource />} />
      <Route path="/*" element={<ShowSourceRoutes />} />
    </SentryRoutes>
  );
};

const ShowSourceRoutes = () => {
  return (
    <>
      <SentryRoutes>
        <Route path="/" element={<SourcesList />} />
        <Route path="/subsources/*" element={<SubsourceRoutes />} />
        <Route
          path=":id/:databaseName/:schemaName/:objectName/*"
          element={<SourceDetail />}
        />
      </SentryRoutes>
    </>
  );
};

export default SourceRoutes;
