import * as Sentry from "@sentry/react";
import { sql } from "kysely";
import React from "react";

import { queryBuilder, rawLimit } from "~/api/materialize";
import { useSmallestClusterReplicaSize } from "~/api/materialize/cluster/useSmallestClusterReplicaSize";
import useSqlTyped from "~/api/materialize/useSqlTyped";

export function buildLargestMaintainedQueriesQuery(
  replicaSize: bigint | null,
  limit: number,
) {
  if (!replicaSize) return null;
  const qb = queryBuilder
    .selectFrom("mz_dataflow_arrangement_sizes as s")
    .innerJoin("mz_compute_exports as ce", "ce.dataflow_id", "s.id")
    .leftJoin("mz_objects as o", "o.id", "ce.export_id")
    .leftJoin("mz_schemas as sc", "sc.id", "o.schema_id")
    .leftJoin("mz_databases as da", "da.id", "sc.database_id")
    .select((eb) => [
      "o.id",
      "o.name",
      eb.ref("s.size").$castTo<number>().as("size"),
      sql<number>`${sql.id("s", "size")} / ${sql.raw(
        replicaSize.toString(),
      )} * 100`.as("memoryPercentage"),
      sql<"materialized-view" | "index">`o.type`.as("type"),
      "sc.name as schemaName",
      "da.name as databaseName",
      "s.id as dataflowId",
      "s.name as dataflowName",
    ])
    // Filter out transient dataflows
    .where("ce.export_id", "not like", "t%")
    .orderBy("memoryPercentage", "desc");

  return rawLimit(qb, limit).compile();
}

const spanContext = {
  name: "use-largest-maintained-queries",
  op: "http.client",
};

const STRIP_DATAFLOW_PREFIX = /^Dataflow: /;

/**
 * Fetches the maintained queries that use the most memory
 *
 * memoryPercent is based on the smallest replica in the cluster
 */
function useLargestMaintainedQueries({
  clusterId,
  clusterName,
}: {
  clusterId: string;
  clusterName: string;
}) {
  // TODO(aru): due to an issue where the enclosing context is causing repeated
  // rerenders of this component, the initial query is being issued very
  // frequently. This causes us to blow out our Sentry performance quota.
  // Temporarily disable this. We'll want to reintroduce the transactions once
  // we migrate this query to React Query.
  // const initialTransaction = Sentry.startTransaction(baseTransactionContext);
  const sizeResponse = useSmallestClusterReplicaSize({ clusterId });
  let replicaInfo: { memoryBytes: bigint; replicaName: string } | null = null;
  if (sizeResponse.results && sizeResponse.results.length > 0) {
    replicaInfo = {
      memoryBytes: sizeResponse.results[0].memoryBytes,
      replicaName: sizeResponse.results[0].name,
    };
  }

  const query = React.useMemo(
    () =>
      buildLargestMaintainedQueriesQuery(replicaInfo?.memoryBytes ?? null, 10),
    [replicaInfo?.memoryBytes],
  );

  const response = useSqlTyped(query, {
    cluster: clusterName,
    replica: replicaInfo?.replicaName,
    transformRow: (row) => {
      // If you drop an index used by a materialization, the dataflow stays around, but
      // we can no longer look it up in mz_objects
      const isOrphanedDataflow =
        !row.id || !row.name || !row.databaseName || !row.schemaName;

      let databaseName = row.databaseName,
        schemaName = row.schemaName,
        name = row.name;

      if (isOrphanedDataflow) {
        const fullyQualifiedName = row.dataflowName
          .replace(STRIP_DATAFLOW_PREFIX, "")
          .split(".");
        if (fullyQualifiedName.length === 3) {
          [databaseName, schemaName, name] = fullyQualifiedName;
        }
      }

      return {
        ...row,
        isOrphanedDataflow,
        databaseName: databaseName,
        schemaName: schemaName,
        name: name,
      };
    },
  });

  if (
    !sizeResponse.loading &&
    sizeResponse.results &&
    sizeResponse.results.length === 0
  ) {
    return {
      ...sizeResponse,
      data: [],
      replicaName: null,
      replicaSize: null,
      results: [],
    };
  }

  const error = sizeResponse.error ?? response.error;
  return {
    ...response,
    data: response.results,
    replicaName: replicaInfo?.replicaName ?? null,
    replicaSize: replicaInfo?.memoryBytes ?? null,
    error,
    failedToLoad: sizeResponse.failedToLoad || response.failedToLoad,
    isError: error !== null,
    refetch: async () => {
      Sentry.startSpan(
        {
          ...spanContext,
          attributes: {
            polled: true,
          },
        },
        async () => {
          await sizeResponse.refetch();
          return await response.refetch();
        },
      );
    },
  };
}

export type LargestMaintainedQueriesResponse = ReturnType<
  typeof useLargestMaintainedQueries
>;

export type Sink = NonNullable<LargestMaintainedQueriesResponse["data"]>[0];

export default useLargestMaintainedQueries;
