import React from "react";

import { queryBuilder } from "./db";
import useSqlTyped from "./useSqlTyped";

/**
 * Fetches subsoures for a single source
 */
export function useSubsources(sourceId?: string) {
  const query = React.useMemo(() => {
    if (!sourceId) return null;

    return queryBuilder
      .selectFrom("mz_sources as s")
      .innerJoin("mz_object_dependencies as od", "od.object_id", "s.id")
      .innerJoin("mz_schemas as sc", "sc.id", "s.schema_id")
      .innerJoin("mz_databases as d", "d.id", "sc.database_id")
      .select([
        "s.id",
        "s.name",
        "d.name as databaseName",
        "sc.name as schemaName",
      ])
      .where("od.referenced_object_id", "=", sourceId)
      .orderBy(["d.name", "sc.name", "name"])
      .compile();
  }, [sourceId]);

  return useSqlTyped(query);
}

export type SubsourcesResponse = ReturnType<typeof useSubsources>;

export type SubSource = NonNullable<SubsourcesResponse["results"]>[0];
