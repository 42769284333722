import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";

import { SentryRoutes } from "~/sentry";

import { ConnectionDetails } from "./ConnectionDetails";
import { SchemaObjectHeader } from "./SchemaObjectHeader";
import { useSchemaObjectParams } from "./useSchemaObjectParams";
import { useToastIfObjectNotExtant } from "./useToastIfObjectNotExtant";

const CONNECTION_TABS = [
  {
    label: "Details",
    href: `./.`,
    end: true,
  },
];

export const ConnectionDetailRoutes = () => {
  const objectRouteParams = useSchemaObjectParams();
  useToastIfObjectNotExtant();
  return (
    <SentryRoutes>
      <Route
        element={
          <>
            <SchemaObjectHeader
              objectType="connection"
              tabStripItems={CONNECTION_TABS}
            />
            <Outlet />
          </>
        }
      >
        <Route
          path="/"
          element={<ConnectionDetails {...objectRouteParams} />}
        />
        <Route path="*" element={<Navigate to="../" replace />} />
      </Route>
    </SentryRoutes>
  );
};
