import {
  LDContext,
  LDProvider,
  useLDClient,
  useLDClientError,
} from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";

import LoadingScreen from "~/components/LoadingScreen";
import config from "~/config";
import { useCurrentUser } from "~/queries/frontegg";

export const LaunchDarklyProviderWrapper = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const ldClient = useLDClient();
  const ldClientError = useLDClientError();

  /**
   * When 'deferInitialization' is set to true, useLDClient() returns undefined
   * while Launch Darkly is initializing.
   */
  const isLoading = ldClient === undefined;

  if (ldClientError) {
    // Trigger error boundary on error
    throw ldClientError;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return children;
};

/**
 * A wrapper over LaunchDarkly's LDProvider
 *
 * We use deferInitialization to block rendering the app until the flag values are
 * available. This is important since incorrect flag values can redirect users to the
 * home page on routes that are guarded by feature flags.
 */
export const LaunchDarklyProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [ldContext, setLdContext] = useState<LDContext | undefined>(undefined);

  const { data: user } = useCurrentUser();

  useEffect(() => {
    // We can assume user and organizationId are always available since this component
    // doesn't mount until Frontegg is initialized.
    const context: LDContext = {
      kind: "multi",
      ...(config.environmentdOverride
        ? {
            user: {
              anonymous: true,
            },
          }
        : {}),
      ...(config.impersonation
        ? {
            impersonated_organization: {
              key: config.impersonation.organizationId,
            },
          }
        : {}),
      ...(user
        ? {
            user: {
              key: user.id,
              email: user.email,
            },
          }
        : {}),
      ...(user.tenantId
        ? {
            organization: {
              key: user.tenantId,
            },
          }
        : {}),
    };

    setLdContext(context);
  }, [user]);

  return (
    <LDProvider
      clientSideID={config.launchDarklyKey}
      reactOptions={{
        useCamelCaseFlagKeys: false,
      }}
      deferInitialization
      context={ldContext}
      options={{
        bootstrap: "localStorage",
      }}
    >
      <LaunchDarklyProviderWrapper>{children}</LaunchDarklyProviderWrapper>
    </LDProvider>
  );
};
