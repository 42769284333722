import React from "react";
import { Route } from "react-router-dom";

import { SentryRoutes } from "~/sentry";

import { AcceptInvitation } from "./AcceptInvitation";
import { ActivateAccount } from "./ActivateAccount";
import { Login } from "./Login";
import { Logout } from "./Logout";
import { OauthCallback } from "./OauthCallback";
import { OidcCallback } from "./OidcCallback";
import { Signup } from "./Signup";

export const AuthRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="oidc/callback" element={<OidcCallback />} />
      <Route path="activate" element={<ActivateAccount />} />
      <Route path="invitation/accept" element={<AcceptInvitation />} />
      <Route path="sign-up" element={<Signup />} />
      <Route path="social/success" element={<OauthCallback />} />
    </SentryRoutes>
  );
};
