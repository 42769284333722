import React from "react";
import { Route, useParams } from "react-router-dom";

import { LoadingContainer } from "~/components/LoadingContainer";
import WorkflowGraph from "~/components/WorkflowGraph/WorkflowGraph";
import { PageHeader, PageTabStrip } from "~/layouts/BaseLayout";
import { SentryRoutes } from "~/sentry";

const SubsourceDetail = () => {
  const params = useParams<{ id: string }>();

  return (
    <SentryRoutes>
      <Route
        path="workflow"
        element={<WorkflowGraph focusedObjectId={params.id} />}
      />
    </SentryRoutes>
  );
};

const SubsourceRoutes = () => {
  return (
    <SentryRoutes>
      <Route
        path=":id/*"
        element={
          <>
            <PageHeader variant="compact" sticky>
              <PageTabStrip
                tabData={[{ href: `workflow`, label: "Workflow" }]}
              />
            </PageHeader>
            <React.Suspense fallback={<LoadingContainer />}>
              <SubsourceDetail />
            </React.Suspense>
          </>
        }
      />
    </SentryRoutes>
  );
};

export default SubsourceRoutes;
