import { Expression, RawBuilder, Simplify, sql } from "kysely";

import { queryBuilder } from "./db";

export function getOwners() {
  return queryBuilder.selectFrom("mz_roles as r").select((eb) => [
    "r.id",
    eb
      .or([
        eb.fn<boolean>("mz_is_superuser", []),
        eb.fn<boolean>("has_role", [
          sql.id("current_user"),
          "r.oid",
          sql.lit("USAGE"),
        ]),
      ])
      .$castTo<boolean>()
      .as("isOwner"),
  ]);
}

export function jsonArrayFrom<O>(
  expr: Expression<O>,
): RawBuilder<Simplify<O>[]> {
  return sql`(select coalesce(jsonb_agg(agg), '[]') from ${expr} as agg)`;
}

// NOTE(benesch): We do not have ideal handling for
// multiprocess clusters (i.e., 2xlarge+ clusters at the time of writing) in
// the Console. Specifically, we use the maximum CPU/memory
// percentage from any process in the replica as each replica's overall
// CPU/memory percentage. Ideally it would return data for each process in
// the replica separately, but the downstream consumers (e.g., the replica
// table) are not yet equipped to handle that.
// A better fix should be handled here (https://github.com/MaterializeInc/console/issues/1041)
export function buildClusterReplicaUtilizationTable(
  {
    mzClusterReplicaUtilization = "mz_cluster_replica_utilization",
  }: {
    mzClusterReplicaUtilization?: "mz_cluster_replica_utilization";
  } = {
    mzClusterReplicaUtilization: "mz_cluster_replica_utilization",
  },
) {
  return queryBuilder
    .selectFrom(`${mzClusterReplicaUtilization} as cru`)
    .groupBy("replica_id")
    .select(({ fn }) => [
      "replica_id",
      fn.max("cru.cpu_percent").as("cpu_percent"),
      fn.max("cru.memory_percent").as("memory_percent"),
      fn.max("cru.disk_percent").as("disk_percent"),
    ]);
}

/**
 * Useful since Kysely's count function returns a union of all number types in TypeScript
 */
export function countAll() {
  return sql<bigint>`count(*)`.as("count");
}
