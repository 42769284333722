import { ModalBody, ModalContent, useSteps } from "@chakra-ui/react";
import React from "react";
import { Navigate, Route, useLocation, useNavigate } from "react-router-dom";

import { FormTopBar, WizardStep } from "~/components/formComponentsV2";
import { Modal } from "~/components/Modal";
import { useMaxMySqlConnections } from "~/platform/connections/queries";
import { SentryRoutes } from "~/sentry";

import NewKafkaSourceContent from "./kafka/NewKafkaSourceContent";
import NewMySqlSourceContent from "./mysql/NewMySqlSourceContent";
import { NewWebhookSourceContent } from "./NewWebhookSource";
import NewPostgresSourceContent from "./postgres/NewPostgresSourceContent";
import SourceGallery from "./SourceGallery";

const SOURCE_STEPS: WizardStep[] = [{ id: "choose", label: "Create a source" }];

const SourceGalleryContent = ({
  initialSteps,
}: {
  initialSteps: WizardStep[];
}) => {
  const wizardSteps = useSteps({ index: 0, count: initialSteps.length });
  return (
    <>
      <FormTopBar
        title="Create a Source"
        steps={SOURCE_STEPS}
        {...wizardSteps}
      ></FormTopBar>
      <ModalBody>
        <SourceGallery />
      </ModalBody>
    </>
  );
};

const NewSource = () => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const previousPage = locationState?.previousPage ?? "../..";
  const { data: maxMySqlConnections } = useMaxMySqlConnections();
  const mySqlEnabled = (maxMySqlConnections ?? 0) > 0;
  return (
    <Modal
      isOpen
      onClose={() => navigate(previousPage)}
      size="full"
      closeOnEsc={false}
      scrollBehavior="inside"
    >
      <ModalContent>
        <SentryRoutes>
          <Route
            path=""
            element={<SourceGalleryContent initialSteps={SOURCE_STEPS} />}
          />
          <Route
            path="/webhook"
            element={<NewWebhookSourceContent initialSteps={SOURCE_STEPS} />}
          />
          <Route
            path="/postgres"
            element={<NewPostgresSourceContent initialSteps={SOURCE_STEPS} />}
          />
          {mySqlEnabled && (
            <Route
              path="/mysql"
              element={<NewMySqlSourceContent initialSteps={SOURCE_STEPS} />}
            />
          )}
          <Route
            path="/kafka"
            element={<NewKafkaSourceContent initialSteps={SOURCE_STEPS} />}
          />
          <Route path="*" element={<Navigate to="" />} />
        </SentryRoutes>
      </ModalContent>
    </Modal>
  );
};

export default NewSource;
