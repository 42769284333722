import React from "react";
import { Navigate, Route } from "react-router-dom";

import { AUTH_ROUTES } from "~/fronteggRoutes";
import { AuthRoutes } from "~/platform/auth/AuthRoutes";
import { AuthenticatedRoutes } from "~/platform/AuthenticatedRoutes";
import { useIsLoggedIn } from "~/queries/frontegg";
import { SentryRoutes } from "~/sentry";

export const UnauthenticatedRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="/account/*" element={<AuthRoutes />} />
      <Route path="*" element={<AuthenticatedRoutesWrapper />} />
    </SentryRoutes>
  );
};

export const AuthenticatedRoutesWrapper = () => {
  const { data: isLoggedIn } = useIsLoggedIn();

  if (!isLoggedIn) {
    const fullPath = location.pathname + location.search + location.hash;
    const redirectUrl = encodeURIComponent(fullPath);
    return (
      <Navigate to={`${AUTH_ROUTES.loginPath}?redirectUrl=${redirectUrl}`} />
    );
  }

  return <AuthenticatedRoutes />;
};
