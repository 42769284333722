import {
  Box,
  chakra,
  Flex,
  HStack,
  StackProps,
  Text,
  useTheme,
} from "@chakra-ui/react";
import { AxisBottom, AxisLeft, AxisScale } from "@visx/axis";
import { localPoint } from "@visx/event";
import { GridRows } from "@visx/grid";
import ParentSize, {
  ParentSizeProvidedProps,
} from "@visx/responsive/lib/components/ParentSize";
import { scaleLinear, scaleTime } from "@visx/scale";
import { Line, LinePath } from "@visx/shape";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import React, { PointerEvent, useMemo } from "react";

import {
  GraphLineCursor,
  GraphTooltipCursor,
} from "~/components/graphComponents";
import { useHandleEmittedEvent } from "~/hooks/useHandleEmittedEvent";
import {
  getOffset,
  useCursorState,
  usePointerEventEmitters,
} from "~/hooks/usePointerEventEmitters";
import { MaterializeTheme } from "~/theme";
import { notNullOrUndefined } from "~/util";
import { findNearestDatum } from "~/utils/graph";

import { DataPoint, GraphDataKey, OfflineEvent, ReplicaData } from "./types";
import {
  UtilizationTooltip,
  UtilizationTooltipData,
} from "./UtilizationTooltip";

// Constants for graph element height so we can show the correct height loading container
const GRAPH_HEIGHT_PX = 300;
const LABEL_HEIGHT_PX = 18;
const LABEL_MARGIN_PX = 8;
export const TOTAL_GRAPH_HEIGHT_PX =
  GRAPH_HEIGHT_PX + LABEL_HEIGHT_PX + LABEL_MARGIN_PX;

// number of ticks we show on the graph
const tickCount = 8;

const margin = { top: 10, right: 0, bottom: 36, left: 40 };
export const graphHeightPx = 300;

interface UtilizationGraphProps {
  bucketSizeMs: number;
  /** Array of lines to show on the graph */
  data: ReplicaData[];
  dataKey: GraphDataKey;
  endTime: Date;
  offlineEvents: Array<OfflineEvent>;
  replicaColorMap: Map<string, { label: string; color: string }>;
  startTime: Date;
  timePeriodMinutes: number;
  title: string;
}

export const ClusterUtilizationGraphInner = ({
  bucketSizeMs,
  data,
  dataKey,
  endTime,
  height,
  offlineEvents,
  replicaColorMap,
  startTime,
  timePeriodMinutes,
  title,
  width,
}: UtilizationGraphProps & ParentSizeProvidedProps) => {
  const [statusVisible, setStatusVisible] = React.useState(true);
  const [metricVisible, setMetricVisible] = React.useState(true);
  const { colors, fonts } = useTheme<MaterializeTheme>();
  const { tooltipLeft, tooltipTop, showTooltip, hideTooltip } =
    useTooltip<UtilizationTooltipData>();
  const {
    containerRef: tooltipContainerRef,
    containerBounds,
    TooltipInPortal,
  } = useTooltipInPortal({
    detectBounds: true,
  });
  const { cursor, handleCursorMove, hideCursor } = useCursorState();
  const { onPointerMove, onPointerOut } = usePointerEventEmitters({
    source: "ClusterUtilizationGraph",
  });

  // bounds
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const xScale = React.useMemo(
    () =>
      scaleTime({
        domain: [startTime.getTime(), endTime.getTime()],
        range: [margin.left, width],
      }),
    [endTime, startTime, width],
  );
  const yScale = React.useMemo(
    () =>
      scaleLinear({
        domain: [100, 0],
        range: [margin.bottom, innerHeight],
      }),
    [innerHeight],
  );

  const handleTooltip = React.useCallback(
    (event: PointerEvent) => {
      const svgPoint = localPoint(event);
      if (!svgPoint) return;

      const { offsetX, offsetY } = getOffset(event, svgPoint, containerBounds);

      // To handle scrolled containers, we add an offset calculated from the original event
      showTooltip({
        tooltipTop: svgPoint.y + offsetY,
        tooltipLeft: svgPoint.x + offsetX,
      });
    },
    [showTooltip, containerBounds],
  );
  useHandleEmittedEvent(
    "pointermove",
    ({ event }) => {
      if ("clientX" in event && "clientY" in event) {
        handleCursorMove(event);
      }
    },
    ["ClusterUtilizationGraph"],
  );
  useHandleEmittedEvent("pointerout", hideCursor, ["ClusterUtilizationGraph"]);

  const tooltipData = useMemo(() => {
    if (!cursor) {
      return null;
    }

    // find all data points that are closest to the cursor
    const nearestPoints = data
      .map(({ data: replicaData }) => {
        const nearestResult = findNearestDatum({
          scale: xScale,
          accessor: (value: DataPoint) => value.bucketStart,
          scaledValue: cursor.x,
          data: replicaData,
        });
        if (!nearestResult || nearestResult.distance > bucketSizeMs) {
          // If the closest point is more than the width of a bucket away,
          // don't show it in the tooltip.
          return null;
        }
        return nearestResult;
      })
      .filter(notNullOrUndefined);

    const maxXValue = xScale(
      Math.max(...nearestPoints.map((p) => p.datum.bucketStart)),
    );

    return {
      data: nearestPoints.map((p) => p.datum),
      nearestPointX: maxXValue,
      points: nearestPoints.map((point) => ({
        color:
          replicaColorMap.get(point.datum.id)?.color ?? colors.accent.purple,
        key: point.datum.id,
        // Snap the point to the start of the time bucket
        x: xScale(point.datum.bucketStart),
        // Show the point on the largest y value
        y: yScale(point.datum[dataKey] ?? 0),
      })),
    };
  }, [
    bucketSizeMs,
    colors.accent.purple,
    cursor,
    data,
    dataKey,
    replicaColorMap,
    xScale,
    yScale,
  ]);

  const yTicks = React.useMemo(() => [0, 25, 50, 75, 100], []);

  const xTicks = React.useMemo(() => {
    const startTimeMs = startTime.getTime();
    const duration = endTime.getTime() - startTimeMs;
    const tickSlots = Array.from({
      length: tickCount,
    }) as undefined[];
    return tickSlots.map((_, i) => i * (duration / tickCount) + startTimeMs);
  }, [endTime, startTime]);
  const legendData = React.useMemo(
    () => Array.from(replicaColorMap.entries()),
    [replicaColorMap],
  );
  const replicaColors = React.useMemo(
    () => Array.from(replicaColorMap.values()).map(({ color }) => color),
    [replicaColorMap],
  );
  const statusLineColor = colors.foreground.tertiary;

  return (
    <Box position="relative">
      <HStack justifyContent="space-between" mb={2}>
        <Text fontSize="sm" lineHeight="16px" fontWeight={500}>
          {title}
        </Text>
        <HStack spacing={4} as="ul">
          <LegendItem
            icon={<ColoredDotRow colors={replicaColors} />}
            text="max()"
            onClick={() => setMetricVisible((val) => !val)}
          />
          <LegendItem
            icon={<ColoredDot color={statusLineColor} />}
            text="replica restarts"
            onClick={() => setStatusVisible((val) => !val)}
          />
        </HStack>
      </HStack>
      <svg width={width} height={height} ref={tooltipContainerRef}>
        <GridRows
          left={margin.left}
          scale={yScale}
          width={innerWidth}
          stroke={colors.border.primary}
          strokeDasharray="4"
          tickValues={yTicks}
          pointerEvents="none"
        />
        <AxisLeft<AxisScale<number>>
          hideAxisLine
          hideTicks
          left={margin.left}
          scale={yScale}
          stroke={colors.border.secondary}
          tickFormat={(value) => `${value.toFixed()}%`}
          tickLabelProps={() => ({
            fill: colors.foreground.secondary,
            fontFamily: fonts.mono,
            fontSize: "12",
            textAnchor: "end",
            dx: "-4px",
            dy: "5px",
          })}
          tickValues={yTicks}
        />
        <AxisBottom<AxisScale<number>>
          hideTicks
          scale={xScale}
          stroke={colors.border.secondary}
          strokeWidth={2}
          tickLabelProps={() => ({
            fill: colors.foreground.secondary,
            fontFamily: fonts.mono,
            fontSize: "12",
            textAnchor: "middle",
          })}
          tickFormat={(value) => {
            if (timePeriodMinutes < 12 * 60) {
              return `${differenceInMinutes(
                endTime,
                new Date(value),
              ).toString()}m`;
            }
            if (timePeriodMinutes < 30 * 24 * 60) {
              return `${differenceInHours(
                endTime,
                new Date(value),
              ).toString()}h`;
            }
            return `${differenceInDays(endTime, new Date(value)).toString()}d`;
          }}
          tickValues={xTicks}
          top={innerHeight}
        />
        {data.map((replicaData) => {
          if (replicaData.data.length < 1) return null;
          return (
            metricVisible && (
              <LinePath
                key={replicaData.id}
                defined={(datapoint) => datapoint[dataKey] !== null}
                stroke={replicaColorMap.get(replicaData.id)?.color}
                strokeWidth={1}
                data={replicaData.data}
                x={(d) => xScale(d.bucketStart)}
                y={(d) => yScale(d[dataKey] ?? 0)}
              />
            )
          );
        })}
        {data.map((replicaData) =>
          replicaData.data.map((event) => {
            const offlineEventsWithReasons = event.offlineEvents.filter(
              ({ offlineReason }) => !!offlineReason,
            );
            if (offlineEventsWithReasons.length === 0) return null;
            return (
              <circle
                key={event.id + event.bucketStart}
                cx={xScale(event.bucketStart)}
                cy={yScale(event[dataKey] ?? 0)}
                r="5"
                fill="white"
                stroke="#000082"
                strokeWidth={1.5}
              />
            );
          }),
        )}
        {offlineEvents.map(
          (event) =>
            statusVisible && (
              <Line
                key={event.id + event.timestamp}
                from={{ x: xScale(event.timestamp), y: margin.top }}
                to={{ x: xScale(event.timestamp), y: innerHeight }}
                stroke={statusLineColor}
                strokeWidth={1}
                pointerEvents="none"
              />
            ),
        )}
        {cursor && (
          <GraphLineCursor
            graphBottom={innerHeight + margin.top}
            graphTop={margin.top}
            point={cursor}
          />
        )}

        {tooltipData?.points && tooltipData.points.length > 0 && (
          <GraphTooltipCursor points={tooltipData.points} />
        )}
        <rect
          x={margin.left}
          y={margin.top}
          width={width - margin.left - margin.right}
          height={height - margin.top - margin.bottom}
          fill="transparent"
          onPointerMove={(event) => {
            handleTooltip(event);
            onPointerMove?.(event, containerBounds);
          }}
          onPointerOut={(event) => {
            hideTooltip();
            onPointerOut?.(event, containerBounds);
          }}
        />
      </svg>
      <Flex justifyContent="space-between" alignItems="start" gap="4">
        <HStack spacing={4} as="ul" ml={8} flexWrap="wrap">
          {legendData.map(([replicaId, { label, color }]) => (
            <HStack as="li" alignItems="center" key={replicaId}>
              <LegendItem
                as="div"
                text={label}
                icon={<ReplicaLineIcon color={color} />}
              />
            </HStack>
          ))}
        </HStack>
      </Flex>
      {tooltipLeft && tooltipTop && tooltipData && (
        <UtilizationTooltip
          component={TooltipInPortal}
          dataKey={dataKey}
          replicaColorMap={replicaColorMap}
          tooltipData={tooltipData}
          left={tooltipLeft}
          top={tooltipTop}
        />
      )}
    </Box>
  );
};

export const UtilizationGraph = (props: UtilizationGraphProps) => {
  return (
    <ParentSize
      className="graph-container"
      debounceTime={10}
      style={{ width: "100%", minWidth: 0 }}
    >
      {(parentSizeState) => {
        return (
          <ClusterUtilizationGraphInner
            {...parentSizeState}
            height={graphHeightPx}
            {...props}
          />
        );
      }}
    </ParentSize>
  );
};

const ColoredDot = (props: { color?: string }) => {
  return (
    <chakra.div
      backgroundColor={props.color}
      height="8px"
      width="8px"
      borderRadius="8px"
    />
  );
};

const ColoredDotRow = (props: { colors: Array<string> }) => {
  return (
    <HStack spacing={1} maxWidth="72px" flexWrap="wrap" rowGap="2">
      {props.colors.map((color, i) => (
        <ColoredDot key={color + i} color={color} />
      ))}
    </HStack>
  );
};

const ReplicaLineIcon = (props: { color: string }) => {
  return (
    <Flex
      position="relative"
      width="3"
      height="2"
      justifyContent="center"
      alignItems="center"
    >
      <chakra.div
        backgroundColor={props.color}
        height="2"
        width="2"
        borderRadius="8px"
        position="absolute"
      />
      <chakra.div
        position="absolute"
        backgroundColor={props.color}
        width="100%"
        height="2px"
      />
    </Flex>
  );
};

const LegendItem = ({
  text,
  icon,
  ...props
}: React.PropsWithChildren<
  {
    text: string;
    icon?: React.ReactNode;
  } & StackProps
>) => {
  return (
    <HStack as="button" {...props}>
      <Box minWidth="2">{icon}</Box>
      <Text fontSize="xs">{text}</Text>
    </HStack>
  );
};
