import config from "~/config";
import { useRegionSlug } from "~/store/environments";

type RangeParams = {
  from: string;
  to: string;
};

const defaultRangeParams: RangeParams = {
  from: "now-15m",
  to: "now",
};

const grafanaBaseUrl = "https://grafana.dev.materialize.com/explore";

/**
 * Generate a URL that links to a trace in Grafana.
 *
 * @param traceId The ID of the trace to link to
 * @param rangeParams Optional time range parameters to supply to the query
 */
function useGrafanaTraceUrl(
  traceId: string,
  rangeParams: RangeParams = defaultRangeParams,
) {
  const regionSlug = useRegionSlug();
  const datasourceId = config.tempoDatasourceIds[regionSlug];
  if (!datasourceId) {
    console.warn(`Could not find datasource ID for ${regionSlug}`);
    return null;
  }
  const panes = {
    tracePane: {
      datasource: datasourceId,
      queries: [
        {
          refId: "traceId",
          datasource: {
            type: "tempo",
            uid: datasourceId,
          },
          queryType: "traceql",
          limit: 1,
          tableType: "traces",
          query: traceId,
        },
      ],
      range: rangeParams,
    },
  };
  const url = new URL(grafanaBaseUrl);
  url.searchParams.set("schemaVersion", "1");
  url.searchParams.set("panes", JSON.stringify(panes));
  url.searchParams.set("orgId", "1");
  return url.toString();
}

export default useGrafanaTraceUrl;
