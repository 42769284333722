import { sql } from "kysely";
import React from "react";

import {
  buildFullyQualifiedObjectName,
  queryBuilder,
  SchemaObject,
  useSqlTyped,
} from "~/api/materialize";

export type DDLNoun = "SINK" | "SOURCE";

/**
 * Fetches the DDL statement for creating a schema object
 */
function useShowCreate(noun: DDLNoun, schemaObject?: SchemaObject) {
  const query = React.useMemo(() => {
    if (!schemaObject) return null;

    return sql<{
      name: string;
      create_sql: string;
    }>`SHOW CREATE ${sql.raw(noun)} ${buildFullyQualifiedObjectName(
      schemaObject,
    )}`.compile(queryBuilder);
  }, [noun, schemaObject]);

  const response = useSqlTyped(query);

  let ddl: string | null = null;
  if (response.results) {
    ddl = response.results[0].create_sql;
  }

  return { ...response, results: ddl };
}

export default useShowCreate;
