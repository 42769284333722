import {
  Flex,
  HStack,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import { createNamespace } from "~/api/materialize";
import { useSubsources } from "~/api/materialize/useSubsources";
import ErrorBox from "~/components/ErrorBox";
import { MainContentContainer } from "~/layouts/BaseLayout";
import { MaterializeTheme } from "~/theme";
import { truncateMaxWidth } from "~/theme/components/Table";

import { SOURCES_FETCH_ERROR_MESSAGE } from "./constants";

export interface SubsourceProps {
  sourceId?: string;
}

const Subsources = ({ sourceId }: SubsourceProps) => {
  const {
    results: sources,
    isInitiallyLoading: isLoading,
    failedToLoad,
  } = useSubsources(sourceId);
  const { colors } = useTheme<MaterializeTheme>();

  const isEmpty = sources && sources.length === 0;

  return (
    <MainContentContainer>
      <HStack spacing={6} height="100%">
        <VStack width="100%" spacing={6} height="100%">
          <VStack
            spacing={6}
            width="100%"
            height="100%"
            alignItems="flex-start"
          >
            <Text textStyle="heading-sm">Subsources</Text>
            {failedToLoad ? (
              <Flex justifyContent="center" width="100%" flex="1">
                <ErrorBox message={SOURCES_FETCH_ERROR_MESSAGE} />
              </Flex>
            ) : isLoading ? (
              <Flex justifyContent="center" width="100%">
                <Spinner data-testid="loading-spinner" />
              </Flex>
            ) : isEmpty ? (
              <Flex width="100%" justifyContent="center">
                No subsources
              </Flex>
            ) : (
              <Table
                variant="standalone"
                data-testid="subsources-table"
                borderRadius="xl"
              >
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sources?.map((s) => (
                    <Tr key={s.id}>
                      <Td py="3" {...truncateMaxWidth}>
                        <Text
                          textStyle="text-small"
                          fontWeight="500"
                          mb="2px"
                          noOfLines={1}
                          color={colors.foreground.secondary}
                        >
                          {createNamespace(s.databaseName, s.schemaName)}
                        </Text>
                        <Text textStyle="text-ui-med" noOfLines={1}>
                          {s.name}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </VStack>
          );
        </VStack>
      </HStack>
    </MainContentContainer>
  );
};

export default Subsources;
