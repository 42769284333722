import * as Sentry from "@sentry/react";
import { sql } from "kysely";
import React from "react";

import { queryBuilder, rawLimit } from "~/api/materialize";
import useSqlTyped from "~/api/materialize/useSqlTyped";

export function buildSmallestReplicaQuery(clusterId: string) {
  const qb = queryBuilder
    .selectFrom("mz_cluster_replicas as cr")
    .innerJoin("mz_cluster_replica_sizes as crs", "cr.size", "crs.size")
    .select((eb) => [
      "cr.name",
      sql<bigint>`${eb.ref("crs.memory_bytes")} * ${eb.ref("crs.processes")}`.as(
        "memoryBytes",
      ),
    ])
    .where("cr.cluster_id", "=", clusterId)
    // Order by smallest replicas first
    .orderBy("crs.memory_bytes");

  // take the first (smallest) replica
  return rawLimit(qb, 1).compile();
}

const spanContext = {
  name: "use-smallest-cluster-replica-size",
  op: "http.cleitn",
};

/**
 * Returns the size of the smallest replica for a given cluster
 */
export function useSmallestClusterReplicaSize({
  clusterId,
}: {
  clusterId?: string;
}) {
  const sizeQuery = React.useMemo(() => {
    if (!clusterId) return null;

    return buildSmallestReplicaQuery(clusterId);
  }, [clusterId]);
  const results = useSqlTyped(sizeQuery);
  return {
    ...results,
    refetch: async () => {
      Sentry.startSpan(
        {
          ...spanContext,
          attributes: {
            polled: true,
          },
        },
        async () => results.refetch(),
      );
    },
  };
}
