import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useController, useForm } from "react-hook-form";

import { useSegment } from "~/analytics/segment";
import { alreadyExistsError } from "~/api/materialize/parseErrors";
import { Schema } from "~/api/materialize/schemaList";
import useSchemas, { isDefaultSchema } from "~/api/materialize/useSchemas";
import { MATERIALIZE_DATABASE_IDENTIFIER_REGEX } from "~/api/materialize/validation";
import Alert from "~/components/Alert";
import ErrorBox from "~/components/ErrorBox";
import { Modal } from "~/components/Modal";
import ObjectNameInput from "~/components/ObjectNameInput";
import SchemaSelect from "~/components/SchemaSelect";
import { ObjectToastDescription } from "~/components/Toast";
import { useToast } from "~/hooks/useToast";
import { MaterializeTheme } from "~/theme";
import { capitalizeSentence } from "~/util";

import { useCreateSecret } from "./queries";

type FormValues = {
  name: string;
  schema: Schema;
  value: string;
};

const NAME_FIELD = "name";
const VALUE_FIELD = "value";

const NewSecretModal = ({
  isOpen,
  onClose,
  onPrimaryButtonAction,
}: {
  isOpen: boolean;
  onClose: () => void;
  onPrimaryButtonAction: () => void;
}) => {
  const [generalFormError, setGeneralFormError] = useState<string | null>(null);
  const toast = useToast();

  const { shadows } = useTheme<MaterializeTheme>();
  const { track } = useSegment();

  const {
    control,
    register,
    handleSubmit: handleSubmit,
    reset: formReset,
    formState,
    setError,
    setFocus,
  } = useForm<FormValues>({
    mode: "onTouched",
  });

  const { results: schemas, failedToLoad } = useSchemas({
    filterByCreatePrivilege: true,
  });

  const { mutate, isPending: isCreationInFlight } = useCreateSecret();

  const handleClose = () => {
    formReset();
    onClose();
  };

  const handleValidSubmit = async (formValues: FormValues) => {
    setGeneralFormError(null);
    const variables = {
      name: formValues.name,
      databaseName: formValues.schema.databaseName,
      schemaName: formValues.schema.name,
      value: formValues.value,
    };
    mutate(variables, {
      onSuccess: () => {
        onPrimaryButtonAction();
        toast({
          description: (
            <ObjectToastDescription
              name={formValues.name}
              message="created successfully"
            />
          ),
        });
        formReset();
      },
      onError: (error) => {
        if (alreadyExistsError(error.message)) {
          setError("name", {
            message: capitalizeSentence(error.message),
          });
          setFocus(NAME_FIELD);
        } else {
          setGeneralFormError(error.message);
        }
      },
    });
  };
  const { field: schemaField } = useController({
    control,
    name: "schema",
    rules: {
      required: "Schema is required.",
    },
  });

  React.useEffect(() => {
    if (!schemas) return;
    if (schemaField.value) return;

    const selected = schemas.find(isDefaultSchema);
    if (selected) {
      schemaField.onChange(selected);
    }
  }, [schemas, schemaField]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent shadow={shadows.level4}>
        <form onSubmit={handleSubmit(handleValidSubmit)}>
          <ModalHeader>Create a secret</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack pb={6} spacing="4">
              {failedToLoad ? (
                <ErrorBox />
              ) : (
                <>
                  {generalFormError && (
                    <Alert variant="error" message={generalFormError} />
                  )}
                  <FormControl isInvalid={!!formState.errors.name}>
                    <FormLabel fontSize="sm">Name</FormLabel>
                    <ObjectNameInput
                      {...register(NAME_FIELD, {
                        required: "Name is required.",
                        pattern: {
                          value: MATERIALIZE_DATABASE_IDENTIFIER_REGEX,
                          message: "Name must not include special characters",
                        },
                      })}
                      placeholder="confluent_password"
                      autoFocus={isOpen}
                      autoCorrect="off"
                      size="sm"
                      variant={formState.errors.name ? "error" : "default"}
                    />
                    {!formState.errors.name && (
                      <FormHelperText>
                        Alphanumeric characters and underscores only.
                      </FormHelperText>
                    )}
                    <FormErrorMessage>
                      {formState.errors.name?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formState.errors.schema}>
                    <FormLabel>Schema</FormLabel>
                    <SchemaSelect
                      {...schemaField}
                      schemas={schemas ?? []}
                      variant={formState.errors.schema ? "error" : "default"}
                    />
                    <FormErrorMessage>
                      {formState.errors.schema?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!formState.errors.value}>
                    <FormLabel fontSize="sm">Value</FormLabel>
                    <Textarea
                      {...register(VALUE_FIELD, {
                        required: "Value is required.",
                      })}
                      autoCorrect="off"
                      size="sm"
                    />
                    <FormErrorMessage>
                      {formState.errors.value?.message}
                    </FormErrorMessage>
                  </FormControl>
                </>
              )}
            </VStack>
          </ModalBody>

          {!failedToLoad && (
            <ModalFooter>
              <HStack spacing="2">
                <Button variant="secondary" size="sm" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="sm"
                  isDisabled={isCreationInFlight}
                  onClick={() => track("Create Secret Clicked")}
                >
                  Create secret
                </Button>
              </HStack>
            </ModalFooter>
          )}
        </form>
      </ModalContent>
    </Modal>
  );
};

export default NewSecretModal;
