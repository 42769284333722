import {
  Button,
  FormControl,
  Input,
  Spinner,
  Text,
  useTimeout,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import Alert from "~/components/Alert";
import { AppErrorBoundary } from "~/components/AppErrorBoundary";
import { LabeledInput } from "~/components/formComponentsV2";
import { LoadingContainer } from "~/components/LoadingContainer";
import { AuthContentContainer, AuthLayout } from "~/layouts/AuthLayout";
import { useActivateAccount, useActivateStrategy } from "~/queries/frontegg";

import { PasswordField } from "./PasswordField";
import { validatePassword } from "./utils";

interface ActivateAccountFormState {
  password: string;
  confirmPassword: string;
}

export const ActivateAccount = () => {
  const [params] = useSearchParams();
  const token = params.get("token");
  const userId = params.get("userId");

  if (!userId || !token) {
    return (
      <AuthLayout>
        <AccountActivationError />
      </AuthLayout>
    );
  }
  return (
    <AuthLayout>
      <AppErrorBoundary message="An error occurred activating your account">
        <ActivateAccountInner token={token} userId={userId} />
      </AppErrorBoundary>
    </AuthLayout>
  );
};

export const ActivateAccountInner = ({
  token,
  userId,
}: {
  token: string;
  userId: string;
}) => {
  const { formState, handleSubmit, register } =
    useForm<ActivateAccountFormState>({
      criteriaMode: "all",
      defaultValues: {
        password: "",
        confirmPassword: "",
      },
      mode: "onTouched",
    });
  const { error: activationStrategyError, data: activationStrategy } =
    useActivateStrategy({ token, userId });
  const {
    isError: isActivationError,
    isPending: isActivationPending,
    isSuccess: isActivationSuccess,
    mutate: activateAccount,
  } = useActivateAccount();

  React.useEffect(() => {
    if (
      "shouldSetPassword" in activationStrategy &&
      !activationStrategy.shouldSetPassword
    ) {
      activateAccount({ token, userId });
    }
  }, [activateAccount, activationStrategy, token, userId]);

  const handleValidSubmit = (values: ActivateAccountFormState) => {
    activateAccount({ token, userId, password: values.password });
  };

  if (isActivationSuccess) {
    return <AccountActivationSuccess />;
  }
  if (activationStrategyError) {
    return <AccountActivationError />;
  }
  if ("errors" in activationStrategy) {
    return <AccountActivationError message={activationStrategy.errors[0]} />;
  }
  if (!activationStrategy.shouldSetPassword) {
    return (
      <VStack spacing="8">
        <Text textStyle="heading-xs">
          Activating your account, please wait...
        </Text>
        <LoadingContainer />
      </VStack>
    );
  }
  return (
    <AuthContentContainer title="Activate account">
      <form onSubmit={handleSubmit(handleValidSubmit)}>
        <VStack spacing="6" alignItems="start">
          {isActivationError && (
            <Alert
              variant="error"
              message="Account activation failed."
              width="100%"
            />
          )}
          <PasswordField
            errors={formState.errors.password}
            inputProps={register("password", {
              validate: validatePassword(),
            })}
          />
          <FormControl isInvalid={!!formState.errors.confirmPassword}>
            <LabeledInput
              label="Confirm password"
              error={formState.errors.confirmPassword?.message}
              inputBoxProps={{
                maxWidth: "100%",
              }}
            >
              <Input
                {...register("confirmPassword", {
                  required: "Confirm password is required.",
                  validate: {
                    match: (value, formValues) =>
                      formValues.password !== value
                        ? "Passwords must match."
                        : undefined,
                  },
                })}
                autoCorrect="off"
                placeholder="Re-enter password"
                size="lg"
                type="password"
                variant={formState.errors.confirmPassword ? "error" : "default"}
              />
            </LabeledInput>
          </FormControl>
          <Button
            alignSelf="flex-end"
            variant="primary"
            size="lg"
            type="submit"
            isLoading={isActivationPending || isActivationSuccess}
            spinner={<Spinner />}
          >
            Activate account
          </Button>
        </VStack>
      </form>
    </AuthContentContainer>
  );
};

const AccountActivationError = (props: { message?: string }) => (
  <Alert
    variant="error"
    message={
      props.message ||
      "Account activation failed. Please ask an admin to resend your activation email."
    }
  />
);

const AccountActivationSuccess = () => {
  const navigate = useNavigate();
  useTimeout(() => {
    navigate("/", { replace: true });
  }, 3000);

  return (
    <Alert
      variant="info"
      label="Account activated"
      message="Your account has been successfully activated, you will now be logged in."
    />
  );
};
