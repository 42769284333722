import { useCurrentOrganization } from "~/api/auth";
import useLocalStorage from "~/hooks/useLocalStorage";

export function useShowSystemObjects() {
  const { organization } = useCurrentOrganization();
  const [showSystemObjects, setShowSystemObjects] = useLocalStorage<boolean>(
    `${organization?.id}-show-system-objects`,
    false,
  );
  return [showSystemObjects, setShowSystemObjects];
}
