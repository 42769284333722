import {
  Box,
  BoxProps,
  Button,
  ChakraTheme,
  Code,
  GridItem,
  GridItemProps,
  HStack,
  IconButton,
  StackProps,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import React, { forwardRef, PropsWithChildren, useRef } from "react";
import { Link } from "react-router-dom";

import { useSegment } from "~/analytics/segment";
import type { User } from "~/api/frontegg/types";
import { TabbedCodeBlock } from "~/components/copyableComponents";
import TextLink from "~/components/TextLink";
import * as chilipiper from "~/marketing/chilipiper";
import docUrls from "~/mz-doc-urls.json";
import { newConnectionPath } from "~/platform/routeHelpers";
import { useCurrentUser } from "~/queries/frontegg";
import { useRegionSlug } from "~/store/environments";
import CommandIcon from "~/svg/CommandIcon";
import { MaterializeTheme, ThemeColors } from "~/theme";
import ColorsType from "~/theme/colors";

import { saveClearPrompt, setPromptValue } from "./store/prompt";
import { shellStateAtom } from "./store/shell";
import TutorialInsertionWidget from "./TutorialInsertionWidget";

/* Semantic version number used for instrumentation */
const QUICKSTART_VERSION = "1.0.0";

type RunnableProps = {
  runCommand: (value: string) => void;
  title: string;
  value: string;
};

const Runnable = ({ runCommand, value, title }: RunnableProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  const setPrompt = useAtomCallback(setPromptValue);
  const clearPrompt = useAtomCallback(saveClearPrompt);

  return (
    <TabbedCodeBlock
      width="100%"
      flexShrink="0"
      tabs={[{ title, contents: value }]}
      lineNumbers
      headingIcon={
        <IconButton
          aria-label="Run command button"
          title="Run command"
          onClick={() => {
            setPrompt(value);
            runCommand(value);
            clearPrompt();
          }}
          icon={<CommandIcon color={colors.foreground.secondary} />}
          variant="unstyled"
          rounded={0}
          sx={{
            _hover: {
              background: "rgba(255, 255, 255, 0.06)",
            },
          }}
        />
      }
    />
  );
};

const TextContainer = ({ children }: PropsWithChildren) => (
  <VStack spacing="4" alignItems="flex-start">
    {children}
  </VStack>
);

const RunnableContainer = ({
  children,
  ...rest
}: PropsWithChildren & BoxProps) => (
  <VStack spacing="6" {...rest}>
    {children}
  </VStack>
);

const StepLayout = forwardRef(
  ({ children, ...rest }: PropsWithChildren & BoxProps, ref) => (
    <VStack
      spacing="6"
      alignItems="stretch"
      overflow="auto"
      flexGrow="1"
      minHeight="0"
      paddingBottom="6"
      paddingX="10"
      ref={ref}
      {...rest}
    >
      {children}
    </VStack>
  ),
);

type StepProps = {
  runCommand: (value: string) => void;
  title: string;
  user: User | null;
  colors: ChakraTheme["colors"] & ThemeColors & typeof ColorsType;
};

const stepsData: Array<{
  title: string;
  render: (props: StepProps) => JSX.Element;
}> = [
  {
    title: "Ingest streaming data",
    render: ({ runCommand, title }) => (
      <>
        <TextContainer>
          <Text textStyle="heading-md">{title}</Text>
          <Text textStyle="text-base">
            You&apos;ll use a sample{" "}
            <TextLink
              target="_blank"
              href={`${docUrls["/docs/sql/create-source/load-generator/"]}#auction`}
            >
              auction house data set
            </TextLink>{" "}
            to build an operational use case around auctions, bidders and (gasp)
            fraud. 🕵️💰
          </Text>
        </TextContainer>
        <RunnableContainer>
          <Runnable
            runCommand={runCommand}
            value="CREATE SOURCE auction_house
  FROM LOAD GENERATOR AUCTION
  (TICK INTERVAL '1s', AS OF 100000)
  FOR ALL TABLES;"
            title="Create a load generator source"
          />
          <Text textStyle="text-base">
            Once you{" "}
            <TextLink
              target="_blank"
              href={docUrls["/docs/sql/create-source/"]}
            >
              create the source
            </TextLink>
            , data will be continually produced as you walk through the
            quickstart.
          </Text>
          <Runnable
            runCommand={runCommand}
            value="SHOW SOURCES;"
            title="What data is being generated?"
          />
        </RunnableContainer>
        <Text textStyle="text-base">
          For now, let&apos;s focus on the{" "}
          <Code variant="inline-syntax">auctions</Code>
          and <Code variant="inline-syntax">bids</Code> data sets: ongoing
          auctions where sellers put items up for bidding, and bids placed by
          buyers to win the auctioned items. Before moving on, get a sense for
          the data you&apos;ll be working with:
        </Text>
        <Runnable
          runCommand={runCommand}
          value="SELECT * FROM auctions LIMIT 1;"
          title="What does an auction record look like?"
        />
        <Runnable
          runCommand={runCommand}
          value="SELECT * FROM bids LIMIT 1;"
          title="What does a bid record look like?"
        />
      </>
    ),
  },
  {
    title: "Use indexes for speed",
    render: ({ runCommand, title }) => (
      <>
        <TextContainer>
          <Text textStyle="heading-md">{title}</Text>
          <Text textStyle="text-base">
            Lately, you’ve been struggling with{" "}
            <TextLink
              target="_blank"
              href="https://en.wikipedia.org/wiki/Flipping"
            >
              auction flippers
            </TextLink>
            : users that purchase items only to quickly resell them for profit.
            As the auction house operator, you want to detect this fraudulent
            behavior as soon as it happens.
          </Text>
          <Text textStyle="text-base">
            Let&apos;s dive into how{" "}
            <TextLink target="_blank" href={docUrls["/docs/sql/create-index/"]}>
              indexes
            </TextLink>{" "}
            can help get up-to-date results out of Materialize in milliseconds.
          </Text>
          <Runnable
            runCommand={runCommand}
            value={`CREATE VIEW winning_bids AS
  SELECT DISTINCT ON (auctions.id) bids.*,
    auctions.item,
    auctions.seller
  FROM auctions, bids
  WHERE auctions.id = bids.auction_id
  -- Where all bids occurred during the auction
    AND bids.bid_time < auctions.end_time
  -- Where all auctions have completed
    AND mz_now() >= auctions.end_time
  ORDER BY auctions.id,
    bids.amount DESC,
    bids.bid_time,
    bids.buyer;`}
            title="Create a view that tracks winning bids per auction"
          />
        </TextContainer>
        <Text textStyle="text-base">
          You can query the{" "}
          <TextLink target="_blank" href={docUrls["/docs/sql/create-view/"]}>
            view
          </TextLink>{" "}
          directly, but this won&apos;t be very impressive just yet! Querying
          the view re-executes the embedded statement, which has a cost that
          increases with your data volume.
        </Text>
        <Runnable
          runCommand={runCommand}
          value={`SELECT * FROM winning_bids
WHERE item = 'Best Pizza in Town'
ORDER BY bid_time DESC;`}
          title="Not very fast, is it?"
        />
      </>
    ),
  },
  {
    title: "Use indexes for speed",
    render: ({ runCommand, title }) => (
      <>
        <TextContainer>
          <Text textStyle="heading-md">{title}</Text>
          <Text textStyle="text-base">
            In Materialize, you use{" "}
            <TextLink target="_blank" href={docUrls["/docs/sql/create-index/"]}>
              indexes
            </TextLink>{" "}
            to keep results incrementally updated and immediately accessible.
            Try creating several indexes on the{" "}
            <Code variant="inline-syntax">winning_bids</Code> view using columns
            that can help optimize operations like point lookups and joins.
          </Text>
        </TextContainer>
        <RunnableContainer>
          <Runnable
            runCommand={runCommand}
            value="CREATE INDEX wins_by_item ON winning_bids (item);"
            title="Index winning_bids on the item column"
          />
          <Runnable
            runCommand={runCommand}
            value="CREATE INDEX wins_by_buyer ON winning_bids (buyer);"
            title="Index winning_bids on the buyer column"
          />
          <Runnable
            runCommand={runCommand}
            value="CREATE INDEX wins_by_seller ON winning_bids (seller);"
            title="Index winning_bids on the seller column"
          />
        </RunnableContainer>
        <Text textStyle="text-base">
          These indexes are now maintaining the results of{" "}
          <Code variant="inline-syntax">winning_bids</Code> in memory, and work
          like a self-updating cache. If you try to read out of{" "}
          <Code variant="inline-syntax">winning_bids</Code> while hitting one of
          these indexes (e.g., with a point lookup), things should be a whole
          lot more interactive!
        </Text>
        <Runnable
          runCommand={runCommand}
          value={`SELECT * FROM winning_bids
WHERE item = 'Best Pizza in Town'
ORDER BY bid_time DESC;`}
          title="Do a point lookup 💥"
        />
        <Text textStyle="text-base">
          That&apos;s fast, but...to detect and take action upon fraud, you
          can&apos;t rely on manual checks, right? You want to keep a running
          tab on these flippers.
        </Text>
      </>
    ),
  },
  {
    title: "Use indexes for speed",
    render: ({ runCommand, title }) => (
      <>
        <TextContainer>
          <Text textStyle="heading-md">{title}</Text>
          <Text textStyle="text-base">
            Luckily, the indexes you created in the previous step also make
            joins more interactive (like in other databases).
          </Text>
          <Text textStyle="text-base">
            To detect auction flippers, you need to keep track of cases where a
            user wins an auction as a bidder, and then is identified as a seller
            for an item at a higher price.
          </Text>
        </TextContainer>
        <Runnable
          runCommand={runCommand}
          value={`CREATE VIEW fraud_activity AS
  SELECT w2.seller,
         w2.item AS seller_item,
         w2.amount AS seller_amount,
         w1.item buyer_item,
         w1.amount buyer_amount
  FROM winning_bids w1,
       winning_bids w2
  -- Identified as a buyer and seller for any two auctions
  WHERE w1.buyer = w2.seller
  -- For the same item
    AND w1.item = w2.item
  -- Tries to sell at a higher price
    AND w2.amount > w1.amount;`}
          title="Create a view that detects auction flippers"
        />
        <Text textStyle="text-base">
          The indexes you created on the join keys (
          <Code variant="inline-syntax">buyer</Code>,
          <Code variant="inline-syntax">seller</Code>) are used to{" "}
          <TextLink
            target="_blank"
            href={`${docUrls["/docs/transform-data/optimization/"]}#join`}
          >
            optimize the performance of the join
          </TextLink>
          , and again allow Materialize to return results in milliseconds.
        </Text>
        <Text textStyle="text-base">
          Aha! You can now catch any auction flippers in real time, based on the
          results of this view.
        </Text>
        <Runnable
          runCommand={runCommand}
          value="SELECT * FROM fraud_activity LIMIT 100;"
          title="Who is flipping auctions?"
        />
      </>
    ),
  },
  {
    title: "See results change!",
    render: ({ runCommand, title }) => (
      <>
        <TextContainer>
          <Text textStyle="heading-md">{title}</Text>
          <Text textStyle="text-base">
            You&apos;ve seen how Materialize returns results fast using indexes.
            But how fresh are these results? Let&apos;s build confidence that
            Materialize reacts as soon as your data changes by flagging
            fraudulent accounts manually.
          </Text>
        </TextContainer>
        <Runnable
          runCommand={runCommand}
          value="CREATE TABLE fraud_accounts (id bigint);"
          title="Create a table for fraud flagging"
        />
        <Runnable
          runCommand={runCommand}
          value={`SUBSCRIBE TO (
  SELECT buyer, count(*)
  FROM winning_bids
  WHERE buyer NOT IN (SELECT id FROM fraud_accounts)
  GROUP BY buyer
  ORDER BY 2 DESC LIMIT 5
);`}
          title="Subscribe to see results change over time!"
        />
        <Text textStyle="text-base">
          You can keep an eye on the results, but these may not change much at
          the moment. You&apos;ll fix that next!
        </Text>
      </>
    ),
  },
  {
    title: "See results change!",
    render: ({ title }) => (
      <>
        <TextContainer>
          <Text textStyle="heading-md">{title}</Text>
          <Text textStyle="text-base">
            Pick one of the buyers from the list maintained by the{" "}
            <Code variant="inline-syntax">SUBSCRIBE</Code>, and mark them as
            fraudulent by adding their <Code variant="inline-syntax">id</Code>{" "}
            to the <Code variant="inline-syntax">frauds_account</Code> table.
          </Text>
          <RunnableContainer width="100%">
            <Box width="100%">
              <TutorialInsertionWidget />
            </Box>
            <Text textStyle="text-base">
              This should cause the flagged buyer to immediately drop out of the
              Top 5! If you click &quot;Show diffs&quot; above the table,
              you&apos;ll notice that the picked buyer was kicked out, and the
              next non-fraudulent buyer in line automatically entered the Top 5.
            </Text>
            <Text textStyle="text-base">
              When you&apos;re done, cancel out of the{" "}
              <Code variant="inline-syntax">SUBSCRIBE</Code> using the
              &quot;Stop streaming&quot; button, and click &quot;Continue&quot;
              below.
            </Text>
          </RunnableContainer>
        </TextContainer>
      </>
    ),
  },
  {
    title: "Serve correct results",
    render: ({ runCommand, title }) => (
      <>
        <TextContainer>
          <Text textStyle="heading-md">{title}</Text>
          <Text textStyle="text-base">
            With fraud out of the way, let&apos;s shift the focus to a different
            operational use case: profit & loss alerts.
          </Text>
          <Text textStyle="text-base">
            As the auction house operator, you want to alert users when
            they&apos;ve spent more money than they&apos;ve earned. For that,
            you need to trust that the results maintained in Materialize are
            correct and consistent — it&apos;s real money we&apos;re talking
            about, after all!
          </Text>
        </TextContainer>
        <Runnable
          runCommand={runCommand}
          value={`CREATE VIEW funds_movement AS
  SELECT id,
         SUM(credits) AS credits,
         SUM(debits) AS debits
  FROM (
    SELECT seller AS id, amount AS credits, 0 AS debits
    FROM winning_bids
    UNION ALL
    SELECT buyer AS id, 0 AS credits, amount AS debits
    FROM winning_bids
  )
  GROUP BY id;`}
          title="Create a view that tracks purchases & sales per user"
        />
        <Text textStyle="text-base">
          To verify that the results are trustworthy, you can write a diagnostic
          query that checks that the total seller credit and total buyer debit
          amounts always add up.
        </Text>
        <Runnable
          runCommand={runCommand}
          value={`SELECT SUM(credits),
       SUM(debits)
FROM funds_movement;`}
          title="Create a view that shows that auction house dollar$ add up!"
        />
        <TextContainer>
          <Text textStyle="text-base">
            You can also <Code variant="inline-syntax">SUBSCRIBE</Code> to this
            query, and watch the sums change in lockstep as auctions close.
          </Text>
          <Runnable
            runCommand={runCommand}
            value={`SUBSCRIBE TO (
  SELECT SUM(credits),
         SUM(debits)
  FROM funds_movement
);`}
            title="Subscribe to see results change over time!"
          />
        </TextContainer>
      </>
    ),
  },
  {
    title: "Cleaning up",
    render: ({ runCommand, title }) => (
      <>
        <TextContainer>
          <Text textStyle="heading-md">{title}</Text>
          <Text textStyle="text-base">
            Once you&apos;re done exploring the{" "}
            <TextLink
              target="_blank"
              href={`${docUrls["/docs/sql/create-source/load-generator/"]}#auction`}
            >
              auction house data set
            </TextLink>
            , remember to clean up your environment:
          </Text>
        </TextContainer>

        <RunnableContainer>
          <Runnable
            runCommand={runCommand}
            value="DROP SOURCE auction_house CASCADE;"
            title="Drop source & dependent objects"
          />
          <Runnable
            runCommand={runCommand}
            value="DROP TABLE fraud_accounts;"
            title="Drop table"
          />
        </RunnableContainer>
        <TextContainer>
          <Text textStyle="heading-md" width="100%">
            What&apos;s next?
          </Text>
          <Text textStyle="text-base" width="100%">
            Now that you&apos;ve got the basics down, you&apos;re ready to start
            developing with your own data! Or, if you want to learn more, get in
            touch.
          </Text>
        </TextContainer>
      </>
    ),
  },
];

/**
 * Since many steps have the same title, this gets the xth step of a given title
 */
const getLocalTutorialStepByTitle = (
  globalTutorialStep: number,
  title: string,
): number => {
  const startIndexByTitle = stepsData.reduce<{ [title: string]: number }>(
    (accum, step, index) => {
      if (accum[step.title] === undefined) {
        accum[step.title] = index;
      }
      return accum;
    },
    {},
  );

  if (Object.keys(startIndexByTitle).length === 0) {
    return 0;
  }

  return globalTutorialStep - startIndexByTitle[title];
};

const Steps = forwardRef(
  (
    {
      runCommand,
      onChangeStep,
    }: {
      runCommand: (command: string) => void;
      onChangeStep: (desired: number) => void;
    },
    ref,
  ) => {
    const [shellState] = useAtom(shellStateAtom);
    const { currentTutorialStep } = shellState;
    const { colors } = useTheme<MaterializeTheme>();
    const { data: user } = useCurrentUser();
    const regionSlug = useRegionSlug();

    const atStart = currentTutorialStep === 0;

    const atEnd = currentTutorialStep >= stepsData.length - 1;

    const step = stepsData[currentTutorialStep];

    return (
      <StepLayout width="100%" ref={ref}>
        <step.render
          runCommand={runCommand}
          title={step.title}
          user={user}
          colors={colors}
        />
        <HStack
          width="100%"
          alignSelf="flex-end"
          alignItems="space-between"
          justifyContent={atStart ? "flex-end" : "space-between"}
          marginTop="4"
        >
          {!atStart && (
            <Button
              onClick={() => onChangeStep(currentTutorialStep - 1)}
              variant="tertiary"
            >
              Back
            </Button>
          )}
          {atEnd ? (
            <HStack gap={4}>
              <Button
                variant="primary"
                onClick={() => {
                  chilipiper.submit({
                    name: user.name,
                    email: user.email,
                    meetingType: "guided-trial",
                    organizationId: user.tenantId,
                  });
                }}
              >
                Talk to us
              </Button>
              <Button
                as={Link}
                variant="primary"
                to={`${newConnectionPath(regionSlug)}`}
              >
                Connect data
              </Button>
            </HStack>
          ) : (
            <Button
              onClick={() => onChangeStep(currentTutorialStep + 1)}
              variant="primary"
            >
              Continue
            </Button>
          )}
        </HStack>
      </StepLayout>
    );
  },
);

type ProgressProps = {
  min: number;
  max: number;
  value: number;
  onStepClick: (step: number) => void;
} & StackProps;

const PROGRESS_STEP_SPACING = 1;

const Progress = ({ min, max, value, onStepClick, ...rest }: ProgressProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  const steps: boolean[] = [];

  for (let i = min; i < max; i++) {
    steps.push(i <= value);
  }

  return (
    <HStack {...rest} spacing={PROGRESS_STEP_SPACING}>
      {steps.map((filled, idx) => {
        return (
          <Box
            key={idx}
            flexGrow="1"
            height="1"
            borderRadius="2px"
            transition="all 0.2s"
            bgColor={filled ? colors.accent.purple : colors.background.tertiary}
            onClick={() => onStepClick(idx)}
            cursor={value !== idx ? "pointer" : "auto"}
            title={value !== idx ? `Jump to step ${idx + 1}` : ""}
            sx={{
              _hover: {
                backgroundColor: colors.accent.purple,
                boxShadow:
                  value !== idx
                    ? `0px 0px 3px 1px ${colors.accent.purple}`
                    : "none",
              },
            }}
          />
        );
      })}
    </HStack>
  );
};

type TutorialProps = GridItemProps & {
  runCommand: (command: string) => void;
};

const Tutorial = ({ runCommand, ...rest }: TutorialProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  const [shellState, setShellState] = useAtom(shellStateAtom);
  const { currentTutorialStep: step } = shellState;
  const { track } = useSegment();
  const stepsScrollContainerRef = useRef<HTMLDivElement>(null);

  const changeStep = (desired: number) => {
    if (desired < 0) {
      desired = 0;
    } else if (desired >= stepsData.length) {
      desired = stepsData.length - 1;
    }
    const prevStep = step;

    setShellState((prev) => ({ ...prev, currentTutorialStep: desired }));

    const title = stepsData[desired].title;

    track("Tutorial change page", {
      pageHeader: title,
      pageNumber: desired,
      sectionPageNumber: getLocalTutorialStepByTitle(desired, title),
      quickstartVersion: QUICKSTART_VERSION,
    });

    const isChangingToSecondStep = prevStep === 0 && desired === 1;
    if (isChangingToSecondStep) {
      // If a user goes from the first page to the next page, we use this as a
      // signal that the user has intended to start the quickstart
      track("Quickstart Start", {
        quickstartVersion: QUICKSTART_VERSION,
      });
    }

    const isChangingToLastStep =
      prevStep === stepsData.length - 2 && desired === stepsData.length - 1;
    if (isChangingToLastStep) {
      // If a user goes from the second last page to the last page, we use this
      // as a signal that the user has completed the quickstart.
      track("Quickstart End", {
        quickstartVersion: QUICKSTART_VERSION,
      });
    }

    stepsScrollContainerRef.current?.scrollTo({ top: 0 });
  };

  return (
    <GridItem
      area="tutorial"
      borderLeftWidth="1px"
      borderColor={colors.border.secondary}
      bg={colors.background.shellTutorial}
      borderBottomRightRadius="lg"
      display="flex"
      flexDirection="column"
      {...rest}
    >
      <VStack
        paddingTop="6"
        spacing="0"
        alignItems="flex-start"
        minHeight="0"
        flex="1"
      >
        <VStack spacing="6" alignItems="flex-start" width="100%" paddingX="10">
          <Progress
            min={0}
            max={stepsData.length}
            value={step}
            width="100%"
            onStepClick={changeStep}
          />
          <Text
            textStyle="text-small"
            fontWeight="500"
            color={colors.foreground.secondary}
            marginBottom="4"
          >
            QUICKSTART
          </Text>
        </VStack>
        <Steps
          runCommand={runCommand}
          onChangeStep={changeStep}
          ref={stepsScrollContainerRef}
        />
      </VStack>
    </GridItem>
  );
};

export default Tutorial;
