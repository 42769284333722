import { useFlags } from "launchdarkly-react-client-sdk";

import { SchemaObject } from "~/api/materialize";
import config from "~/config";
import { relativeObjectPath } from "~/platform/object-explorer/routerHelpers";
import { useRegionSlug } from "~/store/environments";

import { NULL_DATABASE_NAME } from "./object-explorer/constants";
import { relativeObjectPath as legacyRelativeObjectPath } from "./schemaObjectRouteHelpers";

export type RoutableObjectType =
  | "source"
  | "sink"
  | "index"
  | "materialized-view";

export const signupPath = config.externalRegistration
  ? "https://materialize.com/register"
  : "/account/sign-up";

export const loginPath = "/account/login";

export const logoutPath = "/account/logout";

export const regionPath = (regionSlug: string) => {
  return `/regions/${regionSlug}`;
};

export const absoluteSubsourcePath = (
  regionSlug: string,
  subsourceId: string,
) => {
  return `${regionPath(regionSlug)}/sources/subsources/${subsourceId}/workflow`;
};

export const legacySourcePath = (regionSlug: string, source: SchemaObject) => {
  return `${regionPath(regionSlug)}/sources/${legacyRelativeObjectPath(source)}`;
};

export type ClusterPathParams = {
  id: string;
  name: string;
};

export const absoluteClusterPath = (
  regionSlug: string,
  cluster: ClusterPathParams,
) => `${regionPath(regionSlug)}/clusters/${relativeClusterPath(cluster)}`;

export const relativeClusterPath = (cluster: ClusterPathParams) =>
  `${cluster.id}/${encodeURIComponent(cluster.name)}`;

/**
 * Function to switch to another cluster, while maintaining the rest of the route.
 */
export function replaceClusterIdAndName({
  pathname,
  currentClusterId,
  currentClusterName,
  targetCluster,
}: {
  pathname: string;
  currentClusterId: string;
  currentClusterName: string;
  targetCluster: ClusterPathParams;
}) {
  const toReplace = `${currentClusterId}/${currentClusterName}`;
  const replacement = `${targetCluster.id}/${targetCluster.name}`;
  return pathname.replace(toReplace, replacement);
}

export const ENVIRONMENT_NOT_READY_SLUG = "environment-not-ready";

export const environmentNotReadyPath = `/${ENVIRONMENT_NOT_READY_SLUG}`;

export const legacyRelativeWorkflowGraphPath = (schemaObject: SchemaObject) =>
  `${legacyRelativeObjectPath(schemaObject)}/workflow`;

export const legacyRelativeIndexPath = (index: SchemaObject) =>
  legacyRelativeWorkflowGraphPath(index);

export const legacyRelativeDataflowVisualizerPath = (index: SchemaObject) =>
  `${legacyRelativeObjectPath(index)}/dataflow-visualizer`;

export const legacyRelativeMaterializedViewPath = (
  materializedView: SchemaObject,
) => legacyRelativeWorkflowGraphPath(materializedView);

export const SHELL_SLUG = "shell";

export const shellPath = (regionSlug: string) => {
  return `${regionPath(regionSlug)}/${SHELL_SLUG}`;
};

export { shellPath as homePagePath };

export interface ObjectPathParams {
  databaseName: string | null;
  schemaName: string;
  objectType: string;
  objectName: string;
  id: string;
}

export function objectExplorerObjectPath(
  regionSlug: string,
  params: ObjectPathParams,
) {
  return `${regionPath(regionSlug)}/objects/${relativeObjectPath(params)}`;
}

export function useBuildObjectPath() {
  const regionSlug = useRegionSlug();
  const flags = useFlags();
  if (flags["object-explorer-1820"]) {
    return (params: ObjectPathParams, _basePath?: string) =>
      objectExplorerObjectPath(regionSlug, params);
  }

  return (params: ObjectPathParams, basePath?: string) =>
    basePath +
    legacyRelativeObjectPath({
      ...params,
      name: params.objectName,
      databaseName: params.databaseName ?? NULL_DATABASE_NAME,
    });
}

export interface ObjectWithoutTypePathParams {
  databaseName: string | null;
  schemaName: string;
  name: string;
  id: string;
}

export function useBuildMaterializedViewPath() {
  const regionSlug = useRegionSlug();
  const flags = useFlags();

  if (flags["object-explorer-1820"]) {
    return (params: ObjectWithoutTypePathParams) =>
      `${regionPath(regionSlug)}/objects/${relativeObjectPath({
        ...params,
        objectName: params.name,
        objectType: "materialized-view",
      })}`;
  }
  return (params: ObjectWithoutTypePathParams) =>
    legacyRelativeMaterializedViewPath(params);
}

export function useBuildIndexPath() {
  const regionSlug = useRegionSlug();
  const flags = useFlags();

  if (flags["object-explorer-1820"]) {
    return (params: ObjectWithoutTypePathParams) =>
      objectExplorerObjectPath(regionSlug, {
        ...params,
        objectName: params.name,
        objectType: "index",
      });
  }
  return (params: ObjectWithoutTypePathParams) =>
    `${regionPath(regionSlug)}/${legacyRelativeIndexPath(params)}`;
}

export function useBuildSinkPath() {
  const regionSlug = useRegionSlug();
  const build = useBuildObjectPath();

  return (params: ObjectWithoutTypePathParams) =>
    build(
      { ...params, objectName: params.name, objectType: "sink" },
      `${regionPath(regionSlug)}/sinks/`,
    );
}

export function useBuildSourcePath() {
  const regionSlug = useRegionSlug();
  const build = useBuildObjectPath();

  return (params: ObjectWithoutTypePathParams) =>
    build(
      { ...params, objectName: params.name, objectType: "source" },
      `${regionPath(regionSlug)}/sources/`,
    );
}

export interface WorkflowGraphPathParams {
  type: string;
  databaseObject: SchemaObject;
  clusterId?: string | null;
  clusterName?: string | null;
}

export function useBuildWorkflowGraphPath() {
  const regionSlug = useRegionSlug();
  const flags = useFlags();

  if (flags["object-explorer-1820"]) {
    return (params: WorkflowGraphPathParams) =>
      `${objectExplorerObjectPath(regionSlug, {
        ...params.databaseObject,
        objectName: params.databaseObject.name,
        objectType: params.type,
      })}/workflow`;
  }
  return ({
    type,
    databaseObject,
    clusterId,
    clusterName,
  }: WorkflowGraphPathParams) => {
    const cluster =
      clusterId && clusterName ? { id: clusterId, name: clusterName } : null;
    if (type === "source") {
      return `${legacySourcePath(regionSlug, databaseObject)}/workflow`;
    }

    if (type === "index" && cluster) {
      return `${absoluteClusterPath(regionSlug, cluster)}/indexes/${legacyRelativeIndexPath(databaseObject)}`;
    }

    if (type === "materialized-view" && cluster) {
      return `${absoluteClusterPath(regionSlug, cluster)}/materialized-views/${legacyRelativeMaterializedViewPath(databaseObject)}`;
    }
    if (type === "subsource") {
      return `${absoluteSubsourcePath(regionSlug, databaseObject.id)}`;
    }
  };
}

export const relativeQueryHistoryPath = (executionId: string) =>
  `${executionId}`;

export const newConnectionPath = (regionSlug: string) => {
  return `${regionPath(regionSlug)}/sources/new/connection`;
};
