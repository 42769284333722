import React from "react";
import { Navigate, Route } from "react-router-dom";

import ErrorBox from "~/components/ErrorBox";
import { LoadingContainer } from "~/components/LoadingContainer";
import { SentryRoutes } from "~/sentry";
import { useAllObjects } from "~/store/allObjects";

import { ConnectionDetailRoutes } from "./ConnectionDetailRoutes";
import { DatabaseDetails } from "./DatabaseDetails";
import { SchemaDetails } from "./SchemaDetails";
import { SimpleObjectDetailRoutes } from "./SimpleObjectDetailRoutes";
import { SinkDetailRoutes } from "./SinkDetailRoutes";
import { SourceDetailRoutes } from "./SourceDetailRoutes";

export const ObjectExplorerDetailRoutes = () => {
  const { snapshotComplete: objectSnapshotComplete, isError: isObjectError } =
    useAllObjects();
  const objectPath = ":objectName/:id/*";

  if (isObjectError) {
    return <ErrorBox />;
  }
  if (!objectSnapshotComplete) {
    return <LoadingContainer />;
  }

  return (
    <SentryRoutes>
      <Route path=":databaseName" element={<DatabaseDetails />} />
      <Route
        path=":databaseName/schemas/:schemaName/*"
        element={
          <SentryRoutes>
            <Route path="" element={<SchemaDetails />} />
            <Route
              path={`sinks/${objectPath}`}
              element={<SinkDetailRoutes />}
            />
            <Route
              path={`sources/${objectPath}`}
              element={<SourceDetailRoutes />}
            />
            <Route
              path={`connections/${objectPath}`}
              element={<ConnectionDetailRoutes />}
            />
            {/* Minimal catch-all for simple objects */}
            <Route
              path={`:objectType/${objectPath}`}
              element={<SimpleObjectDetailRoutes />}
            />
            <Route path="*" element={<Navigate to="../" replace />} />
          </SentryRoutes>
        }
      />
      <Route path="*" element={<Navigate to="../" replace />} />
    </SentryRoutes>
  );
};
