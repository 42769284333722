import React from "react";
import { Navigate } from "react-router-dom";

import config from "~/config";

export const RedirectIfImpersonating = ({
  children,
}: React.PropsWithChildren) => {
  if (config.environmentdOverride) {
    return <Navigate to="/" replace />;
  } else {
    return <>{children}</>;
  }
};
