import { IPostgresInterval } from "postgres-interval";

import {
  LagInfo,
  LagStatus,
} from "~/api/materialize/cluster/materializationLag";
import { ConnectorStatus } from "~/api/materialize/types";
import { WorkflowGraphNode } from "~/api/materialize/useWorkflowGraphNodes";
import colors from "~/theme/colors";
import { kebabToTitleCase } from "~/util";

/**
 * Sums up the total number of hours from a posgres interval.
 */
export function calculateHoursFromInterval(interval: IPostgresInterval) {
  const years = interval?.years ?? 0;
  const months = interval?.months ?? 0;
  const days = interval?.days ?? 0;
  let hours = interval?.hours ?? 0;
  if (years >= 1) hours += years * 365 * 24;
  if (months >= 1) hours += months * 30 * 24;
  if (days >= 1) hours += days * 24;
  return hours;
}
/**
 * Given a postgres interval, formats the duration in the largest units possible,
 * hours, days or seconds. The value is floored, not rounded.
 *
 * e.g. `20h` or `12m` or `42s`
 */
export function formatLagSimple(
  lag: IPostgresInterval | null,
  suffix = "behind",
): string {
  if (!lag) return "-";

  const hours = calculateHoursFromInterval(lag);
  const minutes = lag?.minutes ?? 0;
  const seconds = lag?.seconds ?? 0;
  let formattedString = "";

  if (hours >= 1) {
    formattedString = `${hours}h`;
  } else if (minutes >= 1) {
    formattedString = `${minutes}m`;
  } else if (seconds >= 1) {
    formattedString = `${seconds}s`;
  } else if (formattedString === "") {
    formattedString = "Less than 1s";
  }
  return `${formattedString.trim()} ${suffix}`;
}

/**
 * Given a postgres interval, formats the duration as hours minutes and seconds.
 *
 * e.g. `20h 12m 42s`
 */
export function formatLagDetailed(
  lag: IPostgresInterval | null,
  suffix = "behind",
): string {
  if (!lag) return "-";

  const hours = calculateHoursFromInterval(lag);
  const minutes = lag?.minutes ?? 0;
  const seconds = lag?.seconds ?? 0;
  let formattedString = "";

  if (hours > 0) {
    formattedString += `${hours}h `;
  }
  if (minutes > 0) {
    formattedString += `${minutes}m `;
  }
  if (seconds >= 1) {
    formattedString += `${seconds}s`;
  }
  if (formattedString === "") {
    formattedString = "Less than 1s";
  }

  return `${formattedString.trim()} ${suffix}`;
}

export function formatLagFromMzNow(lagFromMzNow: IPostgresInterval) {
  /**
   *  \u2265 is ≥. We prefix ≥ since the lag from mz now is always approximate given frontiers from mz_materialization_lag
   * aren't guaranteed to be fresh.
   */
  return `\u2265${formatLagSimple(lagFromMzNow)}`;
}

export function formatTableLagInfo(lagInfo: LagInfo | null | undefined) {
  if (!lagInfo) {
    return "-";
  }
  const { globalLag, lagStatus } = lagInfo;
  if (lagStatus === "NOT_HYDRATED") {
    return "Not hydrated";
  }

  return formatLagSimple(globalLag);
}

export const sourceStatusToColor = (status: ConnectorStatus): string => {
  switch (status) {
    case "created":
    case "starting":
    case "paused":
    case "stalled":
      return colors.yellow[500];
    case "failed":
    case "dropped":
      return colors.orange[350];
    case "running":
      return colors.green[450];
  }
};

export const humanizeSourceStatus = (status: ConnectorStatus): string => {
  switch (status) {
    case "created":
      return "Created";
    case "starting":
      return "Starting";
    case "paused":
      return "Paused";
    case "stalled":
      return "Stalled";
    case "failed":
      return "Failed";
    case "dropped":
      return "Dropped";
    case "running":
      return "Running";
  }
};

export const LAG_STATUS_TO_FORMAT: Record<
  LagStatus,
  { text: string; pillColor: string; nodeColor: string }
> = {
  WAITING: {
    text: "Waiting",
    pillColor: colors.yellow[500],
    nodeColor: colors.yellow[500],
  },
  LAGGING: {
    text: "Lagging",
    pillColor: colors.orange[350],
    nodeColor: colors.orange[350],
  },
  NOT_HYDRATED: {
    text: "Not hydrated",
    pillColor: colors.yellow[500],
    nodeColor: colors.yellow[500],
  },
  UP_TO_DATE: {
    text: "Up to date",
    pillColor: colors.green[500],
    nodeColor: colors.green[450],
  },
};

export function formatObjectType(node: WorkflowGraphNode) {
  if (node.sourceType === "subsource") {
    return "Subsource";
  }
  if (node.sourceType) {
    return `${kebabToTitleCase(node.sourceType)} Source`;
  }
  return kebabToTitleCase(node.type);
}
