import React from "react";
import { Route } from "react-router-dom";

import { TablesList } from "~/platform/tables/TablesList";
import { SentryRoutes } from "~/sentry";

export const TableRoutes = () => {
  return (
    <SentryRoutes>
      <Route path="/*" element={<ShowTableRoutes />} />
    </SentryRoutes>
  );
};

const ShowTableRoutes = () => {
  return (
    <>
      <SentryRoutes>
        <Route path="/" element={<TablesList />} />
      </SentryRoutes>
    </>
  );
};
