import { useColorMode } from "@chakra-ui/color-mode";
import { FronteggProvider, LocalizationOverrides } from "@frontegg/react";
import React from "react";

import { trackSignupInHubspot } from "~/analytics/hubspot";
import LoadingScreen from "~/components/LoadingScreen";
import { AUTH_ROUTES } from "~/fronteggRoutes";
import { fronteggQueryKeys } from "~/queries/frontegg";
import { queryClient } from "~/queryClient";
import { fronteggAuthPageBackground, getFronteggTheme } from "~/theme";
import { fronteggLoginDisabled } from "~/utils/frontegg";

type Props = {
  baseUrl: string;
  children: () => React.ReactNode;
};

const events = {
  signUpComplete: trackSignupInHubspot,
};

const FronteggProviderWrapper = ({ baseUrl, children }: Props) => {
  const [loading, setLoading] = React.useState(true);
  const { colorMode } = useColorMode();
  const theme = React.useMemo(() => {
    return getFronteggTheme(colorMode);
  }, [colorMode]);
  const localizations: LocalizationOverrides = {
    loginBox: {
      forgetPassword: {
        submitButtonText: "Reset password",
      },
    },
  };

  if (fronteggLoginDisabled()) {
    return children();
  }

  return (
    <>
      {loading && <LoadingScreen />}
      <FronteggProvider
        localizations={{ en: localizations }}
        contextOptions={{ baseUrl }}
        events={events}
        backgroundImage={fronteggAuthPageBackground}
        themeOptions={theme}
        customLoader={(isLoading) => {
          if (!isLoading) {
            // If we just logged in, there are likely failed frontegg queries that will
            // cause trigger error states if we don't remove them. Invalidating them isn't
            // enough, since the stale value will still be used.
            queryClient.removeQueries({
              queryKey: fronteggQueryKeys.all(),
            });
          }
          setLoading(isLoading);
        }}
        authOptions={{
          routes: {
            loginUrl: AUTH_ROUTES.loginPath,
            logoutUrl: AUTH_ROUTES.logoutPath,
          },
          keepSessionAlive: true,
          enforceRedirectToSameSite: true,
        }}
      >
        {!loading && children()}
      </FronteggProvider>
    </>
  );
};

export default FronteggProviderWrapper;
