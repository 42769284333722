import { AxisScale } from "@visx/axis";
import { ScaleInput } from "@visx/scale";
import { bisector } from "d3";

// This function was copied and then modified from the visx XYchart:
// https://github.com/airbnb/visx/blob/6edbde496fc461094f6b3c60e8ec86c4b720b4f5/packages/visx-xychart/src/utils/findNearestDatumSingleDimension.ts#L7
export function findNearestDatum<
  Scale extends AxisScale,
  Datum extends object,
>({
  scale,
  accessor,
  scaledValue,
  data,
}: {
  scale: Scale;
  accessor: (d: Datum) => ScaleInput<Scale>;
  scaledValue: number;
  data: Datum[];
}) {
  if (data.length === 0) return null;

  const coercedScale = scale as AxisScale; // broaden type before type guards below

  let nearestDatum: Datum;
  let nearestDatumIndex: number;
  let dataValue: number;
  // if scale has .invert(), convert svg coord to nearest data value
  if ("invert" in coercedScale && typeof coercedScale.invert === "function") {
    const bisect = bisector(accessor).left;
    // find closest data value, then map that to closest datum
    dataValue = Number(coercedScale.invert(scaledValue));
    const index = bisect(data, dataValue);
    // take the two datum nearest this index, and compute which is closer
    const nearestDatum0 = data[index - 1];
    const nearestDatum1 = data[index];
    // Handle a single datapoint
    if (!nearestDatum0) {
      nearestDatum = nearestDatum1;
    } else if (!nearestDatum1) {
      nearestDatum = nearestDatum0;
    } else {
      nearestDatum =
        Math.abs(dataValue - accessor(nearestDatum0)) >
        Math.abs(dataValue - accessor(nearestDatum1))
          ? nearestDatum1
          : nearestDatum0;
    }
    nearestDatumIndex = nearestDatum === nearestDatum0 ? index - 1 : index;
  } else {
    console.warn(
      "[findNearestDatum] encountered incompatible scale type, bailing",
    );
    return null;
  }

  if (nearestDatum == null || nearestDatumIndex == null) return null;

  const distance = Math.abs(Number(accessor(nearestDatum)) - dataValue);

  return { datum: nearestDatum, index: nearestDatumIndex, distance };
}
