import {
  buildGlobalQueryKey,
  buildQueryKeyPart,
} from "~/api/buildQueryKeySchema";
import { getCloudRegions } from "~/api/cloudGlobalApi";
import { queryClient } from "~/queryClient";

export const cloudGlobalApiQueryKeys = {
  all: () => buildGlobalQueryKey("cloudGlobalApi"),
  cloudRegions: () =>
    [
      ...cloudGlobalApiQueryKeys.all(),
      buildQueryKeyPart("cloudRegions"),
    ] as const,
};

export function fetchCloudRegions() {
  return queryClient.fetchQuery({
    queryKey: cloudGlobalApiQueryKeys.cloudRegions(),
    staleTime: Infinity,
    gcTime: Infinity,
    queryFn: async ({ signal }) => {
      const response = await getCloudRegions({ signal });
      return response.data;
    },
  });
}
