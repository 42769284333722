export function getBuildConstants() {
  return {
    basename: __BASENAME__,
    defaultStack: __DEFAULT_STACK__,
    forceOverrideStack: __FORCE_OVERRIDE_STACK__,
    envdHost: __ENVD_HOST__,
    impersonationHostname: __IMPERSONATION_HOSTNAME__,
    sentryEnabled: __SENTRY_ENABLED__ === "true",
    sentryRelease: __SENTRY_RELEASE__,
  };
}

export type BuildConstants = ReturnType<typeof getBuildConstants>;
