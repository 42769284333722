import { ColorModeProvider, ColorModeScript } from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider as JotaiProvider } from "jotai";
import React from "react";
import { BrowserRouter } from "react-router-dom";

import { AppErrorBoundary } from "~/components/AppErrorBoundary";
import { ChakraProviderWrapper } from "~/components/ChakraProviderWrapper";
import { DevtoolsContainer } from "~/components/DevtoolsContainer";
import FronteggProviderWrapper from "~/components/FronteggProviderWrapper";
import { HoistFronteggToken } from "~/components/HoistFronteggToken";
import IncidentStatusWidget from "~/components/IncidentStatusWidget/IncidentStatusWidget";
import LoadingScreen from "~/components/LoadingScreen";
import config from "~/config";
import { getStore } from "~/jotai";
import { queryClient } from "~/queryClient";
import { config as themeConfig, initialColorMode } from "~/theme";
import { fronteggLoginDisabled } from "~/utils/frontegg";

import { UnauthenticatedRoutes } from "./UnauthenticatedRoutes";

export const App = () => {
  return (
    <BrowserRouter basename={config.basename}>
      <ColorModeScript initialColorMode={initialColorMode} />
      <ColorModeProvider options={themeConfig}>
        <ChakraProviderWrapper>
          <AppErrorBoundary containerProps={{ height: "100vh" }}>
            <DevtoolsContainer />
            <FronteggProviderWrapper baseUrl={config.fronteggUrl}>
              {() => (
                <JotaiProvider store={getStore()}>
                  <QueryClientProvider client={queryClient}>
                    <IncidentStatusWidget />
                    <AppErrorBoundary containerProps={{ height: "100vh" }}>
                      <HoistFronteggToken>
                        <React.Suspense fallback={<LoadingScreen />}>
                          <FronteggRoutes>
                            <UnauthenticatedRoutes />
                          </FronteggRoutes>
                        </React.Suspense>
                      </HoistFronteggToken>
                    </AppErrorBoundary>
                  </QueryClientProvider>
                </JotaiProvider>
              )}
            </FronteggProviderWrapper>
          </AppErrorBoundary>
        </ChakraProviderWrapper>
      </ColorModeProvider>
    </BrowserRouter>
  );
};

/**
 * Prevents our routes from rendering on auth routes so frontegg can handle them.
 */
const FronteggRoutes = (props: React.PropsWithChildren) => {
  if (fronteggLoginDisabled()) return props.children;

  if (location.pathname.startsWith("/account")) {
    // Paths that start with /account are handled by Frontegg. Our routes still render,
    // so we have to explicitly handle these cases, otherwise we end up with redirect
    // loops (https://github.com/MaterializeInc/cloud/issues/4351), and broken logouts
    // (https://github.com/MaterializeInc/cloud/issues/4351)
    return null;
  }

  return props.children;
};
