import React from "react";

import { useHoistFronteggAuth } from "~/api/fronteggToken";
import { fronteggLoginDisabled } from "~/utils/frontegg";

export const HoistFronteggToken = (props: React.PropsWithChildren) => {
  if (fronteggLoginDisabled()) {
    return props.children;
  }
  return <HoistFronteggTokenInner>{props.children}</HoistFronteggTokenInner>;
};

const HoistFronteggTokenInner = (props: React.PropsWithChildren) => {
  // This has to be run before any other code that attempts to fetch data, since it makes
  // the frontegg token available in module state.
  useHoistFronteggAuth();

  return props.children;
};
