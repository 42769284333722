import { sql } from "kysely";

import {
  buildFullyQualifiedObjectName,
  escapedIdentifier as id,
  escapedLiteral as lit,
  SchemaObject,
} from "~/api/materialize";
import { Cluster } from "~/api/materialize/cluster/useConnectorClusters";
import { assert } from "~/util";

export interface CreateSourceParameters {
  name: string;
  connection: SchemaObject;
  databaseName: string;
  schemaName: string;
  cluster: Cluster;
  publication: string;
  allTables: boolean;
  tables: {
    name: string;
    alias: string;
  }[];
}

const createPostgresSourceStatement = (params: CreateSourceParameters) => {
  if (params.cluster.id === "0") {
    throw new Error("You must specify cluster");
  }

  assert(params.connection?.name);
  assert(params.cluster?.name);
  const query = sql`
CREATE SOURCE ${buildFullyQualifiedObjectName(params)}
IN CLUSTER ${id(params.cluster.name)}
FROM POSTGRES CONNECTION ${buildFullyQualifiedObjectName(
    params.connection,
  )} (PUBLICATION ${lit(params.publication)})
${
  params.allTables
    ? sql`FOR ALL TABLES`
    : sql`FOR TABLES (${sql.join(
        params.tables.map(
          (t) => sql`${id(t.name)}${t.alias ? sql` AS ${id(t.alias)}` : sql``}`,
        ),
      )})`
};`;

  return query;
};

export default createPostgresSourceStatement;
