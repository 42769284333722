import { sql } from "kysely";

import {
  escapedIdentifier as id,
  escapedLiteral as lit,
} from "~/api/materialize";

export default function createClusterReplicaStatement(values: {
  clusterName: string;
  name: string;
  size: string;
}) {
  return sql`CREATE CLUSTER REPLICA ${id(values.clusterName)}.${id(
    values.name,
  )} SIZE = ${lit(values.size)}
`;
}
