import { useColorMode, useTheme } from "@chakra-ui/react";
import { Line } from "@visx/shape";
import { TooltipInPortalProps } from "@visx/tooltip/lib/hooks/useTooltipInPortal";
import React from "react";

import { MODAL_TOOLTIP_Z_INDEX } from "~/layouts/zIndex";
import { MaterializeTheme } from "~/theme";
import { Point } from "~/types/geometry";

export interface TooltipProps {
  top: number;
  left: number;
  component: React.FC<TooltipInPortalProps>;
  children: React.ReactNode;
}

export const GraphTooltip = ({
  top,
  left,
  component: TooltipInPortal,
  children,
}: TooltipProps) => {
  const { colors, radii, shadows } = useTheme<MaterializeTheme>();
  const mode = useColorMode();

  return (
    <TooltipInPortal
      data-testid="chart-tooltip"
      top={top}
      left={left}
      style={{
        backgroundColor:
          mode.colorMode === "dark"
            ? colors.background.secondary
            : colors.background.primary,
        borderRadius: radii.lg,
        borderWidth: "1px",
        boxShadow: shadows.level3,
        minWidth: 60,
        overflow: "hidden",
        position: "absolute",
        zIndex: MODAL_TOOLTIP_Z_INDEX,
      }}
    >
      {children}
    </TooltipInPortal>
  );
};

export interface GraphTooltipCursorProps {
  points: Array<{
    key: string;
    color: string;
    x: number;
    y: number;
  }>;
}

export const GraphTooltipCursor = (props: GraphTooltipCursorProps) => {
  return (
    <g>
      {props.points.map(({ x, y, key, color }) => (
        <circle
          key={key}
          cx={x}
          cy={y}
          r={4}
          fill={color}
          stroke="white"
          strokeWidth={1}
          pointerEvents="none"
        />
      ))}
    </g>
  );
};

export interface GraphLineCursorProps {
  graphBottom: number;
  graphTop: number;
  point: Point;
}

/**
 * A vertical line that follows the cursor.
 */
export const GraphLineCursor = (props: GraphLineCursorProps) => {
  const { colors } = useTheme<MaterializeTheme>();
  return (
    <Line
      from={{ x: props.point.x, y: props.graphTop }}
      to={{ x: props.point.x, y: props.graphBottom }}
      stroke={colors.foreground.tertiary}
      strokeWidth={1}
      pointerEvents="none"
    />
  );
};
